<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">About Taipei</div>
        <div class="aboutInfo__content">
          Taipei is situated in a basin in northern Taiwan that was inhabited by aboriginal peoples until settlers from China moved into the area about 300 years ago.<br>
          Eastern Taipei was largely underused fields until the nineteen-seventies, when the city began to develop the area as a financial and commercial district. Here, the modern face of Taipei shines through, with its glass and steel skyscrapers, wide boulevards, and the world's tallest building, Taipei 101.<br>
          Taipei also boasts dozens of world-class performance venues, where you can enjoy world-class theater and concert events. The city also offers a wide range of other diversions - shopping malls, nightclubs, live-music bars, quality hotels, and exotic restaurants.
        </div>
        <div class="aboutInfo__title">Attractions</div>
        <div class="aboutInfo__content">
          <div class="bold">National Palace Museum</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 10 km)</div>
            The National Palace Museum (NPM) houses a collection of ancient Chinese artifacts, some of which came from The Palace Museum and the preparatory department of the Nanjing Museum (previously the "National Central Museum"); those that came from The Palace Museum originated from the Qing Court, and those that came from the preparatory department of the Nanjing Museum primarily originated from the Institute for Exhibiting Antiquities, which was previously owned by the Jehol and Shenyang temporary palaces. This signifies that the NPM's current artifact collection contains Qing court artifacts from The Palace Museum, the Jehol temporary palace, and the Shenyang temporary palace.
            <br>
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-national-palace-museum.png" alt="" style="width: 248px; height: 280px; margin: 20px 0;">
          </div>
          <div class="bold">TAIPEI 101 (Observatory)</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 1 km)</div>
            TAIPEI 101 is the world's first skyscraper to remain the tallest building in the world for 5 consecutive years. The rarest and the most proud achievements of TAIPEI 101 are not the multiple "first" records created during construction and for its height, but also the cutting-edge technology and architectural concepts that are able to withstand the major forces of nature such as earthquakes and typhoons. After conquering all obstacles and challenging the impossible, TAIPEI 101 is finally complete; since official opening, it is gradually being transformed into the best office building, best shopping mall and the best scenic spot.<br>
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-101.png" alt="" style="width: 528px; height: 200px; margin: 20px 0;">
          </div>
          <div class="bold">Xinyi District (Shopping Area)</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 1 km)</div>
            The Xinyi District is the newest trend-setting commercial district in Taipei, embracing dozens of fashionable malls, restaurants, and hotels on Sections 4 and 5 of Xinyi Road. Shin Kong Mitsukoshi Department Store's New Life Square and A4 branches, Novel Hall for Performing Arts, Vie Show Cinemas, Grand Hyatt Taipei Hotel, and Taipei 101 are just a few of the many places where you can satisfy your consumer cravings in this area.<br>
            By day, the Xinyi District bustles with the fast-paced energy of business as Taipei's commercial and financial center. At night, the skyscrapers come alight with eye-catching displays of LED lights, setting the mood for evening fun.<br>
            On weekends, the Xinyi District transforms again as outdoor stages and squares come alive with concerts, dance performances, record release events, and celebrity appearances, making this the place where you can experience the youthful energy of Taipei.
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-xinnyi.png" alt="" style="width: 450px; height: 300px; margin: 20px 0;">
          </div>
          <div class="bold">Songshan Cultural and Creative Park</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 1.3 km)</div>
            The 6.6-hectares Songshan Cultural and Creative Park in Taipei’s Xinyi District was completed in 1937 as the Songshan Tobacco Factory, which was one of the seed companies of a monopoly system mandated by the Taiwan Governor-General Office. The premises were one of Taiwan’s pioneers of modern industry, as well as the first professional tobacco plant. A gracefully simple Japanese modernist structure, the factory features meticulously crafted face cams, glasswork and bronze nails that made it arguably a “model factory” at that time. When Japan lost the war in 1945, the Taiwan Provincial Monopoly Bureau took over the factory and renamed it, Songshan Tobacco Factory of Taiwan Provincial Monopoly Bureau. The factory ceased production of cigarettes in 1998 for concerns over urban planning, regulatory changes in the tobacco and liquor marketing system, as well as shrinking demand. It became a relic of the past after being merged into the Taipei Cigarette Plant.<br>
            In 2001, the Taipei City Government named the tobacco factory the city’s 99th historic site and converted it into a park comprising city-designated historic sites (namely the office building, the 1st to 5th warehouses, cigarette plant and boiler room), historic structures (the inspection room, a machinery repair shed and a nursing room) and architectural highlights (the Baroque-style garden, an ecologically landscaped pond, a public bath and a multi-purpose auditorium). For more efficient reuse of space, the Songshan Cultural and Creative Park was built on the historic site as a production base for designers and cultural & creative businesses, as well as a venue for performances and exhibitions. The park introduced to its premises a Taiwan Design Museum and TMSK through partnerships with the Taiwan Design Center and prestigious Taiwanese glasswork label LIULI GONG FANG, respectively, besides a snack bar that was converted from the machinery repair shed.
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-songshan.png" alt="" style="width: 420px; height: 280px; margin: 20px 0;">
          </div>
          <div class="bold">Huashan 1914 - Huashan Creative Park</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 4 km)</div>
            Huashan 1914 Creative Park currently serves as Taipei’s primary creative arts center. Not only is it a popular venue for a medley of performance troupes and rock bands, it also provides shop fronts that are shared by independent artisans and craftspeople. The buildings that stand in Huashan 1914 Creative Park are actually part of a Japanese-era winery that was constructed in 1914, hence the inclusion of the date in the park’s official title. By the year 1987, however, urbanization caught up with the city and the alcohol factory was relocated elsewhere to put an end to its water pollution.<br>
            A decade after being abandoned, the winery was being eyed by several envious groups. Golden Bough Theatre began to use the derelict park as a performance venue and was promptly charged for misuse of government property. Nevertheless, other cultural groups soon joined in and after two years of campaigning, the ex-winery was designated a cultural hotspot in 1999.
            <br><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-huashan.png" alt="" style="width: 440px; height: 320px; margin: 20px 0;">
          </div>
          <div class="bold">Chiang-Kai-Shek Memorial Hall</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 4 km)</div>
            The Memorial Hall is built upon three stories of broad and solid foundations that symbolize "Jhung Jheng" (impartial and righteous). The octagonal roof design creates many "ren" (Man), motifs on the very top, symbolizing the "unification of Man and Heaven." The roof is fashioned in the form of the Altar to Heaven and covered with blue glazed tiles, reflecting the sunlight. The apex of the roof is gold in color and culminates the upsurge glory None of the other materials, such as the white marble, red cypress ceiling, and light red granite floor, have been tainted with artificial colors, thereby imparting a feeling of sacredness, solemnity, hospitality, and peace.
            <br><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-memorial-hall.png" alt="" style="width: 424px; height: 320px; margin: 20px 0;">
          </div>
          <div class="bold">The Red House</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 6.5 km)</div>
            The two-story high red brick building is shaped into an octagonal cylinder. Each side of the octagon has a dormer window and a decorative wall. During the Japanese colonial era, the Red House was turned into a venue for Peking opera and crosstalk performances in the early 20th century. The place became a movie theater in the 1970s', ushering in the beginning of Ximenting's cinema culture. Now the Red House is both a teahouse and a venue for small-scale theater productions.
            <br><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-red-house.png" alt="" style="width: 424px; height: 320px; margin: 20px 0;">
          </div>
          <div class="bold">Taipei Fine Arts Museum</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 7.5 km)</div>
            Shaped like a pound sign, the Taipei Fine Art Museum is a traditional siheyuan courtyard with a modern edge. At night the artfully lighted museum is an eye-catching landmark. The museum hosts many international exhibitions.
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-arts-museum.png" alt="" style="width: 446px; height: 320px; margin: 20px 0;">
          </div>
          <div class="bold">TAIPEI EXPO Park</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 6 km)</div>
            Converted from the 2010 Taipei International Flora Expo, the TAIPEI EXPO PARK is an urban recreational facility that comprises three typical park areas (Yuanshan, Taipei Fine Arts Museum and Xinsheng) while offering venues for the hosting of various events in the former Flora Expo’s pavilions. Among the iconic Flora Expo displays, still available, on the parks’ premises, mostly featuring perennial flowering/foliage plants and turfs, are the 3-dimensional Green Walls, Flower Walls, “Flora Rainbows”, Special Exhibition Area, Flower Landscape, Rare Flowers & Plants Area, and Fruit Tree Area, which aim to keep the Expo’s images and variety alive, while impressing park visitors with the openness of a vision of verdure, combined with enhanced recreational quality. The TAIPEI EXPO PARK is also perfect for accommodating the hosting of exhibitions or other events as it lies strategically in the vicinity of MRT Yuanshan Station.
            <br><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-taipei-expo-park.png" alt="" style="width: 456px; height: 280px; margin: 20px 0;">
          </div>
          <div class="bold">Xinbeitou Hot Springs</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 17 km)</div>
            Hot springs in Xinbeitou with high temperature and many sources are caused by the terrestrial heat of Datun Mountains. Thermal valley is one of the earliest hot spring sources found in Taiwan.<br>Beitou hot spring has been famous since Japanese colonial times. The area is around with historic monuments and natural scenic spots. Beitou Museum, Yinsong Building, Xingnai Spring, Beitou Library, Beitou Hot Spring Park, and the Folk Museum connect into a hot spring route.
            <br><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-hot-springs.png" alt="" style="width: 420px; height: 320px; margin: 20px 0;">
          </div>
          <div class="bold">Yangmingshan National Park</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 14 km)</div>
            Of all Taiwan’s national parks, Yangmingshan is the one located closest to a metropolitan center. Yangmingshan features a widely varying terrain, diverse ecology, and is home to numerous protected species. If you’re lucky, you might even catch a glimpse of one of Taiwan’s endemic bird species—the Formosan Blue Magpie.<br>
            The park is full of plum, cherry, peach and pear trees, rhododendrons, etc. In spring, the cherry blossoms never fail to draw crowds. After cherry blossom season, about mid-March, it’s time for the calla lilies at Bamboo Lake (Zhu-zi-hu) to take center stage. Visitors go into the fields and pick calla lillies to take home with them—it’s become a must-do event for this time of a year!<br>
            Perhaps one of the most distinctive features in the park is the “Flower Clock,” composed of a variety of colorful seasonal flowers. When visiting Yangmingshan, be sure to take a photo to prove you’ve seen this famous landmark! Also worth checking are popular sights like the Hsiao-you-keng (“Small Oil Pit”), Ching-tien-kang (Qing-tian Hill) Leng-shui-keng (“Cold Water Pit”), and Yangming Archive House. If you enjoy an invigorating hike, these will be right up your alley!<br>
            The Yangmingshan area also possesses a wealth of geothermal resources, with hot springs having differing compositions depending on their location in the park. Nearby Xing-yi Road in Beitou is lined with hot spring restaurants. If you’re passing along this way, don’t forget to take a relaxing dip in the sulfur springs!
            Beautiful flowers in the spring, a cool getaway in the summer, silver grass and maple leaves in the fall, hot springs in the winter—Yangmingshan’s distinctive seasonal offerings await your discovery!
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-national-park.png" alt="" style="width: 600px; height: 230px; margin: 20px 0;">
          </div>
          <div class="bold">Yeliou Scenic Area</div>
          <div class="aboutInfo__content">
            <div style="margin-bottom: 8px;">(Distance from the venue: around 35 km)</div>
            The total distance measured from the entrance of the Yehliu Geopark to the end of the cape is about 1.7 km; the widest area in between is shorter than 300 m. The distance measured from Yehliu Stop at Jijin Highway to the end of the cape is about 2.4km. The rock landscape of Yehliu Geopark is one of most famous wonders in the world. The costal line is stretching in a direction vertical to the layer and the structure line; besides, the influences caused by wave attack, rock weathering, earth movement and crustal movement all contribute to the formation of such a rare and stunning geological landscape.
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-yeliou.png" alt="" style="width: 478px; height: 320px; margin: 20px 0;">
          </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'VisitTaipeiComp'
}
</script>
