<template>
  <div class="page-holder">
    <div class="page-content news">
        <div class="title">Presentation Guideline</div>
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/poster_guildline.jpg" alt="" style="width:60%; margin:24px;">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PresentationGuidelineComp'
}
</script>
