<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">Grand & Award</div>
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ins-award.png" alt="">
        <div>Check more infomation at <a href="https://www.the-ins.org/files/awards/ins_awards_and_requirements.pdf" style="color:#601985; text-decoration:underline;">here</a></div>
        <div>The final list of awards is coming soon.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GrandAwardComp'
}
</script>
