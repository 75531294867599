<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
      <div @click="viewPDF()" style="border-radius: 20px; background-color: #601985; color:white; padding: 12px 30px;">Click Here to View Conference Manual</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookComp',
  data () {
  },
  methods: {
    viewPDF () {
      window.open('../../ins-manual-0707.pdf', '_blank')
    }
  }
}
</script>
