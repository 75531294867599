<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">About Organizer</div>
        <div class="aboutInfo__title">About Taiwan Association of Neuropsychological Development and Mental Rehabilitation (TANDMR)</div>
        <div class="aboutInfo__content">Taiwan Association of Neuropsychological Development and Mental Rehabilitation (TANDMR) is a non-profit association founded in 2016.</div>
        <div class="aboutInfo__title">Objectives</div>
        <div class="aboutInfo__content">Based on evidence-based knowledge, we delicate to promote scientific, ecological and indigenous psychological researches within practicing. We advocate studies for the resilience and mental health of the community to make life quality better and improve individuals’ well-being.</div>
        <div class="aboutInfo__title">Mission</div>
        <ol class="aboutInfo__list">
          <li>To promote research related to psychological development and mental rehabilitation.</li>
          <li>To promote clinical service with knowledge of psychological development and mental rehabilitation.</li>
          <li>To publish publications about psychological development and mental rehabilitation, and to promote neuropsychological knowledge.</li>
          <li>To strengthen the ability of Taiwanese enhancing well-being, and to promote relevant policies and welfare.</li>
          <li>To disseminate knowledge through professional courses, conference meetings and activities.</li>
          <li>To advocate neuropsychology-based public speech and activities.</li>
          <li>To promote professional course and quality.</li>
          <li>To establish policies related to professional ethics, supervision and arbitration.</li>
          <li>Cooperation for neuropsychological development and mental rehabilitation related affair.</li>
          <li>Arranging activities and work with objectives.</li>
        </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutOrganizerComp'
}
</script>
