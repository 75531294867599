// router/index.js
import Vue from 'vue'
import Router from 'vue-router'
import AboutComp from '@/components/pages/about'
import ProgramComp from '@/components/pages/program/program'
import SocialComp from '@/components/pages/program/social'
import CourseComp from '@/components/pages/program/cecourse'
import BookComp from '@/components/pages/program/book'
import AbstractComp from '@/components/pages/abstract'
import RegistrationComp from '@/components/pages/registration/registration'
import AcccommodationComp from '@/components/pages/registration/accommodation'
import ReceiptComp from '@/components/pages/registration/receipt'
import PaymentComp from '@/components/pages/registration/payment'
import TripComp from '@/components/pages/registration/trip'
import MoreComp from '@/components/pages/more'
import LoginComp from '@/components/pages/login'
import SignUpComp from '@/components/pages/signup'
import ForgetPWComp from '@/components/pages/forgetpw'
import AccountComp from '@/components/pages/account'
import SubmitComp from '@/components/pages/Submit'
// import NewSubmitComp from '@/components/pages/Submit_new'
import EditAbstractComp from '@/components/pages/Submit_edit_abstract'
import EditSymposiumComp from '@/components/pages/Submit_edit_symposium'
import SponsorComp from '@/components/pages/sponsorship'
import SubmissionGuidelineComp from '@/components/pages/submission_guideline'
import PresentationGuidelineComp from '@/components/pages/presentation_guideline'
import GrandAwardComp from '@/components/pages/grand_and_award'
import NewsComp from '@/components/pages/news'
import AboutWelcomeComp from '@/components/pages/about_info/welcome'
import AboutVenueComp from '@/components/pages/about_info/venue'
import AboutConferenceComp from '@/components/pages/about_info/conference'
import AboutOrganizerComp from '@/components/pages/about_info/organizer'
import VisitTaiwanComp from '@/components/pages/visit/taiwan'
import VisitTaipeiComp from '@/components/pages/visit/taipei'
import VisitTransportationComp from '@/components/pages/visit/transportation'
import SpeakersComp from '@/components/pages/program/speakers'
import AlbumsComp from '@/components/pages/photos/albums'
import AlbumComp from '@/components/pages/photos/album'
Vue.use(Router) // 啟用Router

/* eslint-disable no-new */
export default new Router({
  routes: [{ // 設定路徑
    path: '/', // 自訂路徑
    name: 'Default', // 設定名稱
    component: AboutComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/about', // 自訂路徑
    name: 'About', // 設定名稱
    component: AboutComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/program', // 自訂路徑
    name: 'Program', // 設定名稱
    component: ProgramComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/program/course', // 自訂路徑
    name: 'Course', // 設定名稱
    component: CourseComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/program/social', // 自訂路徑
    name: 'Social', // 設定名稱
    component: SocialComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/abstract', // 自訂路徑
    name: 'Abstract', // 設定名稱
    component: AbstractComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/abstract/submissionGuideline', // 自訂路徑
    name: 'SubmissionGuideline', // 設定名稱
    component: SubmissionGuidelineComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/abstract/presentationGuideline', // 自訂路徑
    name: 'PresentationGuideline', // 設定名稱
    component: PresentationGuidelineComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/abstract/grandAward', // 自訂路徑
    name: 'GrandAward', // 設定名稱
    component: GrandAwardComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/registration', // 自訂路徑
    name: 'RegistrationInfo', // 設定名稱
    component: RegistrationComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/registration/trip', // 自訂路徑
    name: 'RegistrationTrip', // 設定名稱
    component: TripComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/registration/accommodation', // 自訂路徑
    name: 'RegistrationAccommodation', // 設定名稱
    component: AcccommodationComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/registration/receipt', // 自訂路徑
    name: 'RegistrationReicept', // 設定名稱
    component: ReceiptComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/registration/payment/:status', // 自訂路徑
    name: 'RegistrationPayment', // 設定名稱
    component: PaymentComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/more', // 自訂路徑
    name: 'More', // 設定名稱
    component: MoreComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/login', // 自訂路徑
    name: 'Login', // 設定名稱
    component: LoginComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/signup', // 自訂路徑
    name: 'Signup', // 設定名稱
    component: SignUpComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/forgetPW', // 自訂路徑
    name: 'ForgetPW', // 設定名稱
    component: ForgetPWComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/account', // 自訂路徑
    name: 'Account', // 設定名稱
    component: AccountComp // 選擇要對應的元件
  }, {
    path: '/submit',
    name: 'Submit',
    component: SubmitComp,
    children: [
      {
      //   path: 'new',
      //   component: NewSubmitComp
      // }, {
        path: 'edit_abstract/:id',
        component: EditAbstractComp
      }, {
        path: 'edit_symposium/:id',
        component: EditSymposiumComp
      }
    ]
  }, { // 設定路徑
    path: '/sponsorship', // 自訂路徑
    name: 'Sponsor', // 設定名稱
    component: SponsorComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/news/:news_id', // 自訂路徑
    name: 'News', // 設定名稱
    component: NewsComp // 選擇要對應的元件
  }, {
    path: '/about/conference',
    name: 'Conference',
    component: AboutConferenceComp
  }, {
    path: '/about/welcome',
    name: 'Welcome',
    component: AboutWelcomeComp
  }, {
    path: '/about/venue',
    name: 'Venue',
    component: AboutVenueComp
  }, {
    path: '/about/organizer',
    name: 'Organizer',
    component: AboutOrganizerComp
  }, {
    path: '/visit/taiwan',
    name: 'Taiwan',
    component: VisitTaiwanComp
  }, {
    path: '/visit/taipei',
    name: 'Taipei',
    component: VisitTaipeiComp
  }, {
    path: '/visit/transportation',
    name: 'Transportation',
    component: VisitTransportationComp
  // }, {
  //   path: '/contact/info',
  //   name: 'ContactInfo',
  //   component: ContactInfoComp
  }, {
    path: '/program/speakers',
    name: 'KeynoteSpeaker',
    component: SpeakersComp
  }, {
    path: '/program/book',
    name: 'ProgramBook',
    component: BookComp
  }, { // 設定路徑
    path: '/albums/', // 自訂路徑
    name: 'Albums', // 設定名稱
    component: AlbumsComp // 選擇要對應的元件
  }, { // 設定路徑
    path: '/album/:album_id', // 自訂路徑
    name: 'album', // 設定名稱
    component: AlbumComp // 選擇要對應的元件
  }]
})
