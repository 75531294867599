<template>
  <div id="about" class="about">
      <section class="about__topHolder">
        <div id="pre-top" class="about__topBtn about__topBtn--next" @click="preTop">
          <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/arrow.png" alt="">
        </div>
        <div id="next-top" class="about__topBtn about__topBtn--pre" @click="nextTop">
          <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/arrow.png" alt="">
        </div>
        <picture :data-top="topDisplaying" id="topPicture" @click="goTop">
          <source media="(max-width: 495px)"
                  :srcset="tops[topDisplaying].mobile" />
          <img class="pic pic--top" :src="tops[topDisplaying].web" alt="">
        </picture>
      </section>
      <section  class="section about__section about__section--coming">
        <div class="about__coming">
          <router-link to="/program/speakers" class="comingItem">
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-program.png" alt="">
            Program
          </router-link>
          <router-link to="/registration" class="comingItem">
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-regist.png" alt="">
            Registration
          </router-link>
          <router-link to="/abstract/grandAward" class="comingItem">
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-award.png" alt="">
            Awards
          </router-link>
          <router-link to="/registration/trip" class="comingItem">
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-extension-tour.png" alt="">
            Extension Tour
          </router-link>
        </div>
      </section>
      <section class="section about__section about__section--news">
        <div class="title title--purple">Latest News</div>
        <div class="news">
          <div class="news__item" v-for="n in news" :key="n.title">
            <div class="news__item--time">{{n.time}}</div>
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-arrow-default.png" alt="" class="news__item--arrow">
            <div v-if="n.link == undefined" class="news__item--title">{{n.title}}</div>
            <router-link v-else :to="'/news/' + n.link" >{{n.title}}</router-link>
          </div>
        </div>
      </section>
      <section class="section about__section about__section--invite">
        <div class="title title--white">Welcome Address</div>
          <div class="invitation">
          I am pleased and honoured to invite you to the International Neuropsychological Society (INS) 2023 meeting in Taipei, Taiwan, held in association with the Taiwan Association of Neuropsychological Development and Mental Rehabilitation (TANDMR).<br><br>
          This meeting represents a landmark for INS as it is our first ever meeting in East Asia. The INS is committed to supporting the development of neuropsychological science and practice across the world and this meeting provides us with a wonderful opportunity to showcase excellent neuropsychological research from all parts of Asia, as well as from the rest of the world.<br><br>
          The motto of the INS is ‘Where the World Meets’ and this will never be more true than at our meeting in Taipei. As well as hearing about the latest research findings from our keynote speakers, symposia, free paper sessions, and continuing education workshops, you will have the opportunity to meet and discuss neuropsychology with colleagues from all around the world.<br><br>
          The last few years have been challenging for all of us. In many ways the pandemic brought us closer with our shared experience of coping with Covid and remote meetings that have allowed us to meet virtually throughout the periods where travel was not possible. But there is nothing like meeting in person, and in Taipei you will receive a very warm Taiwanese welcome in the city known for its friendliness and wonderful mix of cultural influences.<br><br>
          I look forward to meeting you in Taipei.<br><br>
          Jon Evans<br>
          INS President
          </div>
      </section>
      <section class="section about__section about__section--date">
        <div class="title title--white">Important Dates</div>
        <div class="dates">
          <router-link class="dates__card" v-for="date in dates" :key="date.text" :to="date.link">
            <div class="dates__item dates__item--day">{{date.day}}</div>
            <div class="dates__item dates__item--month">{{date.month}}</div>
            <hr noshade="noshade" color="white">
            <div class="dates__item dates__item--text">{{date.text}}</div>
          </router-link>
        </div>
      </section>
      <section class="section about__section about__section--map">
        <div class="title title--purple">Organized by</div>
        <div class="wrapper logos">
          <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-logo-big-02.png" alt="" class="pic pic--ins">
          <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-logo-tandmr.png" alt="" class="pic pic--tadmr">
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.018690037229!2d121.55841961499272!3d25.03343978397259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abb61ab3d33f%3A0xab0d0f7ac395bff4!2z5Y-w5YyX5ZyL6Zqb5pyD6K2w5Lit5b-DKFRJQ0Mp!5e0!3m2!1szh-TW!2stw!4v1656405221338!5m2!1szh-TW!2stw" width="1440"  height="400" style="border:0; max-width:100vw;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </section>
  </div>
</template>

<script>
export default {
  name: 'AboutComp',
  data () {
    return {
      topDisplaying: 0,
      tops: [{
      //   mobile: 'https://storage.googleapis.com/zuvio-public/public/system/images/ins/banner-0322-mob.jpg',
      //   web: 'https://storage.googleapis.com/zuvio-public/public/system/images/ins/banner-0322.jpg',
      //   link: '/#/login'
      // }, {
        mobile: 'https://storage.googleapis.com/zuvio-public/public/system/images/ins/IMG_8982.jpg',
        web: 'https://storage.googleapis.com/zuvio-public/public/system/images/ins/IMG_8981.jpg',
        link: '/#/registration'
      }, {
        mobile: 'https://storage.googleapis.com/zuvio-public/public/system/images/ins/0614-banner-mob.jpg',
        web: 'https://storage.googleapis.com/zuvio-public/public/system/images/ins/0614-banner-web.jpg',
        link: '/#/registration'
      }, {
        mobile: 'https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-mobile-new.png',
        web: 'https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-kv-new.png',
        link: '/#/about/conference'
      }],
      news: [
        { time: '2023-08-21', title: 'Photographs about INS 2023 Taiwan meeting.', link: '7' },
        { time: '2023-06-30', title: 'Traffic information', link: '6' },
        { time: '2023-06-30', title: '繼續教育積分學分申請公告', link: '5' },
        // { time: '2023-06-19', title: '各專科醫生繼續教育學分申請中', link: '5' },
        { time: '2023-05-30', title: 'Deadline for eCode for VISA  (Given country)', link: '4' },
        { time: '2023-05-25', title: 'The issue of failed payments', link: '3' },
        { time: '2023-03-14', title: 'The Taiwan meeting announced that the submission deadline has been extended to March 24th, 2023', link: '2' },
        { time: '2022-12-01', title: 'INS 2023 Taiwan Meeting Online Submission is now open!', link: '1' },
        { time: '2022-09-07', title: 'INS 2023 Taiwan Meeting Website is now open!' }
        // { time: '2022-01-03', title: 'The 2023 Mid-Year Meeting in Taigreat significance in the history of neuropsychological …' },
        // { time: '2022-01-03', title: 'The 2023 Mid-Year Meeting in Taiwan is of grea in the history of neuropsychological …' },
      ],
      dates: [
        {
          day: '1',
          month: 'Dec. 2022',
          text: 'Abstract Submission Open',
          link: 'abstract'
        }, {
          day: '24',
          month: 'Mar. 2023',
          text: 'Deadline of Abstract Submission',
          link: ''
        }, {
          day: '25',
          month: 'April. 2023',
          text: 'Notification of Acceptance',
          link: ''
        }, {
          day: '31',
          month: 'May. 2023',
          text: 'Deadline for Early Bird Registration',
          link: ''
        }, {
          day: '17',
          month: 'June. 2023',
          text: 'Deadline for Online Registration',
          link: ''
        }, {
          day: '06-08',
          month: 'July. 2023',
          text: 'Conference Date',
          link: ''
        }, {
          day: '09',
          month: 'July. 2023',
          text: 'Special Event',
          link: ''
        }
      ]
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    nextTop () {
      if (document.getElementById('topPicture')) {
        if (this.topDisplaying + 1 < this.tops.length) {
          this.topDisplaying += 1
        } else {
          this.topDisplaying = 0
        }
        document.getElementById('topPicture').classList.add('fadein')
        window.setTimeout(this.removeFade, 1000)
      }
    },
    preTop () {
      if (this.topDisplaying - 1 < 0) {
        this.topDisplaying = this.tops.length - 1
      } else {
        this.topDisplaying -= 1
      }
      document.getElementById('topPicture').classList.add('fadein')
      window.setTimeout(this.removeFade, 1700)
    },
    removeFade () {
      document.getElementById('topPicture').classList.remove('fadein')
    },
    goTop () {
      location.href = this.tops[this.topDisplaying].link
    }
  },
  mounted () {
    this.scrollToTop()
    // setInterval(this.nextTop, 5000)
  }
}

</script>
