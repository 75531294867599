<template>
    <div>
      <div class="page-content summit"  v-show="symposiumEditing == '-1'">
      <div class="title" style="margin-bottom: 20px;">Editing Submitted Abstract</div>
      <div class="catalog mb20">
        <div class="wrapper catalog__item">
          <div class="catalog__label">Symposium Proposal</div>
          <template v-if="state =='paperInfo'">
            <input type="button" value="Reset" @click="confirmReset(0)">
            <input type="button" value="Edit" @click="display_symposium(0)">
            <div style="width:20px; height:20px;" v-show="!sym_flag[0]"></div>
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-tick.png" style="width:20px; height:20px;" v-show="sym_flag[0]">
          </template>
          <template v-else-if="state =='preview'">
            <input type="button" value="Preview" @click="display_symposium(0)">
          </template>
        </div>
        <div class="wrapper catalog__item" v-for="(el,index) in symposium.abstract_detail" :key="index">
          <div class="catalog__label">Abstract {{index+1}}</div>
          <template v-if="state =='paperInfo'">
            <input type="button" value="Reset" @click="confirmReset(index+1)">
            <input type="button" value="Edit" @click="display_symposium(index+1)">
            <div style="width:20px; height:20px;" v-show="!sym_flag[index+1]"></div>
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-tick.png" style="width:20px; height:20px;" v-show="sym_flag[index+1]">
          </template>
          <template v-else-if="state =='preview'">
            <input type="button" value="Preview" @click="display_symposium(index+1)">
          </template>
          <div v-show="index >= 4 && state == 'paperInfo'" class="delete6" @click="removeAbstract()"></div>
        </div>
      </div>
      <div class="disclosure__btns" v-if="state == 'paperInfo'">
        <input type="button" class="nextBtn" value="+ Add" @click="addAbstract()">
      </div>
    </div>

      <form class="page-content summit" id="symposiumForm0" v-show="symposiumEditing == '0'">
        <div class="author folder">
          <div class="tabs">
            <div class="tab active" @click="showSymChair('chair')" id="chair_tab">Symposium Chair</div>
            <div class="tab" @click="showSymChair('discussant')" id="discussant_tab">Discussant</div>
          </div>
          <div class="form--pAuthor" v-show="sym_chair == 'chair'">
            <div class="same mb20" @click="sameChair()">
              Click if Symposium Chair is same as User
            </div>
            <div class="signup__form signup__form--submit" id="pAuthor">
              <label for="" class="item required">First (Given) Name</label>
              <div class="inputwrap">
                <input type="text" class="preview" v-model.lazy="symposium.symposium_chair_first_name" required>
              </div>
              <label for="" class="item required">Last (Family) Name</label>
              <div class="inputwrap">
                <input type="text" class="preview" v-model.lazy="symposium.symposium_chair_last_name" required>
              </div>
              <label for="" class="item required">Title</label>
              <div class="inputwrap inputwrap__radio">
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-chair-title" v-model.lazy="symposium.symposium_chair_title" value="1" required>
                  <label>Dr.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-chair-title" v-model.lazy="symposium.symposium_chair_title" value="2" >
                  <label>Prof.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-chair-title" v-model.lazy="symposium.symposium_chair_title" value="3" >
                  <label>Associate Prof.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-chair-title" v-model.lazy="symposium.symposium_chair_title" value="4" >
                  <label>Assistant Prof.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-chair-title" v-model.lazy="symposium.symposium_chair_title" value="5" >
                  <label>Mr.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-chair-title" v-model.lazy="symposium.symposium_chair_title" value="6" >
                  <label>Mrs.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-chair-title" v-model.lazy="symposium.symposium_chair_title" value="7" >
                  <label>Ms.</label>
                </div>
              </div>
              <label for="" class="item required">Affiliation</label>
              <div class="inputwrap">
                <input type="text" class="preview" placeholder="Please enter the full name of your institution."  v-model.lazy="symposium.symposium_chair_affiliation" required>
              </div>
              <label for="" class="item required">Country of Affiliation</label>
              <div class="inputwrap">
                <select v-if="state == 'paperInfo'" name="nation" v-model.lazy='symposium.symposium_chair_country_of_affiliation_code' required>
                  <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
                </select>
                <input v-else-if="state == 'preview'" type="text" :value="symposium.symposium_chair_country_of_affiliation_code" style="background-color: #f2f2f2;">
              </div>
              <label for="" class="item required">Email</label>
              <div class="inputwrap inputwrap__email">
                <input type="email" class="preview" v-model="symposium.symposium_chair_email" required>
                <div class="description"><i>Please allow emails from us in your email spam filter, or make sure that “ins2023tw@gmail.com” is on your "Approved Sender List” or "White List" and/or in your "Address Book." Please enter your E-mail.</i></div>
              </div>
            </div>
          </div>
          <div class="form--pAuthor" v-show="sym_chair == 'discussant'">
            <div class="same mb20" @click="sameDiscussant()">
              Click if Discussant is same as Symposium Chair
            </div>
            <div class="signup__form signup__form--submit" id="pAuthor">
              <label for="" class="item required">First (Given) Name</label>
              <div class="inputwrap">
                <input type="text" class="preview" v-model.lazy="symposium.discussant_first_name" required>
              </div>
              <label for="" class="item required">Last (Family) Name</label>
              <div class="inputwrap">
                <input type="text" class="preview" v-model.lazy="symposium.discussant_last_name" required>
              </div>
              <label for="" class="item required">Title</label>
              <div class="inputwrap inputwrap__radio">
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-discussant-title" v-model.lazy="symposium.discussant_title" value="1" required>
                  <label>Dr.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-discussant-title" v-model.lazy="symposium.discussant_title" value="2" >
                  <label>Prof.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-discussant-title" v-model.lazy="symposium.discussant_title" value="3" >
                  <label>Associate Prof.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-discussant-title" v-model.lazy="symposium.discussant_title" value="4" >
                  <label>Assistant Prof.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-discussant-title" v-model.lazy="symposium.discussant_title" value="5" >
                  <label>Mr.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-discussant-title" v-model.lazy="symposium.discussant_title" value="6" >
                  <label>Mrs.</label>
                </div>
                <div class="inputwrap">
                  <input type="radio" name="radio_title" class="preview radio-discussant-title" v-model.lazy="symposium.discussant_title" value="7" >
                  <label>Ms.</label>
                </div>
              </div>
              <label for="" class="item required">Affiliation</label>
              <div class="inputwrap">
                <input type="text" class="preview" placeholder="Please enter the full name of your institution."  v-model.lazy="symposium.discussant_affiliation" required>
              </div>
              <label for="" class="item required">Country of Affiliation</label>
              <div class="inputwrap">
                <select v-if="state == 'paperInfo'" name="nation" v-model.lazy='symposium.discussant_country_of_affiliation_code' required>
                  <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
                </select>
                <input v-else-if="state == 'preview'" type="text" :value="symposium.discussant_country_of_affiliation_code" style="background-color: #f2f2f2;">
              </div>
              <label for="" class="item required">Email</label>
              <div class="inputwrap inputwrap__email">
                <input type="email" class="preview" v-model="symposium.discussant_email" required>
                <div class="description"><i>Please allow emails from us in your email spam filter, or make sure that “ins2023tw@gmail.com” is on your "Approved Sender List” or "White List" and/or in your "Address Book." Please enter your E-mail.</i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="bodyForm" id="bodyForm" style="grid-column: span 2;">
          <div class="subtitle mb20" style="grid-column: span 2;">Symposium Proposal Title</div>
          <label for="" class="required mb20">Title of Symposium Proposal</label>
          <input type="text" class="preview mb20" v-model.lazy="symposium.symposium_abstract_title" required>
          <div class="tips">*Please be reminded that only the first letter of the Title should be capitalized.</div>
          <label for="" class="required mb20">Abstract Category</label>
          <select id="proposalCategorySelect" class="bodyForm__select mb20" v-model="symposium.symposium_abstract_category"></select>
          <div class="tips">*Maximum of 3 Categories</div>
          <div class="subtitle" style="grid-column: span 2;">Symposium Overview</div>
          <label class="item required">Content</label>
          <ckeditor :editor="editor" v-model="symposium.symposium_content" :config="editorSymposium" :disabled="state == 'preview'"></ckeditor>
        </div>
        <div class="disclosure__btns" v-if="state == 'paperInfo'">
          <input type="button" class="cancelBtn" value="Back" @click="display_symposium(-1)">
          <input type="button" class="nextBtn" value="Save Changes" @click="checkSubForm(0)">
        </div>
        <div class="disclosure__btns" v-else-if="state == 'preview'">
          <input type="button" class="cancelBtn" value="Close" @click="display_symposium(-1)">
        </div>
      </form>
      <!-- abstract 1 to 6 -->
      <template v-for="(sub, subIndex) in symposium.abstract_detail" >
        <form :id="'symposiumForm'+(subIndex+1).toString()" :key="subIndex" v-show="symposiumEditing === subIndex + 1">
          <div class="page-content summit" >
            <div class="title mb20">Abstract {{subIndex+1}}</div>
            <div class="author folder">
              <div class="tabs">
                <div class="tab active">Presenting Author</div>
              </div>
              <div class="form--pAuthor">
                <div class="same mb20" @click="sameAbstractAuthor(subIndex)">
                  Click if Presenting Author is same as User
                </div>
                <div class="signup__form signup__form--submit">
                  <label for="" class="item required">First (Given) Name</label>
                  <div class="inputwrap">
                    <input type="text" class="preview" v-model.lazy="sub.first_name" required>
                  </div>
                  <label for="" class="item required">Last (Family) Name</label>
                  <div class="inputwrap">
                    <input type="text" class="preview" v-model.lazy="sub.last_name" required>
                  </div>
                  <label for="" class="item required">Title</label>
                  <div class="inputwrap inputwrap__radio">
                    <div class="inputwrap">
                      <input type="radio" name="radio_title" class="preview" v-model.lazy="sub.title" value="1" required>
                      <label>Dr.</label>
                    </div>
                    <div class="inputwrap">
                      <input type="radio" name="radio_title" class="preview" v-model.lazy="sub.title" value="2" >
                      <label>Prof.</label>
                    </div>
                    <div class="inputwrap">
                      <input type="radio" name="radio_title" class="preview" v-model.lazy="sub.title" value="3" >
                      <label>Associate Prof.</label>
                    </div>
                    <div class="inputwrap">
                      <input type="radio" name="radio_title" class="preview" v-model.lazy="sub.title" value="4" >
                      <label>Assistant Prof.</label>
                    </div>
                    <div class="inputwrap">
                      <input type="radio" name="radio_title" class="preview" v-model.lazy="sub.title" value="5" >
                      <label>Mr.</label>
                    </div>
                    <div class="inputwrap">
                      <input type="radio" name="radio_title" class="preview" v-model.lazy="sub.title" value="6" >
                      <label>Mrs.</label>
                    </div>
                    <div class="inputwrap">
                      <input type="radio" name="radio_title" class="preview" v-model.lazy="sub.title" value="7" >
                      <label>Ms.</label>
                    </div>
                  </div>
                  <label for="" class="item required">Affiliation</label>
                  <div class="inputwrap">
                    <input type="text" class="preview" placeholder="Please enter the full name of your institution."  v-model.lazy="sub.affiliation" required>
                  </div>
                  <label for="" class="item required">Country of Affiliation</label>
                  <div class="inputwrap">
                    <select v-if="state == 'paperInfo'" name="nation" v-model.lazy='sub.country_of_affiliation_code' required>
                      <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
                    </select>
                    <input v-else-if="state == 'preview'" type="text" :value="sub.country_of_affiliation_code" style="background-color: #f2f2f2;">
                  </div>
                  <label for="" class="item required">Email</label>
                  <div class="inputwrap inputwrap__email">
                    <input type="email" class="preview" v-model="sub.email" required>
                    <div class="description"><i>Please allow emails from us in your email spam filter, or make sure that “ins2023tw@gmail.com” is on your "Approved Sender List” or "White List" and/or in your "Address Book." Please enter your E-mail.</i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-content summit summit--coAuthor ">
            <div class="coAuthor">
              <input v-if="state=='paperInfo'" type="button" class="btn--delete" @click="sym_removeCoAuthor(subIndex)">
              <input v-if="state=='paperInfo'" type="button" class="btn" value="New" @click="sym_newCoAuthor(subIndex)">
              <div class="title">Co-Authors</div>
            </div>
            <div class="coAuthorList">
              <div class="list-head">
                <div class="span2">Orders</div>
                <div class="span2">Title</div>
                <div class="span2">First Name</div>
                <div class="span2">Last Name</div>
                <div>Affiliation<br>(Working Institution</div>
                <div>Country of Affiliation</div>
                <div><button type="button" @click="sym_sameAffiliation($event, subIndex)">Same as <br>Presenting Author.</button></div>
                <div><button type="button" @click="sym_sameCountry($event, subIndex)">Same as <br>Presenting Author.</button></div>
              </div>
              <div class="list-row">
                <div class="wrapper"><input type="checkbox" disabled>01</div>
                <div>{{sub.title != 0 ? titleList[sub.title-1] : 'Presenting Author\'s Title'}}</div>
                <div>{{sub.first_name|| 'Presenting Author'}}</div>
                <div>{{sub.last_name|| 'Presenting Author'}}</div>
                <div>{{sub.affiliation|| 'Presenting Author\'s Affiliation'}}</div>
                <div>{{sub.country_of_affiliation_code || 'Presenting Author\'s Country of Affiliation'}}</div>
              </div>
              <div class="list-row list-new" v-for="(el,co_index) in sub.co_authors" :key="co_index">
                <div class="wrapper"><input type="checkbox" v-model="sym_deleteCoAuthorList[subIndex]" :value="co_index" class="preview">0{{co_index+2}}</div>
                <select v-model="el.title" v-if="state == 'paperInfo'">
                  <option v-for="(title,index) in titleList" :key="index" :value="index+1">{{title}}</option>
                </select>
                <input v-else-if="state == 'preview'" type="text" :value="titleList[el.title-1]" style="background-color: #f2f2f2;">
                <input type="text" class="preview" placeholder="First Name" v-model="el.first_name" required>
                <input type="text" class="preview" placeholder="Last Name" v-model="el.last_name" required>
                <input type="text" class="preview" placeholder="Affiliation" v-model="el.affiliation" required>
                <div>
                  <select v-if="state == 'paperInfo'" name="nation" v-model.lazy='el.country_of_affiliation_code' style="width:125px; margin: 0 6px;" required>
                    <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
                  </select>
                  <input v-else-if="state == 'preview'" type="text" :value="el.country_of_affiliation_code" style="background-color: #f2f2f2;">
                </div>
              </div>
            </div>
          </div>
          <div class="page-content summit">
            <div class="subtitle warning">Body of Abstract <span class="warning" style="font-size: 12px">(All fields with * are required.)</span></div>
            <div class="bodyForm">
              <label for="" class="required">Title of Abstract</label>
              <input type="text" class="preview" v-model.lazy="sub.body_of_abstract_title" required>
              <span class="warning" style="font-size: 12px; grid-column: 2/3;">*Please be reminded that only the first letter of the Title should be capitalized.</span>
              <!-- <label for="" class="required">Abstract Category</label>
              <select :id="'catSelect'+subIndex" class="bodyForm__select" v-model="sub.abstract_category"></select> -->
              <label class="item required">1. Objective</label>
              <ckeditor :editor="editor" v-model="sub.body_of_abstract_objective_content" :config="sym_editor[subIndex * 4 + 0]" :disabled="state == 'preview'"></ckeditor>
              <label class="item required">2. Participants and Methods</label>
              <ckeditor :editor="editor" v-model="sub.body_of_abstract_participants_and_methods" :config="sym_editor[subIndex * 4 + 1]" :disabled="state == 'preview'"></ckeditor>
              <label class="item required">3. Results</label>
              <ckeditor :editor="editor" v-model="sub.body_of_abstract_results" :config="sym_editor[subIndex * 4 + 2]" :disabled="state == 'preview'"></ckeditor>
              <label class="item required">4. Conclusion(s)</label>
              <ckeditor :editor="editor" v-model="sub.body_of_abstract_conclusions" :config="sym_editor[subIndex * 4 + 3]" :disabled="state == 'preview'"></ckeditor>
              <label class="item--5 required">5. Keyword(s)</label>
              <select :id="'keySelect'+subIndex" class="bodyForm__select" v-model="sub.body_of_abstract_keywords"></select>
              <div class="tips">*Maximum of 5 Keywords</div>
              <div class="limit">{{sym_totalCount[subIndex]}} / 2000 characters</div>
              <div class="tips" style="grid-column: 1/3;">*The body of the abstract (1~4 Total) should be typed in English and should be less than 2,000 characters. Please note that extra words would be cut off automatically.</div>
            </div>
            <div class="disclosure__btns" v-if="state == 'paperInfo'">
              <input type="button" class="cancelBtn" value="Back" @click="display_symposium(-1)">
              <input type="button" class="nextBtn" value="Save Changes" @click="checkSubForm(subIndex+1)">
            </div>
            <div class="disclosure__btns" v-else-if="state == 'preview'">
              <input type="button" class="cancelBtn" value="Close" @click="display_symposium(-1)">
            </div>
          </div>
        </form>
      </template>
      <div class="btns" v-if="state == 'paperInfo' && symposiumEditing == '-1'">
        <input type="button" class="backType" value="Cancel" @click="cancelEdit()">
        <input type="button" class="toPreview" value="Preview" @click="checkSymposium()">
      </div>
      <div class="btns" v-else-if="state == 'preview' && symposiumEditing == '-1'">
        <input type="button" class="backType" value="Back" @click="toSymEditing()">
        <input type="button" class="toPreview" value="Submit" @click="SUBMITSYMPOSIUM()">
      </div>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'EditSymposiumComp',
  data () {
    return {
      state: 'paperInfo',
      categories: [],
      titleList: ['Dr.', 'Prof.', 'Associate Prof.', 'Assistant Prof.', 'Mr.', 'Mrs.', 'Ms.'],
      keywords: [],
      symposium: {
        symposium_chair_first_name: '',
        symposium_chair_last_name: '',
        symposium_chair_title: '',
        symposium_chair_affiliation: '',
        symposium_chair_country_of_affiliation_code: '',
        symposium_chair_email: '',
        discussant_first_name: '',
        discussant_last_name: '',
        discussant_title: '',
        discussant_affiliation: '',
        discussant_country_of_affiliation_code: '',
        discussant_email: '',
        symposium_abstract_title: '',
        symposium_abstract_category: [],
        symposium_content: '',
        abstract_detail: [{
          abstract_name: '',
          title: '',
          abstract_category: [],
          first_name: '',
          last_name: '',
          email: '',
          affiliation: '',
          country_of_affiliation_code: '',
          co_authors: [],
          body_of_abstract_title: '',
          body_of_abstract_objective_content: '',
          body_of_abstract_participants_and_methods: '',
          body_of_abstract_results: '',
          body_of_abstract_conclusions: '',
          body_of_abstract_keywords: []
        }, {
          abstract_name: '',
          title: '',
          abstract_category: [],
          first_name: '',
          last_name: '',
          email: '',
          affiliation: '',
          country_of_affiliation_code: '',
          co_authors: [],
          body_of_abstract_title: '',
          body_of_abstract_objective_content: '',
          body_of_abstract_participants_and_methods: '',
          body_of_abstract_results: '',
          body_of_abstract_conclusions: '',
          body_of_abstract_keywords: []
        }, {
          abstract_name: '',
          title: '',
          abstract_category: [],
          first_name: '',
          last_name: '',
          email: '',
          affiliation: '',
          country_of_affiliation_code: '',
          co_authors: [],
          body_of_abstract_title: '',
          body_of_abstract_objective_content: '',
          body_of_abstract_participants_and_methods: '',
          body_of_abstract_results: '',
          body_of_abstract_conclusions: '',
          body_of_abstract_keywords: []
        }, {
          abstract_name: '',
          title: '',
          abstract_category: [],
          first_name: '',
          last_name: '',
          email: '',
          affiliation: '',
          country_of_affiliation_code: '',
          co_authors: [],
          body_of_abstract_title: '',
          body_of_abstract_objective_content: '',
          body_of_abstract_participants_and_methods: '',
          body_of_abstract_results: '',
          body_of_abstract_conclusions: '',
          body_of_abstract_keywords: []
        }]
      },
      symposiumEditing: -1,
      sym_chair: 'chair',
      proposalLimit: false,
      editor: ClassicEditor,
      editorSymposium: {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            if (stats.characters > 2000) {
              this.$popupError('You have reached upper limit of characters.')
              this.proposalLimit = true
            } else if (stats.characters <= 2000 && this.proposalLimit) {
              this.proposalLimit = false
            }
          }
        }
      },
      sym_totalCount: [0, 0, 0, 0],
      sym_count: [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]],
      sym_editor: [{
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[0][0] = stats.characters
            this.countSymWord(0)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[0][1] = stats.characters
            this.countSymWord(0)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[0][2] = stats.characters
            this.countSymWord(0)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[0][3] = stats.characters
            this.countSymWord(0)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[1][0] = stats.characters
            this.countSymWord(1)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[1][1] = stats.characters
            this.countSymWord(1)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[1][2] = stats.characters
            this.countSymWord(1)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[1][3] = stats.characters
            this.countSymWord(1)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[2][0] = stats.characters
            this.countSymWord(2)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[2][1] = stats.characters
            this.countSymWord(2)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[2][2] = stats.characters
            this.countSymWord(2)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[2][3] = stats.characters
            this.countSymWord(2)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[3][0] = stats.characters
            this.countSymWord(3)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[3][1] = stats.characters
            this.countSymWord(3)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[3][2] = stats.characters
            this.countSymWord(3)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[3][3] = stats.characters
            this.countSymWord(3)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[4][0] = stats.characters
            this.countSymWord(4)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[4][1] = stats.characters
            this.countSymWord(4)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[4][2] = stats.characters
            this.countSymWord(4)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[4][3] = stats.characters
            this.countSymWord(4)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[5][0] = stats.characters
            this.countSymWord(5)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[5][1] = stats.characters
            this.countSymWord(5)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[5][2] = stats.characters
            this.countSymWord(5)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[5][3] = stats.characters
            this.countSymWord(5)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[6][0] = stats.characters
            this.countSymWord(6)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[6][1] = stats.characters
            this.countSymWord(6)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[6][2] = stats.characters
            this.countSymWord(6)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[6][3] = stats.characters
            this.countSymWord(6)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[7][0] = stats.characters
            this.countSymWord(7)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[7][1] = stats.characters
            this.countSymWord(7)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[7][2] = stats.characters
            this.countSymWord(7)
          }
        }
      }, {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.sym_count[7][3] = stats.characters
            this.countSymWord(7)
          }
        }
      }],
      sym_deleteCoAuthorList: [[], [], [], [], [], [], [], []],
      sym_flag: [true, true, true, true, true, true, true, true], //  包含proposal
      sym_cat_proposal: null,
      sym_key_select: []
    }
  },
  methods: {
    addAbstract () {
      if (this.symposium.abstract_detail.length === 8) {
        this.$popupError('Your abstract ammount has hit the limit.')
        return true
      }
      const obj = {
        abstract_name: '',
        title: '',
        abstract_category: [],
        first_name: '',
        last_name: '',
        email: '',
        affiliation: '',
        country_of_affiliation_code: '',
        co_authors: [],
        body_of_abstract_title: '',
        body_of_abstract_objective_content: '',
        body_of_abstract_participants_and_methods: '',
        body_of_abstract_results: '',
        body_of_abstract_conclusions: '',
        body_of_abstract_keywords: []
      }
      this.symposium.abstract_detail.push(obj)
      this.sym_flag.push(false)
      this.sym_key_select.push('')
    },
    removeAbstract (index) {
      this.symposium.abstract_detail.splice(index, 1)
      this.sym_flag.splice(index + 1, 1)
      this.sym_key_select.splice(index, 1)
    },
    cancelEdit () {
      location.href = '/#/abstract'
    },
    // 控制現在show哪一個
    display_symposium (index) {
      this.symposiumEditing = index
      if (this.state === 'paperInfo') {
        this.sym_flag[index] = false
      }
      if (index >= 5) {
        if (this.sym_key_select[index - 1] === undefined || this.sym_key_select[index - 1] === '') {
          const name = '#keySelect' + (index - 1).toString()
          // eslint-disable-next-line
          this.sym_key_select[index - 1] = new Selectr(name, { data: this.keywords, multiple: true, maxSelections: 5 })
          this.sym_key_select[index - 1].setValue(this.symposium.abstract_detail[index - 1].body_of_abstract_keywords)
        }
      }
    },
    // proposal的作者群切換
    showSymChair (author) {
      this.sym_chair = author
      if (author === 'chair') {
        document.getElementById('chair_tab').classList.add('active')
        document.getElementById('discussant_tab').classList.remove('active')
        if (this.symposium.symposium_chair_title !== 0 && this.symposium.symposium_chair_title !== undefined) {
          var scq = 'input.radio-chair-title[value=\'' + this.symposium.symposium_chair_title + '\']'
          document.querySelector(scq).checked = true
        }
      } else if (author === 'discussant') {
        document.getElementById('chair_tab').classList.remove('active')
        document.getElementById('discussant_tab').classList.add('active')
        if (this.symposium.discussant_title !== 0 && this.symposium.discussant_title !== undefined) {
          var dcq = 'input.radio-discussant-title[value=\'' + this.symposium.discussant_title + '\']'
          document.querySelector(dcq).checked = true
        }
      }
    },
    // chair is user
    sameChair () {
      if (this.state === 'preview') {
        this.$popupError('Cannot apply this action in preview mode.')
        return true
      }
      this.symposium.symposium_chair_first_name = this.$parent.member.first_name
      this.symposium.symposium_chair_last_name = this.$parent.member.last_name
      this.symposium.symposium_chair_title = this.$parent.member.title
      this.symposium.symposium_chair_affiliation = this.$parent.member.affiliation
      this.symposium.symposium_chair_country_of_affiliation_code = this.$parent.member.country_of_affiliation_code
      this.symposium.symposium_chair_email = this.$parent.member.email
    },
    // discussant is chair
    sameDiscussant () {
      if (this.state === 'preview') {
        this.$popupError('Cannot apply this action in preview mode.')
        return true
      }
      this.symposium.discussant_first_name = this.symposium.symposium_chair_first_name
      this.symposium.discussant_last_name = this.symposium.symposium_chair_last_name
      this.symposium.discussant_title = this.symposium.symposium_chair_title
      this.symposium.discussant_affiliation = this.symposium.symposium_chair_affiliation
      this.symposium.discussant_country_of_affiliation_code = this.symposium.symposium_chair_country_of_affiliation_code
      this.symposium.discussant_email = this.symposium.symposium_chair_email
    },
    // abstract 1-6 author is same as user
    sameAbstractAuthor (index) {
      this.symposium.abstract_detail[index].first_name = this.$parent.member.first_name
      this.symposium.abstract_detail[index].last_name = this.$parent.member.last_name
      this.symposium.abstract_detail[index].title = this.$parent.member.title
      this.symposium.abstract_detail[index].affiliation = this.$parent.member.affiliation
      this.symposium.abstract_detail[index].country_of_affiliation_code = this.$parent.member.country_of_affiliation_code
      this.symposium.abstract_detail[index].email = this.$parent.member.email
    },
    // co-authors 相關
    sym_removeCoAuthor (index) {
      // eslint-disable-next-line
      let newArr = []
      this.symposium.abstract_detail[index].co_authors.forEach((el, elindex) => {
        if (!this.sym_deleteCoAuthorList[index].includes(elindex)) {
          newArr.push(el)
        }
      })
      this.symposium.abstract_detail[index].co_authors = newArr
      this.sym_deleteCoAuthorList[index] = []
    },
    sym_newCoAuthor (index) {
      const obj = {
        orders: '',
        title: '',
        first_name: '',
        last_name: '',
        affiliation: '',
        country_of_affiliation_code: ''
      }
      if (this.symposium.abstract_detail[index].co_authors.length < 9) {
        this.symposium.abstract_detail[index].co_authors.push(obj)
        this.symposium.abstract_detail[index].co_authors[this.symposium.abstract_detail[index].co_authors.length - 1].orders = '0' + (this.symposium.abstract_detail[index].co_authors.length + 1).toString()
      }
    },
    sym_sameAffiliation ($event, subIndex) {
      $event.preventDefault()
      if (this.state === 'preview') {
        this.$popupError('Cannot apply this action in preview mode.')
        return true
      }
      this.symposium.abstract_detail[subIndex].co_authors.forEach((el) => {
        el.affiliation = this.symposium.abstract_detail[subIndex].affiliation
      })
    },
    sym_sameCountry ($event, subIndex) {
      $event.preventDefault()
      if (this.state === 'preview') {
        this.$popupError('Cannot apply this action in preview mode.')
        return true
      }
      this.symposium.abstract_detail[subIndex].co_authors.forEach((el) => {
        el.country_of_affiliation_code = this.symposium.abstract_detail[subIndex].country_of_affiliation_code
      })
    },
    // save change的時候去檢查那個頁面 (proposal or abstract1-6
    checkSubForm (index) {
      const formID = 'symposiumForm' + index
      document.getElementById(formID).classList.add('symposiumForm-submitted')
      if (!document.getElementById(formID).reportValidity()) {
        this.$popupError('Please fill in all columns.')
        return true
      }
      if (index === 0) {
        if (this.proposalLimit === true) {
          this.$popupError('Your Content has reached the character limit of 2000, please check.')
          return true
        }
        if (this.sym_cat_proposal.getValue().length === 0) {
          this.$popupError('Please select Abstract Category.')
          return true
        } else if (this.symposium.symposium_content === '') {
          this.$popupError('Please submit your Symposium Content.')
        } else {
          this.sym_flag[index] = true
          this.$popupSuccess('Your change has been saved, please continue to submit the whole abstracts.')
          this.display_symposium(-1)
        }
      } else {
        // if (this.sym_cat_select[index - 1].getValue().length === 0) {
        //   this.$popupError('Please select Abstract Category.')
        //   return true
        // }
        if (this.sym_key_select[index - 1].getValue().length === 0) {
          this.$popupError('Please select Keywords.')
          return true
        }
        if (this.sym_totalCount[index - 1] > 2000) {
          this.$popupError('Your Abstract has reached the character limit of 2000, please check.')
          return true
        }
        if (this.symposium.abstract_detail[index - 1].body_of_abstract_objective_content === '') {
          this.$popupError('Please submit your Objective.')
        } else if (this.symposium.abstract_detail[index - 1].body_of_abstract_participants_and_methods === '') {
          this.$popupError('Please submit your Participants and Methods.')
        } else if (this.symposium.abstract_detail[index - 1].body_of_abstract_results === '') {
          this.$popupError('Please submit your Results.')
        } else if (this.symposium.abstract_detail[index - 1].body_of_abstract_conclusions === '') {
          this.$popupError('Please submit your Conclusions.')
        } else {
          this.sym_flag[index] = true
          this.display_symposium(-1)
          this.$popupSuccess('Your change has been saved, please continue to submit the whole abstracts.')
        }
      }
    },
    checkSymposium () {
      const size = this.symposium.abstract_detail.length + 1
      for (let index = 0; index < size; index++) {
        if (this.sym_flag[index] === false) {
          this.$popupError('Please submit all your abstract content.')
          return true
        } else {
          if (index === this.symposium.abstract_detail.length) {
            this.toSymPreview()
          }
        }
      }
    },
    countSymWord (index) {
      this.sym_totalCount[index] = 0
      this.sym_count[index].forEach(el => {
        this.sym_totalCount[index] += el
      })
    },
    confirmReset (index) {
      this.$swal.fire({
        icon: 'warning',
        title: 'Reset Abstract',
        text: 'Are you sure to reset all the data of this abstract?',
        confirmButtonText: 'Yes!',
        cancelButtonText: 'Cancel',
        focusConfirm: false,
        confirmButtonColor: '#601985',
        showCancelButton: true
        // reverseButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetSymposium(index)
        }
      })
    },
    resetSymposium (index) {
      this.sym_flag[index] = false
      if (index === 0) {
        this.symposium.symposium_chair_first_name = ''
        this.symposium.symposium_chair_last_name = ''
        this.symposium.symposium_chair_title = ''
        this.symposium.symposium_chair_affiliation = ''
        this.symposium.symposium_chair_country_of_affiliation_code = ''
        this.symposium.symposium_chair_email = ''
        this.symposium.discussant_first_name = ''
        this.symposium.discussant_last_name = ''
        this.symposium.discussant_title = ''
        this.symposium.discussant_affiliation = ''
        this.symposium.discussant_country_of_affiliation_code = ''
        this.symposium.discussant_email = ''
        this.symposium.symposium_abstract_title = ''
        this.symposium.symposium_abstract_category = []
        this.symposium.symposium_content = ''
      } else {
        this.symposium.abstract_detail[index - 1].abstract_name = ''
        this.symposium.abstract_detail[index - 1].title = ''
        this.symposium.abstract_detail[index - 1].abstract_category = []
        this.symposium.abstract_detail[index - 1].first_name = ''
        this.symposium.abstract_detail[index - 1].last_name = ''
        this.symposium.abstract_detail[index - 1].email = ''
        this.symposium.abstract_detail[index - 1].affiliation = ''
        this.symposium.abstract_detail[index - 1].country_of_affiliation_code = ''
        this.symposium.abstract_detail[index - 1].co_authors = []
        this.symposium.abstract_detail[index - 1].body_of_abstract_title = ''
        this.symposium.abstract_detail[index - 1].body_of_abstract_objective_content = ''
        this.symposium.abstract_detail[index - 1].body_of_abstract_participants_and_methods = ''
        this.symposium.abstract_detail[index - 1].body_of_abstract_results = ''
        this.symposium.abstract_detail[index - 1].body_of_abstract_conclusions = ''
        this.symposium.abstract_detail[index - 1].body_of_abstract_keywords = []
        this.sym_key_select[index - 1].clear()
      }
    },
    toSymPreview () {
      this.state = 'preview'
      for (let i = 0; i < this.sym_flag.length; i++) {
        // eslint-disable-next-line
        var query = '#symposiumForm'+ i + ' .preview'
        // eslint-disable-next-line
        var previewInput = document.querySelectorAll(query)
        Array.prototype.forEach.call(previewInput, function (el) {
          if (el.type === 'radio') {
            el.setAttribute('onclick', 'return false;')
          } else if (el.type === 'checkbox') {
            el.setAttribute('disabled', 'disabled')
          } else {
            el.setAttribute('readonly', true)
            el.style.backgroundColor = '#f2f2f2'
          }
        })
      }
      const selectbtn = document.getElementsByClassName('selectr-tag-remove')
      Array.prototype.forEach.call(selectbtn, function (el) {
        el.style.display = 'none'
      })
      this.sym_cat_proposal.disable()
      this.sym_key_select.forEach((el) => {
        el.disable()
      })
    },
    toSymEditing () {
      this.state = 'paperInfo'
      const selectbtn = document.getElementsByClassName('selectr-tag-remove')
      Array.prototype.forEach.call(selectbtn, function (el) {
        el.style.display = 'block'
      })
      this.sym_cat_proposal.enable()
      this.sym_key_select.forEach((el) => {
        el.enable()
      })
      for (let i = 0; i < this.sym_flag.length; i++) {
        // eslint-disable-next-line
        var query = '#symposiumForm' + i + ' .preview'
        // eslint-disable-next-line
        var previewInput = document.querySelectorAll(query)
        Array.prototype.forEach.call(previewInput, function (el) {
          if (el.type === 'radio') {
            el.removeAttribute('onclick')
          } else if (el.type === 'checkbox') {
            el.removeAttribute('disabled')
          } else {
            el.removeAttribute('readonly')
            el.style.backgroundColor = 'white'
          }
        })
      }
    },
    SUBMITSYMPOSIUM () {
      for (let i = 0; i < this.symposium.abstract_detail.length; i++) {
        // eslint-disable-next-line
        var firstCoAuthor = {
          orders: '01',
          title: this.symposium.abstract_detail[i].title,
          first_name: this.symposium.abstract_detail[i].first_name,
          last_name: this.symposium.abstract_detail[i].last_name,
          affiliation: this.symposium.abstract_detail[i].affiliation,
          country_of_affiliation_code: this.symposium.abstract_detail[i].country_of_affiliation_code
        }
        if (this.symposium.abstract_detail[i].co_authors.length === 0) {
          this.symposium.abstract_detail[i].co_authors.unshift(firstCoAuthor)
        } else {
          if (this.symposium.abstract_detail[i].co_authors[0].orders !== '01') {
            this.symposium.abstract_detail[i].co_authors.unshift(firstCoAuthor)
          }
        }
      }
      this.$axios.post(this.$apiUrl + 'submission/edit_abstractlist', this.symposium, {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      }).then((res) => {
        if (res.data.resultCode === '000') {
          this.$popupSuccess('Your Abstract has been changed!')
          location.href = '/#/abstract'
        } else if (res.data.resultCode === '006') {
          this.$tokenError()
        } else {
          this.$popupError()
        }
      })
    }
  },
  beforeMount () {
    if (this.$route.query.t !== undefined) {
      localStorage.setItem('accessToken', this.$route.query.t)
      localStorage.setItem('memberId', this.$route.query.mid)
    }
  },
  mounted () {
    if (localStorage.getItem('accessToken') !== null) {
      this.$axios.post(this.$apiUrl + 'submission/abstract', { abstract_id: this.$route.params.id }, {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      })
        .then((res) => {
          if (res.data.resultCode === '000') {
            if (res.data.data.presentation_type === '4') {
              this.symposium = res.data.data
              this.symposium.abstract_id = this.$route.params.id
              for (let i = 0; i < 5; i++) {
                this.symposium.abstract_detail[i].co_authors.shift()
              }
              if (this.symposium.abstract_detail.length > 5) {
                this.sym_flag.push(true)
              }
            } else {
              location.href = '/#/submit/edit_abstract/' + this.$route.params.id
            }
          } else if (res.data.resultCode === '006') {
            this.$tokenError()
          } else if (res.data.resultCode === '007') {
            this.$popupError()
            location.href = '/#/abstract'
          } else {
            location.reload()
          }
        })
    } else {
      this.$popupHint('Please login your account before editting abstracts.')
      location.href = '/#/login'
    }

    const categoryApi = 'submission/category'
    this.$axios.get(this.$apiUrl + categoryApi).then((res) => {
      this.categories = res.data.data
      // eslint-disable-next-line
      this.sym_cat_proposal = new Selectr('#proposalCategorySelect', {data: this.categories, multiple: true, maxSelections: 3})
      this.sym_cat_proposal.setValue(this.symposium.symposium_abstract_category)
    })
    const keywordApi = 'submission/keywords'
    this.$axios.get(this.$apiUrl + keywordApi, {
      headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken') }
    }).then((res) => {
      this.keywords = res.data.data
      for (let i = 0; i < 4; i++) {
        // eslint-disable-next-line
        var idname = '#keySelect' + i.toString()
        // eslint-disable-next-line
        this.sym_key_select[i] = new Selectr(idname, {data: this.keywords, multiple: true, maxSelections: 5})
        this.sym_key_select[i].setValue(this.symposium.abstract_detail[i].body_of_abstract_keywords)
      }
    })
  }
}
</script>
