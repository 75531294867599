<template>
  <div class="page-holder">
    <div class="forget page-content">
        <div class="subtitle">Forgot My Password</div>
        <div class="text" style="text-align:left;">Please enter the requested information below if you have forgotten your password; the system will email the login information to your registered email address momentarily.</div>
        <form class="signup__form">
            <label for="" class="item required">First (Given) Name</label>
            <div class="inputwrap">
              <input type="text" id="forgetFirstName">
            </div>
            <label for="" class="item required">Last (Family) Name</label>
            <div class="inputwrap">
              <input type="text" id="forgetLastName">
            </div>
            <label for="" class="item required">Email</label>
            <div class="inputwrap">
              <input type="text" id="forgetEmail">
            </div>
            <div class="btns">
              <input type="button" value="Submit" @click="forgetPW()">
            </div>
        </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgetPWComp',
  methods: {
    forgetPW () {
      const user = {
        first: document.getElementById('forgetFirstName').value,
        last: document.getElementById('forgetLastName').value,
        email: document.getElementById('forgetEmail').value
      }
      if (this.$checkEmail(user.email) && user.first !== '' && user.last !== '') {
        this.$axios.post(this.$apiUrl + 'members/forgotPassword', {
          first_name: user.first,
          last_name: user.last,
          email: user.email
        }, { headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') } })
          .then((res) => {
            if (res.data.resultCode === '000') {
              this.$swal.fire({
                icon: 'success',
                title: 'Your password has been successfully changed.',
                text: 'redirect to login page...',
                showConfirmButton: false,
                timer: '2000'
              })
            } else if (res.data.resultCode === '006') {
              this.$tokenError()
            } else {
              this.$popupError('Please try again')
            }
          })
      } else {
        this.$popupError('Please check your email or password.')
      }
    }
  }
}
</script>
