<template>
  <div class="page-holder">
    <div class="page-content summit receipt">
        <div class="title" style="margin-bottom: 18px;">Payment Status</div>
        <div class="receipt__text" v-if="status == 1">
            Please check your full reicpt in account page or
            <router-link to="/registration/receipt">click here to review your receipt</router-link>
        </div>
        <div class="receipt__text" v-else-if="status == 2">
            Your payment has been failed.<br>
            <router-link to="/registration">click here to place your order again</router-link>
        </div>
        <div class="receipt__text" v-else-if="status == 3">
            An information error occurred<br>
            <router-link to="/login">click here to re-login.</router-link>
        </div>
        <div class="receipt__text" v-else-if="status == 4">
            Your payment has been canceled.<br>
            <router-link to="/registration">click here to place your order again</router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentComp',
  data () {
    return {
      status: 0
    }
  },
  methods: {
  },
  mounted () {
    this.status = this.$route.params.status
    if (this.$route.params.status === 3) {
      localStorage.removeItem('accessToken')
      localStorage.setItem('memberId', 0)
    }
  }
}
</script>
