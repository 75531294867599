<template>
  <div class="page-holder">
    <div class="page-content login">
        <div class="login__title title">Log in to INS 2023 Taiwan Meeting Online System</div>
        <div class="login__text text">Welcome to 2023 International Neuropsychology<br>Society Taiwan Meeting Online System</div>
        <form class="login__form">
          <label for="">Email</label>
          <input type="text" id="loginEmail">
          <div class="hint" >hint: error input</div>
          <label for="">Password</label>
          <input type="password" id="loginPassword">
          <div class="hint" >hint: error input</div>
          <router-link to="/forgetPW">Forget Password?</router-link>
          <input type="button" value="Log in" @click="login()">
        </form>
        <div class="login__or">or</div>
        <router-link to="/signup"  class="login__btn login__btn--signup" ><input type="button" class="signup" value="Create My Account"></router-link>
        <div class="login__note">
          Please note that:
          <ul>
            <li>If you are a new user of this online system, please create a new account first.</li>
            <li>All participants are required to create a personal account through the INS 2023 Taiwan Meeting Online System.</li>
            <li>Please login with valid email and password to proceed with paper submission and registration.</li>
            <li>If you’ve forgotten your password, kindly click <u><b>Forgot Password</b></u> and provide the relevant information to reset your password.</li>
            <li>Please fill in the <b>DETAILS</b> about your affiliation. e.g., <u>Department of Neurology, National Taiwan University, Taipei, Taiwan.</u></li>
          </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginComp',
  methods: {
    login () {
      const user = {
        email: document.getElementById('loginEmail').value,
        password: document.getElementById('loginPassword').value
      }
      if (this.$checkEmail(user.email) && user.password !== '') {
        this.$axios.post(this.$apiUrl + 'members/login', {
          email: user.email,
          password: user.password
        }, { headers: { 'Content-Type': 'application/json' } })
          .then((res) => {
            if (res.data.resultCode === '000') {
              localStorage.setItem('accessToken', res.data.resultMap.token)
              location.href = '/#/account'
            } else if (res.data.resultCode === '019') {
              this.$popupError('Please activate your email.')
            } else {
              this.$popupError('Please check your email or password.')
            }
          })
      } else {
        this.$popupError('Please check your email or password.')
      }
    }
  },
  mounted () {
    if (localStorage.getItem('accessToken') !== null) {
      location.href = '/#/account'
    }
  }
}
</script>
