<template>
  <div class="page-holder">
    <div class="page-content trip summit">
        <div class="title" style="margin-bottom:20px;">「9th July, 2023 International Neuropsychological Society Taiwan Meeting」Post-conference Tours</div>
        <!-- <div  style="margin-bottom:12px;">「2023 International Neuropsychological Society Taiwan Meeting」Post-conference Tours</div> -->
        <template v-for="day in tour">
            <div :key="day.date" class="trip__content">
                <div class="trip__date">{{day.date}}</div>
                <div class="trip__schedule">
                    <div class="head">Date</div>
                    <div class="head">Contents</div>
                    <div class="head">Remark</div>
                    <template v-for="(detail, d_index) in day.schedule">
                        <div :key="d_index + '-time'">{{detail.time}}</div>
                        <div :key="d_index + '-content'">{{detail.contents}}</div>
                        <div :key="d_index + '-remark'" v-if="!detail.link">{{detail.remark}}</div>
                        <div :key="d_index + '-remark'" v-else-if="detail.remark =='Beitou Park'" style="flex-direction: column;">
                            <a :href="detail.link">{{detail.remark}}</a>
                            <a href="https://hotspringmuseum.taipei/News_Content.aspx?n=7F7676C34AC3FCF9&sms=CDFD835559552999&s=14090D7DD708233F">Beitou Hot Spring Museum</a>
                            <a href="https://www.xbths.taipei/en/about.php">Xinbeibou Historic Station</a>
                            <a href="https://www.travel.taipei/en/attraction/details/536">Thermal Valley</a>
                        </div>
                        <a :key="d_index + '-link'" target="_blank" :href="detail.link" v-else>{{detail.remark}}</a>
                    </template>
                </div>
            </div>
        </template>
        <!-- <div class="trip__notice">
            <div class="subtitle">【Tour Cost】</div>
            <div>
                R.O.C. Citizens NT$2,400/per person<br>
                Foreign Citizens NT $2100/per person
            </div>
        </div> -->
        <div class="trip__notice">
            <div class="subtitle">【Services included】</div>
            <div>
                All transportation mentioned above <br>
                Lunch<br>
                All entrance tickets<br>
                English speaking guide<br>
                Travel insurance
            </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialComp',
  data () {
    return {
      tour: [{
        date: 'Beitou Park and National Palace Museum (1 day)',
        schedule: [{
          time: '08:00',
          contents: 'Depart from TICC'
        }, {
          time: '08:00-08:20',
          contents: 'TICC to National Chiang Kai-shek',
          remark: 'Tour Bus'
        }, {
          time: '08:20-08:50',
          contents: 'National Chiang Kai-shek Memorial Hall',
          remark: 'National Chiang Kai-shek Memorial Hall',
          link: 'https://www.cksmh.gov.tw/en/content_126.html'
        }, {
          time: '08:50-09:40',
          contents: 'Head to Beitou Park',
          remark: 'Tour Bus'
        }, {
          time: '09:40-11:40',
          contents: 'Beitou Park (Beitou Park integrates historical sites, hot spring culture and natural scenery. We will visits Xinbeitou Station, Hot Spring Museum, Beitou Library, Geothermal Valleyand other special attractions)',
          remark: 'Beitou Park',
          link: 'https://www.travel.taipei/en/attraction/details/532'
        }, {
          time: '11:40-12:10',
          contents: 'Head to National Palace Museum',
          remark: 'Tour Bus'
        }, {
          time: '12:10-13:30',
          contents: 'Lunch (Silks Palace)',
          remark: 'Silks Palace',
          link: 'https://www.silkspalace.com.tw/introflr-en'
        }, {
          time: '13:30-16:00',
          contents: 'National Palace Museum (Learn about the valuables of Chinese culture)',
          remark: 'National Palace Museum',
          link: 'https://www.npm.gov.tw/Articles.aspx?sno=03012532&l=2'
        }, {
          time: '16:00-17:00',
          contents: 'Return to TICC',
          remark: 'Tour Bus'
        }]
      }]
    }
  }
}
</script>
