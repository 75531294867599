<template>
  <div class="page-holder">
    <div class="page-content news">
        <div class="title">Your Order Receipt</div>
        <div class="payNote">*Please reload this page to check your payment status after your payment completed.</div>
        <div class="payEmpty" v-show="list.length == 0">{{listStatus}}</div>
        <div class="paymentlist">
            <div class="order" v-for="(order, oIndex) in list" :key="order.orderno">
                <div class="row row-head">
                    <div class="no warning" style="font-weight: 600;">{{order.orderno}}</div>
                    <div class="time">{{order.create_time}}</div>
                </div>
                <div class="row" >
                    <div>Purchaser / Affiliation</div>
                    <div>{{user.first_name}} {{user.last_name}} / {{user.affiliation}}</div>
                </div>
                <div class="row" >
                    <div>Payment Status</div>
                    <div :class="statusClass(order.order_status_name)">{{order.order_status_name}}</div>
                </div>
                <div class="row">
                    <div>Bill Price</div>
                    <div class="price">${{order.amount}}</div>
                </div>
                <div class="details" v-if="order.orderno[0]=='I'" >
                    <div class="head item">Product Name</div>
                    <div class="head">Unit Price</div>
                    <div class="head">Amount</div>
                    <div class="head subtotal">Item Subtotal</div>
                    <div v-if="JSON.parse(order.data_json).registText">{{JSON.parse(order.data_json).registText}}</div>
                    <div v-else class="item">Registration Fee - {{feeText(JSON.parse(order.data_json).identity)}}</div>
                    <div>${{feeUnit(JSON.parse(order.data_json).identity, oIndex)}}</div>
                    <div>1</div>
                    <div class="subtotal">${{feeUnit(JSON.parse(order.data_json).identity, oIndex)}}</div>
                    <div class="item">Accompaniment fee</div>
                    <div>$1600</div>
                    <div>{{JSON.parse(order.data_json).accompany}}</div>
                    <div class="subtotal">${{1600 * JSON.parse(order.data_json).accompany}}</div>
                    <div class="item">Continuing education courses fee</div>
                    <div>$2250</div>
                    <div>{{JSON.parse(order.data_json).bill.course}}</div>
                    <div class="subtotal">${{2250 * JSON.parse(order.data_json).bill.course}}</div>
                    <div class="item">Dinner fee</div>
                    <div>$1600</div>
                    <div>{{JSON.parse(order.data_json).bill.dinner}}</div>
                    <div class="subtotal">${{1600 * JSON.parse(order.data_json).bill.dinner}}</div>
                    <div class="item">Taiko drum show and snack fee</div>
                    <div>$450</div>
                    <div>{{JSON.parse(order.data_json).bill.show}}</div>
                    <div class="subtotal">${{450 * JSON.parse(order.data_json).bill.show}}</div>
                    <div class="item">Speciel Event fee</div>
                    <div>$2150</div>
                    <div>{{JSON.parse(order.data_json).bill.event}}</div>
                    <div class="subtotal">${{2150 * JSON.parse(order.data_json).bill.event}}</div>
                </div>
                <div class="details" v-else>
                    <div class="head item">Product Name</div>
                    <div class="head">Unit Price</div>
                    <div class="head">Amount</div>
                    <div class="head subtotal">Item Subtotal</div>
                    <div class="item">{{tourPlan(order.amount)}}</div>
                    <div>${{order.amount}}</div>
                    <div>1</div>
                    <div class="subtotal">${{order.amount}}</div>
                </div>
                <div class="footie">
                  <div class="left">
                    <div class="wrap">
                      <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/payment-logo.jpg" alt="" class="logo" style="height:100px; margin-left:-10px; padding: 0;">
                      <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-logo-tandmr.png" alt="" class="logo" style="margin-right:12px;">
                    </div>
                    <div style="padding:0;">
                      taiwanndmr105@gmail.com<br>
                      Rm. 308,309, 3F., No. 861, Xilaya Blvd., Xinshi Dist., Tainan City 744094 , Taiwan (R.O.C.)
                    </div>
                  </div>
                  <div class="payment">
                      <div class="price"><div class="label">Total Payment:&nbsp;&nbsp;&nbsp;&nbsp;</div> NTD${{order.amount}}</div>
                      <!-- <div class="btn btn--cancel" v-if="order.order_status == 1" @click="cancelOrder(order)">Cancel Order</div> -->
                      <!-- <div class="btn" v-else-if="order.order_status == 0" @click="proceedPay(order)" >Proceed to Pay</div> -->
                      <div class="btn" @click="proceedPay(order)" >Proceed to Pay</div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReceiptComp',
  data () {
    return {
      user: {},
      list: [],
      listStatus: 'loading...',
      twFee: [
        [10650, 13400, 13700, 16450, 7600, 9150, 8540, 10050],
        [7800, 9900, 9900, 12200, 5000, 6500, 6250, 7050],
        [6400, 8100, 8100, 11150, 4600, 5500, 5200, 6000]
      ],
      usFee: [
        [350, 440, 450, 540, 250, 300, 280, 330],
        [255, 323, 323, 400, 165, 122, 205, 230],
        [210, 265, 265, 365, 150, 180, 170, 200]
      ]
    }
  },
  methods: {
    proceedPay (order) {
      const url = this.$apiUrl + 'orders/pay?membertoken=' + localStorage.getItem('accessToken') + '&amt=' + order.amount + '&orderno=' + order.orderno
      window.open(url, '_blank')
    },
    cancelOrder (order) {
      const url = this.$apiUrl + 'orders/pay_cancel?membertoken=' + localStorage.getItem('accessToken') + '&orderno=' + order.orderno
      this.$swal.fire({
        icon: 'warning',
        title: 'Are you sure about canceling this order?',
        text: 'Click Cancel Order to cancel your payment, this action cannot be undone.',
        confirmButtonText: 'Cancel Order',
        cancelButtonText: 'No',
        focusConfirm: false,
        confirmButtonColor: '#d95244',
        showCancelButton: true,
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(url, '_blank')
          location.reload()
        }
      })
    },
    feeText (identity) {
      let text = ''
      switch (identity) {
        case 'member-early':
          text = 'Member Early Bird'
          break
        case 'member-regular':
          text = 'Member Regular'
          break
        case 'non-member-early':
          text = 'Non Member Early Bird'
          break
        case 'non-member-regular':
          text = 'Non Member Regular'
          break
        case 'student-early':
          text = 'Student Member Early Bird '
          break
        case 'student-regular':
          text = 'Student Member Regular'
          break
        case 'non-student-early':
          text = 'Non Student Member Early Bird '
          break
        case 'non-student-regular':
          text = 'Non Student Member Regular'
          break
      }
      return text
    },
    feeUnit (identity, orderIndex) {
      let text = ''
      // console.log(orderIndex)
      // console.log(JSON.parse(this.list[orderIndex].data_json))
      const index = JSON.parse(this.list[orderIndex].data_json).country ? JSON.parse(this.list[orderIndex].data_json).country : 0
      // const index = 0
      // console.log(index)
      switch (identity) {
        case 'member-early':
          text = this.twFee[index][0]
          break
        case 'member-regular':
          text = this.twFee[index][1]
          break
        case 'non-member-early':
          text = this.twFee[index][2]
          break
        case 'non-member-regular':
          text = this.twFee[index][3]
          break
        case 'student-early':
          text = this.twFee[index][4]
          break
        case 'student-regular':
          text = this.twFee[index][5]
          break
        case 'non-student-early':
          text = this.twFee[index][6]
          break
        case 'non-student-regular':
          text = this.twFee[index][7]
          break
      }
      return text
    },
    tourPlan (plan) {
      let text = ''
      switch (plan) {
        case '28975':
          text = 'For 1 person: 2 PEOPLE SHARING ROOM'
          break
        case '39650':
          text = 'For 1 person: SIGNAL ROOM'
          break
        case '57950':
          text = 'For 2 persons: TWIN ROOM'
          break
        case '73200':
          text = 'For 3 persons: TRIPLE ROOM'
          break
        case '85400':
          text = 'For 4 persons: QUADRUPLE ROOM'
          break
      }
      return text
    },
    statusClass (status) {
      if (status === 'Success') {
        return 'success'
      } else {
        return 'fail'
      }
    }
  },
  created () {

  },
  mounted () {
    const self = this
    if (localStorage.getItem('accessToken') !== null) {
      this.$axios.get(this.$apiUrl + 'members/info', {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      })
        .then((res) => {
          if (res.data.resultCode === '006') {
            this.$tokenError()
          } else {
            self.user = res.data.data
            self.$axios({
              method: 'post',
              url: self.$apiUrl + 'orders/getorders',
              headers: {
                'Content-Type': 'multipart/form-data; charset=utf-8;',
                Accept: 'application/json',
                'member-token': localStorage.getItem('accessToken')
              }
            }).then((resdata) => {
              // console.log(resdata.data.resultMap)
              self.list = resdata.data.resultMap.pageList.list
              if (self.list.length === 0) {
                self.listStatus = 'Your have not place any order.'
              }
            })
          }
        })
    } else {
      location.href = '/#/login'
    }
  }
}
</script>
