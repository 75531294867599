<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">Transpotation</div>
        <div class="aboutInfo__title">Getting Around in Taiwan</div>
        <div class="aboutInfo__content">
            <ul>
                <li>Domestic Flight</li>
                <div>Taiwan's domestic air network provides flights to link 13 additional airports, serving major centres like Taitung, Kenting, Tainan and Chiayi, as well as off-island territories such as Kinmen, Mazu, Penghu, Green Island and Orchid Island.</div>
                <li>Taiwan High Speed Rail</li>
                <div>Taiwan has an extensive and efficient transport infrastructure, making travel aroung the island and its cities convenient and stress free. The Taiwan High Speed Rail (THSR) covers 345 kilometres along the west side of the island, whisking passengers from Taipei in the north to Kaoshiug in the south in just 90 minutes.</div>
                <li>Mainline Rail Network</li>
                <div>Taiwan also has an established and extensive mainline rail network providing an inexpensive means of getting around the island. The network has stations conveniently located in the centree of cities and towns.</div>
                <li>Metro Systems (MRT)</li>
                <div>The municipalities of Taipei and Kaoshiung have metro systems called the MRT, providing very cheap and convenient transport. They are rated as some of the best in the world with unparalleled levels of punctuality, reliability and cleanliness.</div>
                <li>Bus, Car, and Taxi</li>
                <div>Twenty-four hours bus services for long distance trips are provided by most bus companies, while overseas visitors can easily hire a car with an international driving licence. Taiwan also offers an abundance of cheap, safe and reliable taxis.</div>
            </ul>
        </div>
        <div class="aboutInfo__title">From Taoyuan International Airport</div>
        <ul>
            <li>Step 1: From Taoyuan Airport Terminal 1 (A12) /Taoyuan Airport Terminal 2 (A13) to Taipei Main Station (A1) by Taoyuan Airport MRT.</li>
            <li>Step 2: Take Taipei Metro from Taipei Main Station (BL12/R10) to Taipei 101/ World Trade Center (R03).</li>
            <li>Step 3: Get off at the Taipei 101/ World Trade Center (R03). Taipei International Convention Center is a short 1-min walk from the station, via Exit #1.</li>
        </ul>
        <div class="aboutInfo__title">From SongShan Airport</div>
        <ul>
            <li>Step 1: From SongShan Airport (BR13) to MRT Taipei 101/ World Trade Center (R03) by Taipei Metro.</li>
            <li>Step 2: Get off at the Taipei 101/ World Trade Center (R03). Taipei International Convention Center is a short 1-min walk from the station, via Exit #1.</li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisitTrasportationComp'
}
</script>
