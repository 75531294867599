<template>
  <div class="navbar wrapper">
      <router-link to="/" class="wrapper">
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-logo-big-02.png" alt="" class="pic pic--navLogo">
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-logo-tandmr.png" alt="" class="pic pic--navLogo2">
      </router-link>
    <div class="wrapper lg navbar__tabs">
      <div class="navbar__tab">About
        <div class="navbar__drawer">
          <router-link to="/about/welcome"  class="navbar__drawer--item">Welcome Message</router-link>
          <router-link to="/about/conference"  class="navbar__drawer--item">About INS 2023</router-link>
          <router-link to="/about/organizer"  class="navbar__drawer--item">About Organizer</router-link>
          <router-link to="/about/venue"  class="navbar__drawer--item">Venue</router-link>
        </div>
      </div>
      <div class="navbar__tab">Program
        <div class="navbar__drawer">
          <!-- <router-link to="/program/book" class="navbar__drawer--item">Program Book</router-link> -->
          <router-link to="/program" class="navbar__drawer--item">Program at a Glance</router-link>
          <!-- <div class="navbar__drawer--item coming">Daily Program</div> -->
          <router-link to="/program/speakers" class="navbar__drawer--item">Keynote Speakers</router-link>
          <router-link to="/program/course" class="navbar__drawer--item">Continuing Education Courses</router-link>
          <router-link to="/program/social" class="navbar__drawer--item">Special Event</router-link>
          <router-link to="/program/book" class="navbar__drawer--item">Program Book</router-link>
        </div>
      </div>
      <div class="navbar__tab">Abstract
        <div class="navbar__drawer">
          <router-link to="/abstract/submissionGuideline" class="navbar__drawer--item">Submission Guideline</router-link>
          <router-link to="/abstract/presentationGuideline" class="navbar__drawer--item">Presentation Guideline</router-link>
          <router-link to="/abstract/grandAward" class="navbar__drawer--item">Grand & Award</router-link>
        </div>
      </div>
      <div class="navbar__tab">Registration
        <div class="navbar__drawer">
          <router-link to="/registration" class="navbar__drawer--item">Online Registration</router-link>
          <router-link to="/registration/trip" class="navbar__drawer--item">Extension Tour</router-link>
          <router-link to="/registration/accommodation" class="navbar__drawer--item">Accommodation</router-link>
        </div>
      </div>
      <router-link to="/sponsorship" class="navbar__tab">Sponsorship & Exhibition</router-link>
      <div class="navbar__tab">Visiting Taiwan
        <div class="navbar__drawer">
          <router-link to="/visit/taiwan" class="navbar__drawer--item">About Taiwan</router-link>
          <router-link to="/visit/taipei" class="navbar__drawer--item">About Taipei</router-link>
          <router-link to="/visit/transportation" class="navbar__drawer--item">Transportation</router-link>
          <!-- <router-link to="/visit/visa"  class="navbar__drawer--item"></router-link> -->
          <div class="navbar__drawer--item">Visa Information</div>
        </div>
      </div>
      <div class="navbar__tab" @click="scrolltoBottom()">Contact Us</div>
      <router-link to="/albums" class="navbar__tab">Photo</router-link>
    </div>
    <div class="wrapper lg">
      <router-link to="/login" class="navbar__shortcut">
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-register-default.png" alt="" class="pic pic--navIcon">
        {{is_Login ? 'Account': 'Login'}}
      </router-link>
      <div class="navbar__shortcut" @click="popupSubscribe">
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-sub-default.png" alt="" class="pic pic--navIcon">
        Subscribe
      </div>
    </div>
    <div class="sm hamburgur" id="hamburgur" @click="ctrlSidebar">
      <div class="hamburgur__list" id="sidebar">
        <div class="hamburgur__item hamburgur__item--more" @click.stop="dropdown('about')">About</div>
        <div class="hamburgur__dropdown hamburgur__dropdown--default" id="dropdown-about">
          <router-link to="/about/welcome"  class="hamburgur__dropdownItem">Welcome Message</router-link>
          <router-link to="/about/conference"  class="hamburgur__dropdownItem">About INS 2023</router-link>
          <router-link to="/about/organizer"  class="hamburgur__dropdownItem">About Organizer</router-link>
          <router-link to="/about/venue"  class="hamburgur__dropdownItem">Venue</router-link>
        </div>
        <div class="hamburgur__item hamburgur__item--more" @click.stop="dropdown('program')">Program</div>
        <div class="hamburgur__dropdown hamburgur__dropdown--default" id="dropdown-program">
          <!-- <router-link to="/program/book" class="hamburgur__dropdownItem">Program Book</router-link> -->
          <router-link to="/program" class="hamburgur__dropdownItem">Program at a Glance</router-link>
          <!-- <div class="hamburgur__dropdownItem">Daily Program</div> -->
          <router-link to="/program/speakers" class="hamburgur__dropdownItem">Keynote Speakers</router-link>
          <router-link to="/program/course" class="hamburgur__dropdownItem">Continuing Education Courses</router-link>
          <router-link to="/program/social" class="hamburgur__dropdownItem">Special Event</router-link>
          <router-link to="/program/book" class="hamburgur__dropdownItem">Program Book</router-link>
        </div>
        <div class="hamburgur__item hamburgur__item--more" @click.stop="dropdown('abstract')">Abstract</div>
        <div class="hamburgur__dropdown hamburgur__dropdown--default" id="dropdown-abstract">
          <router-link to="/abstract/submissionGuideline"  class="hamburgur__dropdownItem">Submission Guideine</router-link>
          <router-link to="/abstract/presentationGuideline"  class="hamburgur__dropdownItem">Presentation Guideine</router-link>
          <router-link to="/abstract/grandAward"  class="hamburgur__dropdownItem">Grand & Award</router-link>
        </div>
        <div class="hamburgur__item hamburgur__item--more" @click.stop="dropdown('registration')">Registration</div>
        <div class="hamburgur__dropdown hamburgur__dropdown--default" id="dropdown-registration">
          <router-link to="/registration/" class="hamburgur__dropdownItem">Online Registration</router-link>
          <router-link to="/registration/trip" class="hamburgur__dropdownItem">Extension Tour</router-link>
          <router-link to="/registration/accommodation" class="hamburgur__dropdownItem">Accommodation</router-link>
        </div>
        <router-link to="/sponsorship" class="hamburgur__item">Sponsorship & Exhibition</router-link>
        <div class="hamburgur__item hamburgur__item--more" @click.stop="dropdown('visit')">Visting Taiwan</div>
        <div class="hamburgur__dropdown hamburgur__dropdown--default" id="dropdown-visit">
          <router-link to="/visit/taiwan" class="hamburgur__dropdownItem">About Taiwan</router-link>
          <router-link to="/visit/taipei" class="hamburgur__dropdownItem">About Taipei</router-link>
          <router-link to="/visit/transportation" class="hamburgur__dropdownItem">Transportation</router-link>
          <!-- <router-link to="/visit/visa"  class="hamburgur__dropdownItem"></router-link> -->
          <div class="hamburgur__dropdownItem">Visa Information</div>
        </div>
        <!-- <div class="hamburgur__item hamburgur__item--more" @click.stop="dropdown('contact')">Contact Us</div> -->
        <div class="hamburgur__item" @click="scrolltoBottom()">Contact Us</div>
        <!-- <div class="hamburgur__dropdown hamburgur__dropdown--default" id="dropdown-contact">
          <router-link to="/contact/info" class="hamburgur__dropdownItem">Contact Infomation</router-link>
          <div class="hamburgur__dropdownItem">Related Event</div>
        </div> -->
        <router-link to="/albums" class="hamburgur__item">Photo</router-link>
        <router-link to="/login" class="hamburgur__shortcut">{{is_Login ? 'Account': 'Login'}}</router-link>
        <div class="hamburgur__shortcut" @click="popupSubscribe">Subscribe</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      sidebar: false,
      selector: '<select id="subNation"><option disabled selected value> </option>',
      is_Login: false
    }
  },
  methods: {
    ctrlSidebar () {
      if (!this.sidebar) {
        document.getElementById('sidebar').style.right = '0'
        document.getElementById('hamburgur').classList.add('close')
        this.sidebar = true
      } else {
        document.getElementById('sidebar').style.right = '-85vw'
        document.getElementById('hamburgur').classList.remove('close')
        this.sidebar = false
      }
    },
    comingSoon () {
      Array.from(document.getElementsByClassName('coming')).forEach(element => {
        const temp = document.createElement('div')
        temp.className = 'tooltip'
        temp.textContent = 'coming soon'
        element.appendChild(temp)
      })
    },
    popupSubscribe () {
      const self = this
      this.$swal.fire({
        html: `
        <div class="popup">
          <div class="title" >SubScribe</div>
          <div class="wrapper flex-column popup__sub">
            <div class="grid">
              <label for="">First(Given) Name</label>
              <input type="text" id="subFirstName">
            </div>
            <div class="grid">
              <label for="">Last(Family) Name</label>
              <input type="text" id="subLastName">
            </div>
            <div class="grid">
              <label for="">E-mail</label>
              <input type="text" id="subEmail">
            </div>
            <div class="grid">
              <label for="">Nationality</label>` + self.selector +
            `</div>
          </div>
        </div>
        `,
        focusConfirm: false,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Subscribe',
        cancelButtonText: 'Cancel',
        width: '550px',
        padding: '20px 12px 30px',
        customClass: {
          confirmButton: 'popup__btn ',
          cancelButton: 'popup__btn popup__btn--cancel'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const subscriber = {
            first: document.getElementById('subFirstName').value,
            last: document.getElementById('subLastName').value,
            email: document.getElementById('subEmail').value,
            nation: document.getElementById('subNation').value
          }
          if (subscriber.first !== '' && subscriber.last !== '' && subscriber.nation !== '') {
            if (this.$checkEmail(subscriber.email)) {
              const data = JSON.stringify({ first_name: subscriber.first, last_name: subscriber.last, email: subscriber.email, nationality_code: subscriber.nation, is_subscribe: 1 })
              this.$axios.post(this.$apiUrl + 'subscribe/setting', data, { headers: { 'Content-Type': 'application/json' } })
                .then(() => {
                  this.$popupSuccess('You have successfully subscribed to INS taiwan 2023!')
                })
                .catch(() => {
                  this.$popupError('Please try again.')
                })
            } else {
              this.$popupError('Please fill in all column correctly.')
            }
          }
        }
      })
    },
    dropdown (type) {
      const dropdown = document.getElementById('dropdown-' + type)
      if (dropdown.classList.contains('hamburgur__dropdown--active')) {
        dropdown.classList.remove('hamburgur__dropdown--active')
      } else {
        dropdown.classList.add('hamburgur__dropdown--active')
      }
    },
    scrolltoBottom () {
      if (!this.sidebar) {
        document.getElementById('sidebar').style.right = '0'
        document.getElementById('hamburgur').classList.add('close')
        this.sidebar = true
      }
      window.scrollTo(0, document.body.scrollHeight)
    }
  },
  created () {
    localStorage.setItem('memberId', 0)
  },
  mounted () {
    this.comingSoon()
    if (localStorage.getItem('accessToken') !== null) {
      this.is_Login = true
    }

    if (this.$countryList.length === 0) {
      this.$axios.get(this.$apiUrl + 'subscribe/countryList').then((res) => {
        this.$countryList = res.data.data
        this.$countryList.forEach(el => {
          // eslint-disable-next-line
          var temp = '<option value="' + el.code + '">' + el.name + '</option>'
          this.selector += temp
        })
      })
    } else {
      this.$countryList.forEach(el => {
        // eslint-disable-next-line
        var temp = '<option value="' + el.code + '">' + el.name + '</option>'
        this.selector += temp
      })
    }
  }
}
</script>
