<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">Continuing Education Courses</div>
        <!-- <ul class="aboutInfo__courses">
          <li class="aboutInfo__course" v-for="(s, index) in speakers" :key="index">
              <img :src="'https://storage.googleapis.com/zuvio-public/public/system/images/ins/' + s.pic" alt="">
              <div class="holder">
                <div class="order">Course {{s.order}}</div>
                <div class="name">{{s.name}}</div>
                <div class="titles">{{s.title}}</div>
                <div class="time">{{s.time}}</div>
                <ul class="experience">
                  <li v-for="(e, eIndex) in s.experience" :key="eIndex">
                    {{e}}
                  </li>
                </ul>
              </div>
          </li>
        </ul> -->
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/CE_A.jpg" alt="">
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/CE_B.jpg" alt="">
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/CE_C.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseComp',
  data () {
    return {
      speakers: [{
        order: 'A',
        pic: 'MFKuo.png',
        name: 'Min-Fang Kuo, Ph.D',
        title: 'The principles of transcranial direct current stimulation (tDCS) and apply tDCS protocols for clinical treatment',
        time: 'July 6th 10:00am-12:00pm',
        experience: [
          'Department of Psychology and Neurosciences, Leibniz Research Centre for Working Environment and Human Factors, Germany'
        ]
      }, {
        order: 'B',
        pic: 'Nara.jpg',
        name: 'Nara Andrade, Ph.D, Professor',
        title: 'Social Cognition in Pediatric Neuropsychology',
        time: 'July 6th 10:00am-12:00pm',
        experience: [
          'Professor, Psychology Department, Juiz de Fora Federal University, Brazil'
        ]

      }, {
        order: 'D',
        pic: 'lamar.jpg',
        name: 'Melissa Lamar, Ph.D, Professor',
        title: 'Exploring the Past, Present, and Future of Cerebrovascular Disease & Cognitive Aging',
        time: 'July 7th 1:30pm-3:00pm',
        experience: [
          'Professor, Department of Psychiatry and Behavioral Sciences and the Rush Alzheimer’s Disease Center, Chicago, Illinois, US',
          'Rush University Medical Center, Chicago, Illinois, US'
        ]
      }]
    }
  },
  methods: {
  }
}

</script>
