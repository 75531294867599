<template>
  <div class="page-holder">
    <div class="page-content news aboutInfo">
        <div class="title">Latest News</div>
        <div class="news__title">{{target.title}}</div>
        <div class="news__date">{{target.date}}</div>
        <hr>
        <template>
            <div v-if="target.id == '1'" class="news__content">
                On behalf of the Organizing Committee of the INS 2023 Taiwan Meeting, it is our great honor and pleasure to invite you all to join the exciting science program at the congress, while tasting native Taiwanese delicacies and experiencing life in the city!<br><br>
                We sincerely invite you to share this exciting information by distributing this wonderful news to all your members, thank you!<br><br>
                To find more information for Abstract Submission, please visit our website page:<br><br>
                <a href="https://www.instaiwan2023.org/#/login">https://www.instaiwan2023.org/#/login</a>
            </div>
            <div v-else-if="target.id == '2'" class="news__content">
              The Taiwan meeting announced that the submission deadline has been extended to March 24th, 2023.
            </div>
            <div v-else-if="target.id == '3'" class="news__content">
              According to recent feedback from our electronic payment institution, the main difficulty with failed payments is that the bank may refuse to allow the registrant to use your card for international trade. Please contact your bank to verify this problem.
              <div class="warning">
              If you register before May 31th, you can still take advantage of the early bird discount after that date.
              </div>
            </div>
            <div v-else-if="target.id == '4'" class="news__content">
              Dear attendances,<br>
              Deadline for eCode for VISA is Jun 1 2023, please contact our office for more information.<br>
              Email: ins2023tw@gmail.com
            </div>
            <div v-else-if="target.id == '5'" class="news__content">
              INS 2023 Taiwan meeting<br>
              國際神經心理學會年中會-台灣會議<br>
              時間：112年7月6日 至 112年7月8日<br><br>
              <table>
                <thead>
                    <!-- <tr>
                        <th colspan="3">繼續教育積分/學分公告</th>
                    </tr> -->
                    <tr>
                        <th>單位<br>(依筆畫排序)</th>
                        <th>積分認定</th>
                        <th>備註</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>台灣兒童青少年精神醫學會</td>
                        <td>每天0.5單位，合計1.5單位</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>台灣神經學學會</td>
                        <td>3天，17.5分</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>台灣精神醫學會</td>
                        <td>3天，8學分</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>台灣復健醫學會</td>
                        <td>第一天1.5點、第二天0.5點、第三天1點</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>臨床心理師</td>
                        <td>每小時積分一點<br>(視實際簽到狀況)</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>職能治療師</td>
                        <td>每小時積分一點<br>(視實際簽到狀況)</td>
                        <td></td>
                    </tr>
                </tbody>
              </table>
            </div>
            <div v-else-if="target.id == '6'" class="news__content">
              Air Bus 1960 offers convenient transit service between Taiwan Taoyuan International Airport terminals and Grand Hyatt Taipei daily.<br>
              <ul>
                <li>Hours: To Airport 4:40 AM - 11:00 PM; From Airport 6:05 AM - 1:05 AM</li>
                <li>Duration: 60-70 min</li>
                <li>Stop Location: Grand Hyatt Taipei (Drop off on SongShou Road; Pickup on Shifu Road outside the East Entrance)</li>
                <li>Fare: Adult - NT$145 / Concession - NT$70</li>
                <li>Taoyuan Airport Terminal 1 bus pickup point: Bus pickup zone at B1 Arrivals.</li>
                <li>Taoyuan Airport Terminal 2 bus pickup point: Bus pickup zone at the east arcade of the 1st floor Arrivals lobby.</li>
              </ul>
            </div>
            <div v-else-if="target.id == '7'" class="news__content">
              The photos are stored in the cloud, and you can view them by logging in and accessing the <a href="/#/albums">link</a>
            </div>
        </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsComp',
  data () {
    return {
      id: '',
      sheet: [],
      news: [{
        id: '1',
        title: 'INS 2023 Taiwan Meeting Online Submission is now open!',
        date: '2022-12-01'
      }, {
        id: '2',
        title: 'The Taiwan meeting announced that the submission deadline has been extended to March 24th, 2023.',
        date: '2023-03-14'
      }, {
        id: '3',
        title: 'The issue of failed payments',
        date: '2023-05-25'
      }, {
        id: '4',
        title: 'Deadline for eCode for VISA  (Given country)',
        date: '2023-05-30'
      }, {
        id: '5',
        title: '繼續教育積分學分申請公告',
        date: '2023-06-30'
      }, {
        id: '6',
        title: 'Traffic information',
        date: '2023-06-30'
      }, {
        id: '7',
        title: 'Photographs about INS 2023 Taiwan meeting',
        date: '2023-08-25'
      }],
      target: {}
    }
  },
  methods: {
  },
  mounted () {
    this.id = this.$route.params.news_id
    this.news.forEach(element => {
      this.sheet.push(element.id)
    })
    if (this.sheet.includes(this.id)) {
      this.target = this.news.find(obj => {
        // eslint-disable-next-line
        return obj.id == this.id
      })
    } else {
      location.href = '/'
    }
  }
}
</script>
