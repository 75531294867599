<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">Keynote Speaker</div>
        <ul class="aboutInfo__courses">
          <li class="aboutInfo__course" v-for="(s, index) in speakers" :key="index">
              <img :src="'https://storage.googleapis.com/zuvio-public/public/system/images/ins/' + s.pic" alt="">
              <div class="holder">
                <div class="name">{{s.name}}</div>
                <div class="titles">Keynote Speech: {{index+1}} {{s.theme}}</div>
                <ul class="experience" style="margin-top:4px;">
                  <li v-for="(e, eIndex) in s.experience" :key="eIndex">
                    {{e}}
                  </li>
                </ul>
              </div>
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpeakersComp',
  data () {
    return {
      speakers: [
        {
          name: 'Dr. Jonathan Evans, Ph.D, Professor',
          pic: 'jon.jpg',
          theme: 'Grand Challenges for Global Neuropsychology',
          experience: [
            'Professor of Clinical Neuropsychology, University of Glasgow, UK',
            'Honorary Consultant Clinical Psychologist, NHS Greater Glasgow & Clyde, Scotland, UK',
            'President of the International Neuropsychological Society'
          ]
        }, {
          name: 'Dr. Masaru Mimura, Ph.D, Professor',
          pic: 'mimura.jpg',
          theme: 'Early Detection of MCI and Dementia',
          experience: [
            'Professor and Chairperson of Department of Neuropsychiatry, Keio University School of Medicine, Tokyo, Japan'
          ]
        }, {
          name: 'Dr. Akira Midorikawa, Ph.D, Professor',
          pic: 'midorikawa.jpg',
          theme: 'Paradoxical functional facilitation in various brain injuries and diseases',
          experience: [
            'Professor, Chuo University, Tokyo, Japan',
            'Clinical Neuropsychologist at Department of neurosurgery, Tokyo Metropolitan Komagome Hospital, Tokyo, Japan',
            'Clinical Neuropsychologist at Yokohama Rehabilitation Center, Yokohama, Japan'
          ]
        }, {
          name: 'Dr. Michael Chee Wei Liang, Ph.D, Professor',
          pic: 'mike.jpg',
          theme: 'Napping: A Contemporary Review of an Age Old Practice',
          experience: [
            'Professor, National University of Singapore, Singapore'
          ]
        }, {
          name: 'Dr. Rumi Tanemura, Ph.D, Professor',
          pic: 'rumi.jpg',
          theme: 'Friendly assistive technology for patients with cognitive impairments',
          experience: [
            'Faculty of Rehabilitation, Kansai Medical University, Japan'
          ]
        }, {
          name: 'Dr. Vicki Anderson, Ph.D, FAPS, FASSA, FAAHMS, FASSBI',
          pic: 'vicki.jpg',
          theme: 'Clinical Research in Child Brain Injury: Generating Knowledge, Implementing Evidence, Improving Outcomes',
          experience: [
            'Director, Psychology, Royal Children’s Hospital, Australia',
            'Director, Clinical Sciences Research, Murdoch Children’s Research Institute, Melbourne, Australia'
          ]
        }]
    }
  }
}
</script>
