<template>
  <div class="page-holder">
    <div class="page-content accommodation">
        <div class="title">Accommodation</div>
        <div class="accommodation__content">
            To assist the INS 2023 Taiwan Meeting delegates find an accommodation in Taipei which is nearby The International Convention Center, we have cooperated with hotels to offer special rates just for our Conference participants.<br><br>
            Please kindly note that the limitation of the available room is on a first come first served basis. In order to secure hotel accommodations, it is highly recommended that participants should reserve their room as early as possible.
        </div>
        <div class="accommodation__grid">
            <div class="head">No.</div>
            <div class="head">Hotel</div>
            <div class="head">Distance from<br>Conference<br>Venue</div>
            <div class="head">Class</div>
            <div class="head price-container">
                <div class="head">Room type</div>
                <div class="head">Price/Night</div>
            </div>
            <div class="head">Reservation</div>
            <template v-for="(h, index) in hotel">
                <div :key="h.name + '_id'">{{index+1}}</div>
                <a :key="h.name + '_name'" :href="h.website" class="hotellink">{{h.name}}</a>
                <div :key="h.name + '_dis'">{{h.distance}}</div>
                <div :key="h.name + '_star'"><span v-for="i in h.star" :key="h.name + '_star' + i">&#11088;</span></div>
                <div :key="h.name + '_room'" style="display:flex; flex-direction:column; padding:0; align-items:stretch;">
                <template>
                    <div class="price-container" v-for="(r, index) in h.room" :key="r">
                        <div :key="r+index">{{r}}</div>
                        <div :key="r+index+'price'">NT$ {{h.price[index]}}</div>
                    </div>
                    </template>
                </div>
                <div :key="h.name + '_link'" class="column">
                    <a class="accommodation__book" :href="h.link" target="_blank">Reserve Now</a>
                    <div style="font-size:10px; line-height:1; margin-top:10px;" v-show="index == 0">Promotion Code: G-TN07<br>Please input "G-TN07" under "Corporate or Group Code" after selecting arrival and departure date.</div>
                    <div style="font-size:10px; line-height:1; margin-top:10px;" v-show="index == 3">Included Breakfast.</div>
                </div>
            </template>
        </div>
        <iframe src="https://www.google.com/maps/d/embed?mid=1AlFHTfXA95FBE2Y3WCmqJ8nw9CGEiT8&ehbc=2E312F" width="710"  height="330" style="border:0; max-width:100vw; margin-top:12px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AccommodationComp',
  data () {
    return {
      hotel: [{
        name: 'Grand Hyatt Taipei',
        website: 'https://www.hyatt.com/en-US/hotel/taiwan/grand-hyatt-taipei/taigh/offers?src=adm_sem_agn_pfx_corp_apac_other_PFX_Search_Google_ASPAC_ASPAC_TAIGH_BR_AO_TW_EN_TAIGH_BR_Exact_Brand_TW_EN_Grand+Hyatt+Taipei&gclid=CjwKCAiAqaWdBhAvEiwAGAQltg7kyTA5BdAIyBuoAs-Mu10G-VYFSn89LiSUTHOj87RsZsURbmuRSxoCTTUQAvD_BwE&gclsrc=aw.ds',
        distance: 'Conference Official Hotel',
        star: 5,
        room: ['1 King Bed Deluxe', '2 Twin Beds Deluxe'],
        price: ['7,854', '8,663'],
        link: 'https://www.hyatt.com/zh-HK/hotel/taiwan/grand-hyatt-taipei/taigh'
      }, {
        name: 'Pacific Business Hotel',
        website: 'http://www.businesscenter.com.tw/en/',
        distance: '350m',
        star: 4,
        room: ['Boutique Double Room', 'Business Twin Room'],
        price: ['3,520', '4,180'],
        link: 'https://reurl.cc/VRd2RZ'
      }, {
        name: 'Home Hotel Xinyi',
        website: 'https://homehotel.com.tw/en-xinyi/',
        distance: '1km',
        star: 4,
        room: ['Original Bedroom King/Twin', 'Pure Original Bedroom King/Twin', 'Marvelous Suite King/Twin'],
        price: ['4,700', '5,300', '6,200'],
        link: 'https://reurl.cc/NGmz3Q'
      }, {
        name: 'The Howard Plaza Hotel Taipei',
        website: 'https://www.howard-hotels.com.tw/en_US/HotelBusiness/91',
        distance: '2.2 km',
        star: 5,
        room: ['Superior King', 'Superior Twin', 'Deluxe Twin'],
        price: ['4,000', '4,500', '6,500'],
        link: '../../../reservation-howard.pdf'
      }, {
        name: 'Cosmos Hotel Taipei',
        website: 'https://www.cosmos-hotel.com.tw/',
        distance: '5.4 km',
        star: 4,
        room: ['Superior Room'],
        price: ['3,500 - 4,200'],
        link: '../../../reservation-5.pdf'
      }, {
        name: 'Hua Shan Din by Cosmos Design',
        website: 'https://www.huashandin.com.tw/',
        distance: '4.2 km',
        star: 2,
        room: ['Superior Room'],
        price: ['3,500 - 4,000'],
        link: '../../../reservation-6.pdf'
      }, {
        name: 'Taipei Garden Hotel',
        website: 'https://www.taipeigarden.com.tw/',
        distance: '6.7 km',
        star: 5,
        room: ['Premier Room'],
        price: ['4,400 - 5,200'],
        link: '../../../reservation-7.pdf'
      }]
    }
  }
}
</script>
