<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">Venue</div>
        <div class="aboutInfo__content">Taipei International Convention Center<br>No. 1, Sec. 5, Xinyi Rd., Xinyi Dist., Taipei City, Taiwan (R.O.C.)</div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.018690037229!2d121.55841961499272!3d25.03343978397259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abb61ab3d33f%3A0xab0d0f7ac395bff4!2z5Y-w5YyX5ZyL6Zqb5pyD6K2w5Lit5b-DKFRJQ0Mp!5e0!3m2!1szh-TW!2stw!4v1656405221338!5m2!1szh-TW!2stw" width="698"  height="300" style="border:0; max-width:100vw; margin: 16px 0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="aboutInfo__content">
          <div class="bold">Taipei International Convention Center</div>
          <div class="wrapper" style="gap:20px; align-items: flex-start; margin-top:8px;">
            <div class="aboutInfo__content">The Taipei International Convention Center (TICC) has been operated by TAITRA since 1992. With considerable event management experience and a deep commitment to integrating Taiwan's MICE industry resources, the TICC has become Taiwan's top venue provider. Every year, the TICC hosts an average of 850 major events, such as world congresses, forums, concerts, and product launches. Purpose-built to meet organizer requirements, the TICC has everything needed to make any event a success. The best partner for events in Asia- TICC.</div>
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/about-venue.png" alt="" style="width: 50%; height: 50%;">
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutVenueComp'
}
</script>
