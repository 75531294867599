<template>
  <div class="page-holder">
    <div class="page-content trip summit">
        <div class="title" style="margin-bottom:20px;">Extension Tour</div>
        <div  style="margin-bottom:12px;">Post-conference Tours: Chiayi Alishan Forest Railway and Tsou Cultural Tour (3 days, 3 nights)</div>
        <template v-for="(day, index) in tour">
            <div :key="day.date" class="trip__content">
                <div class="trip__date">Day {{index}}. {{day.date}}</div>
                <div class="trip__schedule">
                    <div class="head">Date</div>
                    <div class="head">Contents</div>
                    <div class="head">Remark</div>
                    <template v-for="(detail, d_index) in day.schedule">
                        <div :key="d_index + '-time'">{{detail.time}}</div>
                        <div :key="d_index + '-content'">{{detail.contents}}</div>
                        <div :key="d_index + '-remark'" v-if="!detail.link">{{detail.remark}}</div>
                        <div :key="d_index + '-remark'" v-else-if="detail.remark =='Land of Legends-Alishan and Tsou Tribe Culure'" style="flex-direction: column;">
                            <a :href="detail.link">{{detail.remark}}</a><br>
                            <a href="https://www.ali-nsa.net/en/attractions/detail/303">Dabang village and Kuba</a>
                        </div>
                        <div :key="d_index + '-remark'" v-else-if="detail.remark =='Shuishan Trail'" style="flex-direction: column;">
                            <a :href="detail.link">{{detail.remark}}</a><br>
                            <a href="https://recreation.forest.gov.tw/en/Forest/RA?typ_id=0500001">Alishan Forest Recreation Area</a><br>
                            <a href="https://www.ali-nsa.net/en/attractions/detail/301">Giant Tree Plank Trail</a>
                        </div>
                        <a :key="d_index + '-link'" target="_blank" :href="detail.link" v-else>{{detail.remark}}</a>
                    </template>
                </div>
            </div>
        </template>
        <div class="trip__notice">
            <div class="subtitle">【Tour Cost】</div>
            <div>
                For 1 person: NTD$28975 (US$950) on 2 PEOPLE SHARING ROOM (Roommates will be assigned by the host)<br>
                For 1 person: NTD$39650 (US$1300) on SIGNAL ROOM<br>
                For 2 persons: Total NTD$57950 (US$1900) on TWIN ROOM<br>
                For 3 persons: Total NTD$73200 (US$2400) on TRIPLE ROOM<br>
                For 4 persons: Total NTD$85400 (US$2800) on QUADRUPLE ROOM<br>
            </div>
        </div>
        <div class="trip__notice">
            <div class="subtitle">【Services included】</div>
            <div>
                All transportation mentioned above<br>
                Meals and suppers list above<br>
                3 nights of accommodation<br>
                All entrance tickets<br>
                English speaking guide<br>
                Local Guide<br>
                Coffee DIY<br>
                Travel insurance<br>
            </div>
        </div>
        <div class="trip__notice">
            <div class="subtitle">【Services NOT included】</div>
            <div>
                International Airfares from and back to your home.<br>
                Visa fees.<br>
                Meals and supper not mention above<br>
                Transportation not included above<br>
                Extra activities/services not included above<br>
                Gratuities to driver / guides<br>
            </div>
        </div>
        <div class="trip__notice">
            <div class="subtitle">【Number Limit】</div>
            <div>
                Because some locations have crowd control, the maximum number of the tour is 36.
                <br>There are only <span style="color:#d95244; font-weight:600;">{{rest}}</span> places available now.
            </div>
        </div>
        <div class="trip__notice warning" style="font-weight:600; padding-left:10px; font-size: 18px;" v-if="rest == 0">The number of tour hit the maximum limit. No place for tour is available.</div>
        <form v-else>
        <div class="disclosure" style="display:flex; flex-direction:column;">
          <div class="disclosure__question">If you are willing to attend the extension tour, please select your plan.</div>
          <div class="disclosure__option" v-show="rest>=1">
            <input type="radio" v-model="plan" value="0" @change="pickPlan()" required>
            <label>For 1 person: NTD$28975 (US$950) on 2 PEOPLE SHARING ROOM (Roommates will be assigned by the host)</label>
          </div>
          <div class="disclosure__option" v-show="rest>=1">
            <input type="radio" v-model="plan" value="1" @change="pickPlan()" required>
            <label>For 1 person: NTD$39650 (US$1300) on SIGNAL ROOM</label>
          </div>
          <div class="disclosure__option" v-show="rest>=2">
            <input type="radio" v-model="plan" value="2" @change="pickPlan()" required>
            <label>For 2 persons: Total NTD$57950 (US$1900) on TWIN ROOM</label>
          </div>
          <div class="disclosure__option" v-show="rest>=3">
            <input type="radio" v-model="plan" value="3" @change="pickPlan()" required>
            <label>For 3 persons: Total NTD$73200 (US$2400) on TRIPLE ROOM</label>
          </div>
          <div class="disclosure__option" v-show="rest>=4">
            <input type="radio" v-model="plan" value="4" @change="pickPlan()" required>
            <label>For 4 persons: Total NTD$85400 (US$2800) on QUADRUPLE ROOM</label>
          </div>

          <div class="disclosure__addition" v-show="plan >= 0 && rest>0">
            <div class="note">Please fill in all participants of the tour.</div>
                <div class="inputwrap" v-show="plan == 0">
                    <label>Full Name</label><input type="text" v-model="info[0].name">
                    <label>Gender</label>
                    <div>
                        <input type="radio"  v-model="info[0].gender" value="male"><label style="margin-right:16px;">male</label>
                        <input type="radio"  v-model="info[0].gender" value="female"><label>female</label>
                    </div>
                    <label>Passport No.</label><input type="text" v-model="info[0].passport">
                    <div style="grid-column: span 2; font-weight:600; text-align:right;">*for travel insurance</div>
                    <label>Birth Date</label><input type="date"  v-model="info[0].birth">
                    <label>Meal</label>
                    <div>
                        <input type="radio"  v-model="info[0].dietary" value="normal"><label>Ordinary</label>
                        <div>
                            <input type="radio" v-model="info[0].dietary" value="other" id="restrict0"><label style="margin-right:8px;">Dietary Restrictions</label>
                            <input type="text" v-model="dietary[0]" :disabled="info[0].dietary !== 'other'">
                        </div>
                    </div>
                    <label>Nationality</label><input type="text"  v-model="info[0].nationality">
                </div>
                <div class="inputwrap" v-show="plan != 0" v-for="index in Number(plan)" :key="index+'tourInfo'" style="padding: 16px 6px; border-bottom: 1px dashed rgb(96, 25, 133);">
                    <div style="grid-column: span 2; font-weight:600; ">{{index}}th person for tour</div>
                    <label>Full Name</label><input type="text" v-model="info[index-1].name">
                    <label>Gender</label>
                    <div>
                        <input type="radio"  v-model="info[index-1].gender" value="male"><label style="margin-right:16px;">male</label>
                        <input type="radio"  v-model="info[index-1].gender" value="female"><label>female</label>
                    </div>
                    <label>Passport No.</label><input type="text" v-model="info[index-1].passport">
                    <div style="grid-column: span 2; font-weight:600; text-align:right;">*for travel insurance</div>
                    <label>Birth Date</label><input type="date"  v-model="info[index-1].birth">
                    <label>Meal</label>
                    <div>
                        <input type="radio"  v-model="info[index-1].dietary" value="normal"><label>Ordinary</label>
                        <div>
                            <input type="radio"  v-model="info[index-1].dietary" value="other"><label style="margin-right:8px;">Dietary Restrictions</label>
                            <input type="text" v-model="dietary[index-1]" :disabled="info[index-1].dietary !== 'other'">
                        </div>
                        </div>
                    <label>Nationality</label><input type="text"  v-model="info[index-1].nationality">
                </div>
          </div>

        <div class="disclosure__list" v-show="rest > 0">
          <div class="head item">Product Name</div>
          <div class="head">Unit Price</div>
          <div class="head">Amount</div>
          <div class="head subtotal">Item Subtotal</div>
          <div class="item">Tour Fee - {{feeText()}}</div>
          <div>${{total}}</div>
          <div>1</div>
          <div class="subtotal">${{total}}</div>
        </div>
        <div class="disclosure__payment">
          <div class="label">Total Payment:&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div class="price">NTD${{total}}</div>
        </div>
        <div class="disclosure__btns" style="justify-content:flex-end;" v-show="rest > 0">
          <input type="button" class="nextBtn" value="Place Order" @click="submitTour()">
        </div>
        </div>
        </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TripComp',
  data () {
    return {
      total: 28975,
      plan: 0,
      tour: [{
        date: '9th July, Sunday',
        schedule: [{
          time: '16:00-20:00',
          contents: 'Head to Chiayi',
          remark: 'Tour Bus'
        }, {
          time: '20:00-21:00',
          contents: 'Dinner (Savor Taiwanese specialties)'
        }, {
          time: '21:00-',
          contents: 'Stay overnight in Chiayi (YUH TONG HOTEL)'
        }]
      }, {
        date: '10th July, Monday',
        schedule: [{
          time: '08:30-08:50',
          contents: 'Head to Chiayi Station',
          remark: 'Tour Bus'
        }, {
          time: '08:50-09:36',
          contents: 'Chiayi Station→Zhangnaoliao Station (Take the century-old Alishan Forest Railway and witness the railway engineering methods that enables the tracks to spiral up the mountains)',
          remark: 'Alishan Forest Railway',
          link: 'https://afrch.forest.gov.tw/EN/0000104'
        }, {
          time: '09:36-10:34',
          contents: 'Zhangnaoliao Station (where we get off to check out the spectacular three-spirals up the mountain)',
          remark: ''
        }, {
          time: '10:34-12:00',
          contents: 'Zhangnaoliao Station →Fenqihu Station',
          remark: ''
        }, {
          time: '12:00-13:00',
          contents: 'Lunch at Fenqihu',
          remark: ''
        }, {
          time: '13:00-14:00',
          contents: 'Stroll around Fenqihu Old Street',
          remark: 'Fengqihu',
          link: 'https://www.ali-nsa.net/en/attractions/detail/519'
        }, {
          time: '14:00-15:30',
          contents: 'Fenqihu→ Alishan Forest Recreation Area',
          remark: 'Tour Bus'
        }, {
          time: '15:30~',
          contents: 'Alishan Hotel (4 star, most historic hotel) (Check-in, dinner and stay overnight)',
          remark: 'Alishan Hotel',
          link: 'http://en.alishanhotel.com.tw/about.php'
        }]
      }, {
        date: '11th July, Tuesday',
        schedule: [{
          time: '05:00-08:00',
          contents: '04:30 Wake up→05:00 Take Alishan shuttle bus→Zhushan Sunrise Observation Point (Enjoy the sunrise and the fantastic view of the cloud sea) → Xiaoliyuan Lookout (360-degree panoramic viewing platform, Eye-feast on the three major mountain ranges, Central Mountains, Yushan Mountains, and Alishan Mountains) → Tea tasting → Return to hotel for breakfast',
          remark: 'Alishan Sunrise',
          link: 'https://www.lonelyplanet.com/articles/sunrise-alishan-railway-taiwan '
        }, {
          time: '08:30-10:00',
          contents: 'Breakfast at Hotel'
        }, {
          time: '10:30-15:30',
          contents: 'Hotel→Shuishan Giant Tree Trail (A 1.5 km trail converted from the old forest railway, where you can visit the millennia-old Shuishan Red Cypress) → Giant Trees Plank Trail (A 1.1 km trail, where you come across three wooden trestle bridges and over 30 red cypress giant trees)',
          remark: 'Shuishan Trail',
          link: 'https://recreation.forest.gov.tw/en/Trail/RT?tr_id=200'
        }, {
          time: '15:30-16:30',
          contents: 'Take the Forest Railway from Shenmu Station to Alishan Station'
        }, {
          time: '16:30~',
          contents: 'Alishan Hotel (4 star, most historic hotel) (Dinner and stay overnight)'
        }]
      }, {
        date: '12th July, Wednesday',
        schedule: [{
          time: '08:00-09:30',
          contents: 'Breakfast at hotel'
        }, {
          time: '09:30-11:00',
          contents: 'Head to Dabang Village ',
          remark: 'Tour Bus'
        }, {
          time: '11:00-12:00',
          contents: 'Introduction to Tsou culture(Visit Kuba, a significant venue for ritual practices of the Tsou, and find out more about the Tsou’s history and culture at the Tsou Culture Center)',
          remark: 'Land of Legends-Alishan and Tsou Tribe Culure',
          link: 'https://youtu.be/dBBMDoEk0aI'
        }, {
          time: '12:00-13:00',
          contents: 'Tsou-style lunch'
        }, {
          time: '13:00-13:30',
          contents: 'Restaurant→Leyeh Village',
          remark: 'Tour Bus'
        }, {
          time: '13:30-15:30',
          contents: 'Leyeh Village Coffee DIY (Dipping your hand in the Coffee DIY workshop, where we learn to make a coffee like no other, with the most traditional method, all with our own hands)'
        }, {
          time: '15:30-17:00',
          contents: 'Head to Chiayi High-Speed Rail Station (where the journey leaves off)',
          remark: 'Tour Bus Arrvie at Chiayi THSR at about 17:00~17:30 (depend on the traffic)'
        }]
      }],
      info: [
        {
          name: '',
          gender: '',
          birth: '',
          passport: '',
          dietary: '',
          nationality: ''
        }, {
          name: '',
          gender: '',
          birth: '',
          passport: '',
          dietary: '',
          nationality: ''
        }, {
          name: '',
          gender: '',
          birth: '',
          passport: '',
          dietary: '',
          nationality: ''
        }, {
          name: '',
          gender: '',
          birth: '',
          passport: '',
          dietary: '',
          nationality: ''
        }
      ],
      dietary: ['', '', '', ''],
      rest: 0
    }
  },
  methods: {
    feeText () {
      let text = ''
      switch (this.plan) {
        case '0':
          text = 'For 1 person: 2 PEOPLE SHARING ROOM'
          break
        case '1':
          text = 'For 1 person: SIGNAL ROOM'
          break
        case '2':
          text = 'For 2 persons: TWIN ROOM'
          break
        case '3':
          text = 'For 3 persons: TRIPLE ROOM'
          break
        case '4':
          text = 'For 4 persons: QUADRUPLE ROOM'
          break
      }
      return text
    },
    pickPlan () {
      const self = this
      switch (this.plan) {
        case '0':
          self.total = 28975
          break
        case '1':
          self.total = 39650
          break
        case '2':
          self.total = 57950
          break
        case '3':
          self.total = 73200
          break
        case '4':
          self.total = 85400
          break
      }
    },
    submitTour () {
      const text = this.checkForm()
      if (text !== '') {
        this.$popupError(text)
      } else {
        const self = this
        const api = 'orders/pay'
        const bodyFormData = new FormData()
        bodyFormData.set('amount', self.total)
        bodyFormData.set('type', 'project_et')
        bodyFormData.set('data_json', JSON.stringify(self.info))
        this.$axios.post(self.$apiUrl + '/orders/addorder', bodyFormData, {
          headers: { 'Content-Type': 'form-data', 'member-token': localStorage.getItem('accessToken') }
        }).then((res) => {
          if (res.data.resultCode === '000') {
            const paymentUrl = self.$apiUrl + api + '?membertoken=' + localStorage.getItem('accessToken') + '&amt=' + self.total + '&orderno=' + res.data.orderno
            window.open(paymentUrl, '_blank')
            location.href = '/#/registration/receipt'
          } else if (res.data.resultCode === '088') {
            this.$popupError('There are not enough place for the tour.')
            location.reload()
          } else {
            this.$popupError()
            location.reload()
          }
        })
      }
    },
    checkForm () {
      let text = ''
      if (this.plan === '0' || this.plan === 0) {
        if (this.info[0].name === '') {
          text = 'Please fill in your name.'
        } else if (this.info[0].gender === '') {
          text = 'Please choose your gender.'
        } else if (this.info[0].dietary === '') {
          text = 'Please choose your dietary.'
        } else if (this.info[0].passport === '') {
          text = 'Please fill in your passport number.'
        } else if (this.info[0].birth === '') {
          text = 'Please fill in your birth.'
        } else if (this.info[0].nationality === '') {
          text = 'Please fill in your nationality.'
        }
        return text
      } else if (['1', '2', '3', '4'].includes(this.plan) || [1, 2, 3, 4].includes(this.plan)) {
        for (let i = 0; i < Number(this.plan); i++) {
          if (this.info[i].name === '') {
            text = 'Please fill in your name of ' + String(i + 1) + 'th person.'
          } else if (this.info[i].gender === '') {
            text = 'Please choose your gender of ' + String(i + 1) + 'th person.'
          } else if (this.info[i].dietary === '') {
            text = 'Please choose your dietary of ' + String(i + 1) + 'th person.'
          } else if (this.info[i].passport === '') {
            text = 'Please fill in your passport number of ' + String(i + 1) + 'th person.'
          } else if (this.info[i].birth === '') {
            text = 'Please select your birth date of ' + String(i + 1) + 'th person.'
          } else if (this.info[i].nationality === '') {
            text = 'Please fill in your nationality of ' + String(i + 1) + 'th person.'
          }
        }
        if (this.plan === '0' || this.plan === 0) {
          if (this.info[0].dietary === 'other') {
            if (this.dietary[0] !== '') {
              this.info[0].dietary = this.dietary[0]
            } else {
              text = 'Please fill in your dietary.'
            }
          }
        }
        if (['1', '2', '3', '4'].includes(this.plan) || [1, 2, 3, 4].includes(this.plan)) {
          for (let i = 0; i < Number(this.plan); i++) {
            if (this.info[i].dietary === 'other') {
              if (this.dietary[i] !== '') {
                this.info[i].dietary = this.dietary[i]
              } else {
                text = 'Please fill in your dietary of ' + String(i + 1) + 'th person.'
              }
            }
          }
        }
        return text
      }
    }
  },
  mounted () {
    const self = this
    if (localStorage.getItem('accessToken') !== null) {
      this.$axios.get(this.$apiUrl + 'members/info', {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      })
        .then((res) => {
          if (res.data.resultCode === '006') {
            this.$tokenError()
          }
          self.rest = 36 - res.data.data.trip_num
        })
    } else {
      location.href = '/#/login'
    }
  }
}
</script>
