<template>
  <div class="wrapper section foot">
    <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-logo-big-02.png" alt="" class="foot__item pic pic--ins">
    <div class="foot__item foot__item--address">
      INS 2023 Taiwan Meeting Secretariat<br>
      Add: Institute of Behavioral Medicine, National Cheng Kung University, No. 1,
      Daxue Rd., East Dist., Tainan City 701 , Taiwan (R.O.C.)
    </div>
    <div class="foot__item foot__item--contact">
      <!-- Tel: +886-2-2798-8329 #51<br>
      Fax: +886-2-2798-6225<br> -->
      Email: taiwanndmr105@gmail.com / ins2023tw@gmail.com
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterComp'
}
</script>
