<template>
  <div class="page-holder">
    <div class="page-content summit">
        <div class="title" style="margin-bottom:16px;">Online Registration</div>
        <form style="display: flex; flex-direction: column;">
        <div class="disclosure">
          <div style="display:flex; justyfy-content:space-between; margin-bottom:12px; border: 1px dashed purple; padding:12px;">
            If you have ever placed the orders, you can check or proceed your orders at the receipt page.
            <input type="button" class="nextBtn" value="Check Order" @click="goReceipt()">
          </div>
          <div class="disclosure__question">
            Based on World Bank income level country listings (1 July 2022). For the middle/low-income category, World Bank low, lower middle and upper middle - Income lists apply. All other World Bank income listings fall under low/middle income category for membership and congress registration fees.<br><br>
            Before beginning your registration, please review the low, lower middle list and select one of the options below.
            <div class="warning" style="font-size:12px;margin-top:8px; text-decoration:underline; font-weight:400;">Please note that: If you choose to register with the payment amount of a middle/low-income country, please make sure that your passport nationality is a middle/low-income country.  Please provide passport proof when registering for the conference.</div>
          </div>
          <div class="disclosure__text" style="display:flex; gap:16px;">
            <div style="text-decoration:underline; color:#601985; cursor:pointer;" @click="popupCountry('m')">Middle Income Countries List</div>
            <div style="text-decoration:underline; color:#601985; cursor:pointer;" @click="popupCountry('l')">Low Income Countries List</div>
          </div>
          <div class="disclosure__option">
            <input type="radio" v-model="country" @change="changeFee();" value="1" required>
            <label>Middle Income Countries</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" v-model="country" @change="changeFee();" value="2" required>
            <label>Low Income Countries</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" v-model="country" @change="changeFee();" value="0" required>
            <label>None of Both</label>
          </div>
          <div class="disclosure__question">1. Registration Fee<br>
          <!-- (Early Bird registration: Now-May 31; Regular registration: June 1-June 17) -->
          </div>
          <div class="disclosure__text">
            <div style="font-size:14px; ">
            INS = The International Neuropsychological Society<br>
            TANDMR = Taiwan Association of Neuropsychological Development and Mental Rehabilitation
            </div>
          </div>
          <!-- <div class="disclosure__option">
            <input type="radio" v-model="answer.identity" @change="changeFee()" value="member-early" required>
            <label>Members Early Bird: NT${{twFee[country][0]}} (≈US${{usFee[country][0]}})</label>
          </div> -->
          <div class="disclosure__option">
            <input type="radio" v-model="answer.identity" @change="changeFee()" value="member-regular" required>
            <label>Members Regular: NT${{twFee[country][1]}} (≈US${{usFee[country][1]}})</label>
          </div>
          <!-- <div class="disclosure__option">
            <input type="radio" v-model="answer.identity" @change="changeFee()" value="non-member-early" required>
            <label>Non-members Early Bird: NT${{twFee[country][2]}} (≈US${{usFee[country][2]}})</label>
          </div> -->
          <div class="disclosure__option">
            <input type="radio" v-model="answer.identity" @change="changeFee()" value="non-member-regular" required>
            <label>Non-members Regular: NT${{twFee[country][3]}} (≈US${{usFee[country][3]}})</label>
          </div>
          <!-- <div class="disclosure__option">
            <input type="radio" v-model="answer.identity" @change="changeFee()" value="student-early" required>
            <label>Student Members Early Bird: NT${{twFee[country][4]}} (≈US${{usFee[country][4]}})</label>
          </div> -->
          <div class="disclosure__option">
            <input type="radio" v-model="answer.identity" @change="changeFee()" value="student-regular" required>
            <label>Student Members Regular: NT${{twFee[country][5]}} (≈US${{usFee[country][5]}})</label>
          </div>
          <!-- <div class="disclosure__option">
            <input type="radio" v-model="answer.identity" @change="changeFee()" value="non-student-early" required>
            <label>Student Non-members Early Bird: NT${{twFee[country][6]}} (≈US${{usFee[country][6]}})</label>
          </div> -->
          <div class="disclosure__option">
            <input type="radio" v-model="answer.identity" @change="changeFee()" value="non-student-regular" required>
            <label>Student Non-members Regular: NT${{twFee[country][7]}} (≈US${{usFee[country][7]}})</label>
          </div>
          <div class="disclosure__addition" v-show="['member-early', 'member-regular', 'student-early', 'student-regular'].includes(answer.identity)">
            <div class="note">Please fill in your INS number or TANDMR number</div>
            <div class="inputwrap">
              <label>INS membership Number</label><input type="text" name="" id="" v-model="answer.INSid">
              <label>TANDMR membership Number</label><input type="text" name="" id="" v-model="answer.TANDMRid">
            </div>
          </div>
          <span class="warning" style="font-size:12px; text-decoration:underline;">Please note that: If you are not a member of INS or TANDMR, you will be unable to select the member’s item. The Conference will verify the member number.</span><br>
          <span class="warning" style="font-size:12px; text-decoration:underline;">Please nonte that: If you are not the student member of INS or TANDMR, you could not choose the student member’s item. </span>
        </div>
        <div class="disclosure">
          <div class="disclosure__question">2. Would you want to take continuing education courses? </div>
          <div class="disclosure__text">Please select the following courses. The AB and CD courses, each of which costs NT$ 2250(≈US$75)</div>
          <div style="font-size:14px; color:#601985; text-decoration:underline;margin-bottom:8px;">Please select one course for July 6th:</div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.CECourse[0]" value="0" required>
            <label>will not attend</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.CECourse[0]" value="A" required>
            <label>A. The principles of transcranial direct current stimulation (tDCS) and apply tDCS protocols for clinical treatment presented by Min-Fang Kuo</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.CECourse[0]" value="B" required>
            <label>B. Social Cognition in Pediatric Neuropsychology presented by Nara Andrade</label>
          </div>
          <div style="font-size:14px; color:#601985; text-decoration:underline;margin-bottom:8px;">Please select one course for July 7th:</div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.CECourse[1]" value="0" required>
            <label>will not attend</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.CECourse[1]" value="C" required>
            <label>C. Bias and equivalence in cross-cultural assessment presented by Alberto Luis Fernández</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.CECourse[1]" value="D" required>
            <label>D. Exploring the Past, Present, and Future of Cerebrovascular Disease & Cognitive Aging presented by Melissa Lamar</label>
          </div>
        </div>
        <div class="disclosure">
          <div class="disclosure__question">3. Will you be present for the Round Table Chinese Food Dinner(17:30-19:00 July 6th, 2023)?</div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.isDinner" value="Yes" required>
            <label>Yes (NT$1600≈US$50)</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.isDinner" value="No" required>
            <label>No</label>
          </div>
          <div class="disclosure__addition" v-show="answer.isDinner == 'Yes'">
            <div class="question">Do you have any dietary restriction?</div>
            <div class="disclosure__option">
              <input type="radio" @change="changeFee()" v-model="answer.diet" value="regular" required>
              <label>No restriction</label>
            </div>
            <div class="disclosure__option">
              <input type="radio" @change="changeFee()" v-model="answer.diet" value="vegetarian" required>
              <label>Vegetarian</label>
            </div>
          </div>
        </div>
        <div class="disclosure">
          <div class="disclosure__question">4. Will you be present for the Gala Party (Taiko drum show & Taiwanese snacks, 17:00-19:00 July 8th, 2023)?</div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.isShow" value="Yes" required>
            <label>Yes (NT$450≈US$15)</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.isShow" value="No" required>
            <label>No</label>
          </div>
        </div>
        <div class="disclosure">
          <div class="disclosure__question">5. Will you attend the Full-Day special event on July 9th (09:00-17:00)? </div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.isSpecial" value="Yes" required>
            <label>Yes (NT$2150≈US$70)</label>
          </div>
          <div class="disclosure__option">
            <input type="radio" @change="changeFee()" v-model="answer.isSpecial" value="No" required>
            <label>No</label>
          </div>
        </div>
        <div class="disclosure">
          <div class="disclosure__question">6. How many people are accompanying the registrant?</div>
          <div class="disclosure__text">each accompaniment will cost NT$1600 (≈US$50) for registration fee</div>
          <div class="disclosure__option">
            <input v-model="answer.accompany" value="0" @change="changeFee();" type="radio" required>
            <label>0</label>
          </div>
          <div class="disclosure__option">
            <input v-model="answer.accompany" value="1" @change="changeFee();" type="radio" required>
            <label>1</label>
          </div>
          <div class="disclosure__option">
            <input v-model="answer.accompany" value="2" @change="changeFee();" type="radio" required>
            <label>2</label>
          </div>
          <div class="disclosure__option">
            <input v-model="answer.accompany" value="3" @change="changeFee();" type="radio" required>
            <label>3</label>
          </div>
          <div class="disclosure__option">
            <input v-model="answer.accompany" value="4" @change="changeFee();" type="radio" required>
            <label>4</label>
          </div>
        </div>
        <div class="disclosure__addition" v-show="answer.accompany !== 0">
          <div class="question" style="font-weight:bold;">Registration fee for accompaniments</div>
          <div class="note">*Please notes that accompanying person could not take continuing education courses.</div>
          <div v-for="index in Number(answer.accompany)" class="ppl" :key="index">
            <div class="bold">{{orderText(index)}} accompaniment's infomation</div>
            <div class="question">Full Name</div>
            <input type="text" @change="changeFee()" v-model="answer.accompaniment[index-1].name" name="" id="" style="margin-bottom:12px;">
            <div class="question">Nationality</div>
            <input type="text" @change="changeFee()" v-model="answer.accompaniment[index-1].nationality" name="" id="" style="margin-bottom:12px;">
            <div class="question">Will you be present for the Round Table Chinese Food Dinner (17:30-19:00 July 6th, 2023)?</div>
            <div class="disclosure__option">
              <input type="radio" @change="changeFee()" v-model="answer.accompaniment[index-1].isDinner" value="Yes" required>
              <label>Yes (NT$1600≈US$50)</label>
            </div>
            <div class="disclosure__option">
              <input type="radio" @change="changeFee()" v-model="answer.accompaniment[index-1].isDinner" value="No" required>
              <label>No</label>
            </div>
            <div class="disclosure__addition" v-show="answer.accompaniment[index-1].isDinner == 'Yes'">
              <div class="question">Do you have any dietary restriction?</div>
              <div class="disclosure__option">
                <input type="radio" @change="changeFee()" v-model="answer.accompaniment[index-1].diet" value="regular" required>
                <label>No restriction</label>
              </div>
              <div class="disclosure__option">
                <input type="radio" @change="changeFee()" v-model="answer.accompaniment[index-1].diet" value="vegetarian" required>
                <label>Vegetarian</label>
              </div>
            </div>
            <div class="question">Will you be present for the Gala Party (Taiko drum show & Taiwanese snacks, 17:00-19:00 July 8th, 2023)?</div>
            <div class="disclosure__option">
              <input type="radio" @change="changeFee()" value="Yes" v-model="answer.accompaniment[index-1].isShow" required>
              <label>Yes (NT$450≈US$15)</label>
            </div>
            <div class="disclosure__option">
              <input type="radio" @change="changeFee()" value="No" v-model="answer.accompaniment[index-1].isShow" required>
              <label>No</label>
            </div>
            <div class="question">Will you attend the Full-Day special event on July 9th (09:00-17:00)? </div>
            <div class="disclosure__option">
              <input type="radio" @change="changeFee()" value="Yes" v-model="answer.accompaniment[index-1].isSpecial" required>
              <label>Yes (NT$2150≈US$75)</label>
            </div>
            <div class="disclosure__option">
              <input type="radio" @change="changeFee()" value="No" v-model="answer.accompaniment[index-1].isSpecial" required>
              <label>No</label>
            </div>
          </div>
        </div>
        <div class="disclosure__list">
          <div class="head item">Product Name</div>
          <div class="head">Unit Price</div>
          <div class="head">Amount</div>
          <div class="head subtotal">Item Subtotal</div>
          <div class="item">Registration Fee - {{feeText()}} {{countryText()}}</div>
          <div>${{feeUnit()}}</div>
          <div>1</div>
          <div class="subtotal">${{feeUnit()}}</div>
          <div class="item">Accompaniment fee</div>
          <div>$1600</div>
          <div>{{answer.accompany}}</div>
          <div class="subtotal">${{1600 * answer.accompany}}</div>
          <div class="item">Continuing education courses fee</div>
          <div>$2250</div>
          <div>{{amount.course}}</div>
          <div class="subtotal">${{2250 * amount.course}}</div>
          <div class="item">Dinner fee</div>
          <div>$1600</div>
          <div>{{amount.dinner}}</div>
          <div class="subtotal">${{1600 * amount.dinner}}</div>
          <div class="item">Gala Party fee</div>
          <div>$450</div>
          <div>{{amount.show}}</div>
          <div class="subtotal">${{450 * amount.show}}</div>
          <div class="item">Speciel Event fee</div>
          <div>$2150</div>
          <div>{{amount.event}}</div>
          <div class="subtotal">${{2150 * amount.event}}</div>
        </div>
        <div class="disclosure__payment">
          <div class="label">Total Payment:&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div class="price">NTD${{total}}</div>
        </div>
        <div class="disclosure__btns" style="justify-content:flex-end;">
          <input type="button" class="nextBtn" value="Place Order" @click="submitRegister()">
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationComp',
  data () {
    return {
      country: 0,
      twFee: [
        [10650, 13400, 13700, 16450, 7600, 9150, 8540, 10050],
        [7800, 9900, 9900, 12200, 5000, 6500, 6250, 7050],
        [6400, 8100, 8100, 11150, 4600, 5500, 5200, 6000]
      ],
      usFee: [
        [350, 440, 450, 540, 250, 300, 280, 330],
        [255, 323, 323, 400, 165, 212, 205, 230],
        [210, 265, 265, 365, 150, 180, 170, 200]
      ],
      total: 0,
      answer: {
        identity: '',
        CECourse: [0, 0],
        isDinner: '',
        diet: '',
        isShow: '',
        isSpecial: '',
        accompany: 0,
        accompaniment: [{
          name: '',
          nationality: '',
          isDinner: '',
          diet: '',
          isShow: '',
          isSpecial: ''
        }, {
          name: '',
          nationality: '',
          isDinner: '',
          diet: '',
          isShow: '',
          isSpecial: ''
        }, {
          name: '',
          nationality: '',
          isDinner: '',
          diet: '',
          isShow: '',
          isSpecial: ''
        }, {
          name: '',
          nationality: '',
          isDinner: '',
          diet: '',
          isShow: '',
          isSpecial: ''
        }],
        INSid: '',
        TANDMRid: ''
      },
      amount: {
        accompany: 0,
        course: 0,
        dinner: 0,
        show: 0,
        event: 0
      }
    }
  },
  methods: {
    submitRegister () {
      if (this.checkform() !== '') {
        this.$popupError(this.checkform())
      } else {
        const self = this
        const api = 'orders/pay'
        const bodyFormData = new FormData()
        self.answer.country = self.country
        self.answer.bill = self.amount
        self.answer.registText = 'Registration Fee - ' + self.feeText() + self.countryText()
        bodyFormData.set('amount', this.total)
        bodyFormData.set('data_json', JSON.stringify(self.answer))
        this.$axios.post(self.$apiUrl + '/orders/addorder', bodyFormData, {
          headers: { 'Content-Type': 'form-data', 'member-token': localStorage.getItem('accessToken') }
        }).then((res) => {
          if (res.data.resultCode === '000') {
            const paymentUrl = self.$apiUrl + api + '?membertoken=' + localStorage.getItem('accessToken') + '&amt=' + self.total + '&orderno=' + res.data.orderno
            window.open(paymentUrl, '_blank')
            location.href = '/#/registration/receipt'
          } else {
            this.$popupError()
            location.reload()
          }
        })
      }
    },
    checkform () {
      let text = ''
      // if (!['non-student-early', 'student-early', 'non-member-early', 'member-early'].includes(this.answer.identity)) {
      //   text = 'Early Bird registration is opened till May 31. Please select Early Bird Plan.'
      // }
      if (this.answer.accompany !== 0) {
        for (let i = 0; i < this.answer.accompany; i++) {
          const ppl = this.answer.accompaniment[i]
          if (ppl.name === '' || ppl.nationality === '' || ppl.isDinner === '' || ppl.isShow === '' || ppl.isSpecial === '') {
            text = 'Please fill in your accompeniments information.'
          }
          if (ppl.isDinner === 'Yes' && ppl.diet === '') {
            text = 'Please choose your accompaniment\'s preference.'
          }
        }
      }
      if (this.answer.isDinner === 'Yes' && this.answer.diet === '') {
        text = 'Please choose your dietary preference.'
      }
      // if (['member-early', 'member-regular', 'student-early', 'student-regular'].includes(this.answer.identity)) {
      //   if (this.answer.INSid === '' && this.answer.TANDMRid === '') {
      //     text = 'Please fill in your INS membership number or TADNMR membership number.'
      //   }
      // }
      if (this.answer.identity === '' || this.answer.isDinner === '' || this.answer.isShow === '' || this.answer.isSpecial === '') {
        text = 'Please fill in all the questions.'
      }
      return text
    },
    orderText (index) {
      let text
      switch (index) {
        case 1:
          text = '1st'
          break
        case 2:
          text = '2nd'
          break
        case 3:
          text = '3rd'
          break
        case 4:
          text = '4th'
          break
      }
      return text
    },
    feeText () {
      let text = ''
      switch (this.answer.identity) {
        case 'member-early':
          text = 'Member Early Bird'
          break
        case 'member-regular':
          text = 'Member Regular'
          break
        case 'non-member-early':
          text = 'Non Member Early Bird'
          break
        case 'non-member-regular':
          text = 'Non Member Regular'
          break
        case 'student-early':
          text = 'Student Member Early Bird '
          break
        case 'student-regular':
          text = 'Student Member Regular'
          break
        case 'non-student-early':
          text = 'Non Student Member Early Bird '
          break
        case 'non-student-regular':
          text = 'Non Student Member Regular'
          break
      }
      return text
    },
    feeUnit () {
      let text = ''
      switch (this.answer.identity) {
        case 'member-early':
          text = this.twFee[this.country][0]
          break
        case 'member-regular':
          text = this.twFee[this.country][1]
          break
        case 'non-member-early':
          text = this.twFee[this.country][2]
          break
        case 'non-member-regular':
          text = this.twFee[this.country][3]
          break
        case 'student-early':
          text = this.twFee[this.country][4]
          break
        case 'student-regular':
          text = this.twFee[this.country][5]
          break
        case 'non-student-early':
          text = this.twFee[this.country][6]
          break
        case 'non-student-regular':
          text = this.twFee[this.country][7]
          break
      }
      return text
    },
    changeFee () {
      let registrationFee = 0
      let CEFee = 0
      let dinnerFee = 0
      let snackFee = 0
      let eventFee = 0
      let accompanyFee = 0
      let additionFee = 0
      this.amount = {
        course: 0,
        dinner: 0,
        show: 0,
        event: 0
      }
      switch (this.answer.identity) {
        case 'member-early':
          registrationFee = this.twFee[this.country][0]
          break
        case 'member-regular':
          registrationFee = this.twFee[this.country][1]
          break
        case 'non-member-early':
          registrationFee = this.twFee[this.country][2]
          break
        case 'non-member-regular':
          registrationFee = this.twFee[this.country][3]
          break
        case 'student-early':
          registrationFee = this.twFee[this.country][4]
          break
        case 'student-regular':
          registrationFee = this.twFee[this.country][5]
          break
        case 'non-student-early':
          registrationFee = this.twFee[this.country][6]
          break
        case 'non-student-regular':
          registrationFee = this.twFee[this.country][7]
          break
      }
      this.answer.CECourse.forEach((course) => {
        if (Number(course) !== 0) {
          CEFee += 2250
          this.amount.course++
        }
      })
      if (this.answer.isDinner === 'Yes') {
        dinnerFee = 1600
        this.amount.dinner = 1
      }
      if (this.answer.isShow === 'Yes') {
        snackFee = 450
        this.amount.show = 1
      }
      if (this.answer.isSpecial === 'Yes') {
        eventFee = 2150
        this.amount.event = 1
      }
      if (this.answer.accompany !== 0) {
        accompanyFee = 1600 * this.answer.accompany
        for (let i = 0; i < this.answer.accompany; i++) {
          if (this.answer.accompaniment[i].isDinner === 'Yes') {
            additionFee += 1600
            this.amount.dinner++
          }
          if (this.answer.accompaniment[i].isShow === 'Yes') {
            additionFee += 450
            this.amount.show++
          }
          if (this.answer.accompaniment[i].isSpecial === 'Yes') {
            additionFee += 2150
            this.amount.event++
          }
        }
      }
      this.total = registrationFee + CEFee + dinnerFee + snackFee + eventFee + accompanyFee + additionFee
    },
    popupCountry (type) {
      let htmlText = ''
      let title = ''
      switch (type) {
        case 'm':
          title = 'Middle Income Countries'
          htmlText = 'ALBANIA<br>ALGERIA<br>AMERICAN SAMOA<br>ANGOLA<br>ARGENTINA<br>ARMENIA<br>AZERBAIJAN<br>BANGLADESH<br>BELARUS<br>BELIZE<br>BENIN<br>BHUTAN<br>BOLIVIA<br>BOSNIA AND HERZEGOVINA<br>BOTSWANA<br>BRAZIL<br>BULGARIA<br>CABO VERDE<br>CAMBODIA<br>CAMEROON<br>CHINA<br>COLOMBIA<br>COMOROS<br>CONGO, REP.<br>COSTA RICA<br>COTE D\'IVOIRE<br>CUBA<br>DJIBOUTI<br>DOMINICA<br>DOMINICAN REPUBLIC<br>ECUADOR<br>EGYPT, ARAB REP.<br>EL SALVADOR<br>EQUATORIAL GUINEA<br>ESWATINI<br>FIJI<br>GABON<br>GEORGIA<br>GHANA<br>GRENADA<br>GUATEMALA<br>GUYANA<br>HAITI<br>HONDURAS<br>INDIA<br>INDONESIA<br>IRAN, ISLAMIC REP.<br>IRAQ<br>JAMAICA<br>JORDAN<br>KAZAKHSTAN<br>KENYA<br>KIRIBATI<br>KOSOVO<br>KYRGYZ REPUBLIC<br>LAO PDR<br>LEBANON<br>LESOTHO<br>LIBYA<br>MALAYSIA<br>MALDIVES<br>MARSHALL ISLANDS<br>MAURITANIA<br>MAURITIUS<br>MEXICO<br>MICRONESIA, FED. STS.<br>MOLDOVA<br>MONGOLIA<br>MONTENEGRO<br>MOROCCO<br>MYANMAR<br>NAMIBIA<br>NEPAL<br>NICARAGUA<br>NIGERIA<br>NORTH MACEDONIA<br>PAKISTAN<br>PALAU<br>PAPUA NEW GUINEA<br>PARAGUAY<br>PERU<br>PHILIPPINES<br>RUSSIAN FEDERATION<br>SAMOA<br>SAO TOME AND PRINCIPE<br>SENEGAL<br>SERBIA<br>SOLOMON ISLANDS<br>SOUTH AFRICA<br>SRI LANKA<br>ST. LUCIA<br>ST. VINCENT AND THE GRENADINES<br>SURINAME<br>TAJIKISTAN<br>TANZANIA<br>THAILAND<br>TIMOR-LESTE<br>TONGA<br>TUNISIA<br>TURKIYE<br>TURKMENISTAN<br>TUVALU<br>UKRAINE<br>UZBEKISTAN<br>VANUATU<br>VIETNAM<br>WEST BANK AND GAZA<br>ZIMBABWE<br>'
          break
        case 'l':
          title = 'Low Income Countries'
          htmlText = 'AFGHANISTAN<br>BURKINA FASO<br>BURUNDI<br>CENTRAL AFRICAN REPUBLIC<br>CHAD<br>CONGO, DEM. REP.<br>ERITREA<br>ETHIOPIA<br>GAMBIA, THE<br>GUINEA<br>GUINEA-BISSAU<br>KOREA, DEM. PEOPLE\'S REP.<br>LIBERIA<br>MADAGASCAR<br>MALAWI<br>MALI<br>MOZAMBIQUE<br>NIGER<br>RWANDA<br>SIERRA LEONE<br>SOMALIA<br>SOUTH SUDAN<br>SUDAN<br>SYRIAN ARAB REPUBLIC<br>TOGO<br>UGANDA<br>YEMEN, REP.<br>ZAMBIA<br>'
          break
      }
      console.log(title)
      this.$swal.fire({
        title: title,
        html: '<div style="overflow-y: scroll;">' + htmlText + '</div>',
        focusConfirm: false,
        confirmButtonText: 'Close',
        width: '390px',
        padding: '20px 12px 30px',
        customClass: {
          confirmButton: 'popup__btn '
        },
        didOpen: () => {
        }
      })
    },
    countryText () {
      if (this.country !== 0 || this.country !== '0') {
        if (this.country === 1 || this.country === '1') {
          return '(Middle Income Coutry)'
        } else if (this.country === 2 || this.country === '2') {
          return '(Low Income Country)'
        }
      }
    },
    goReceipt () {
      location.href = '/#/registration/receipt'
    }
  },
  mounted () {
    if (localStorage.getItem('accessToken') !== null) {
      this.$axios.get(this.$apiUrl + 'members/info', {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      })
        .then((res) => {
          if (res.data.resultCode === '006') {
            this.$tokenError()
          }
        })
    } else {
      location.href = '/#/login'
    }
  }
}

</script>
