<template>
  <div>
    <form id="paperInfoRegular">
      <div class="page-content summit summit--form">
      <div class="title" style="margin-bottom: 20px;">Editing Submitted Abstract</div>
      <div class="author folder">
        <div class="tabs">
          <div class="tab active" @click="showAuthor('presenting')" id="pauthor_tab">Presenting Author</div>
          <div class="tab" @click="showAuthor('corresponding')" id="cauthor_tab">Corresponding Author</div>
        </div>
        <div class="form--pAuthor" v-show="author_switch == 'presenting'">
          <div v-if="state=='paperInfo'" >
            <div class="same" @click="sameUser()">
              Click if Presenting Author is same as User
            </div>
          </div>
          <p class="warning">Please note that further notification regarding abstract submitted would be send to the presenting author ONLY.</p>
          <div class="signup__form signup__form--submit" id="pAuthor">
            <label for="" class="item required">Nationality</label>
            <div class="inputwrap">
              <select v-if="state == 'paperInfo'" name="nation" v-model.lazy='abstract.presenting_author_nationality_code' @change="changeSelectNation('p')" required>
                <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
              </select>
              <input v-else-if="state == 'preview'" type="text" :value="abstract.presenting_author_nationality_code" style="background-color: #f2f2f2;">
            </div>
            <label for="" class="item required">Title</label>
            <div class="inputwrap inputwrap__radio">
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-presenting-title" v-model.lazy="abstract.presenting_author_title" value="1" required>
              <label>Dr.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-presenting-title" v-model.lazy="abstract.presenting_author_title" value="2" >
              <label>Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-presenting-title" v-model.lazy="abstract.presenting_author_title" value="3" >
              <label>Associate Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-presenting-title" v-model.lazy="abstract.presenting_author_title" value="4" >
              <label>Assistant Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-presenting-title" v-model.lazy="abstract.presenting_author_title" value="5" >
              <label>Mr.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-presenting-title" v-model.lazy="abstract.presenting_author_title" value="6" >
              <label>Mrs.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-presenting-title" v-model.lazy="abstract.presenting_author_title" value="7" >
              <label>Ms.</label>
            </div>
          </div>
            <label for="" class="item required">First (Given) Name</label>
            <div class="inputwrap">
              <input type="text" class="preview" v-model.lazy="abstract.presenting_author_first_name" required>
            </div>
            <label for="" class="item">Middle Name</label>
            <div class="inputwrap">
              <input type="text" class="preview" v-model="abstract.presenting_author_middle_name">
            </div>
            <label for="" class="item required">Last (Family) Name</label>
            <div class="inputwrap">
              <input type="text" class="preview" v-model.lazy="abstract.presenting_author_last_name" required>
            </div>
          <label class="item required" v-show="is_Chinese[0]">中文名字</label>
          <div class="inputwrap" v-show="is_Chinese[0]">
            <input type="text" id="input-chinese_name_present" class="preview" v-model.lazy="abstract.presenting_author_chinese_name">
          </div>
            <label for="" class="item required">Gender</label>
            <div class="inputwrap inputwrap__radio">
              <input name="radio_gender" type="radio" value="1" class="preview radio-presenting-gender" v-model.lazy="abstract.presenting_author_gender" required>
              <label for="male">Male</label>
              <input name="radio_gender" type="radio" value="2" class="preview radio-presenting-gender" v-model.lazy="abstract.presenting_author_gender">
              <label for="female">Female</label>
            </div>
            <label for="" class="item required">Date of Birth</label>
            <div class="inputwrap">
              <input type="date" class="preview" v-model="abstract.presenting_author_birth_date" required>
            </div>
            <label for="" class="item required">Job Position</label>
            <div class="inputwrap">
              <input type="text" class="preview" placeholder="Please enter your Job Title."  v-model="abstract.presenting_author_job_position" required>
            </div>
            <label for="" class="item">Department</label>
            <div class="inputwrap">
              <input type="text" class="preview" placeholder="Please enter the full name of your department."  v-model="abstract.presenting_author_department">
            </div>
            <label for="" class="item required">Affiliation</label>
            <div class="inputwrap">
              <input type="text" class="preview" placeholder="Please enter the full name of your institution."  v-model.lazy="abstract.presenting_author_affiliation" required>
            </div>
            <label for="" class="item required">Country of Affiliation</label>
            <div class="inputwrap">
              <select v-if="state == 'paperInfo'" name="nation" v-model.lazy='abstract.presenting_author_country_of_affiliation_code' required>
                <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
              </select>
              <input v-else-if="state == 'preview'" type="text" :value="abstract.presenting_author_country_of_affiliation_code" style="background-color: #f2f2f2;">
            </div>
            <label for="" class="item required">Address</label>
            <div class="inputwrap">
              <input type="text" class="preview"  v-model="abstract.presenting_author_address" required>
            </div>
            <label for="" class="item required">Tel Number</label>
            <div class="inputwrap inputwrap__phone">
              <input type="text"  class="preview" v-model="abstract.presenting_author_tel_number" required>
            </div>
            <label for="" class="item required">Mobile</label>
            <div class="inputwrap inputwrap__phone">
              <input type="text" class="preview"  v-model="abstract.presenting_author_mobile" required>
            </div>
            <label for="" class="item required">INS Membership</label>
            <div class="inputwrap inputwrap__radio">
              <input type="radio" name="radio_isMember" value="1" class="preview radio-presenting-membership" v-model.lazy="abstract.presenting_author_is_ins_member" required>
              <label for="yes">Yes, I am INS member</label>
              <hr class="forcewrap">
              <input type="radio" name="radio_isMember" value="0" class="preview radio-presenting-membership" v-model.lazy="abstract.presenting_author_is_ins_member">
              <label for="no">No, I am not INS member</label>
            </div>
            <label for="" class="item required">Student Status</label>
              <div class="inputwrap inputwrap__radio">
              <input type="radio" name="radio_student" class="preview radio-presenting-student" v-model.lazy="abstract.presenting_author_student_status" value="1" required>
              <label>Does Not Apply</label>
              <hr class="forcewrap">
              <input type="radio" name="radio_student" class="preview radio-presenting-student" v-model.lazy="abstract.presenting_author_student_status" value="2" >
              <label>Graduate Student</label>
              <hr class="forcewrap">
              <input type="radio" name="radio_student" class="preview radio-presenting-student" v-model.lazy="abstract.presenting_author_student_status" value="3" >
              <label>Post-Doctoral Fellow</label>
            </div>
            <label for="" class="item required">Email</label>
            <div class="inputwrap inputwrap__email">
              <input type="email" class="preview" v-model="abstract.presenting_author_email" required>
              <div class="description"><i>Please allow emails from us in your email spam filter, or make sure that “ins2023tw@gmail.com” is on your "Approved Sender List” or "White List" and/or in your "Address Book." Please enter your E-mail.</i></div>
            </div>
          </div>
        </div>
        <div class="form--pAuthor" v-show="author_switch == 'corresponding'">
          <div class="same mb20" v-if="state=='paperInfo'" @click="sameAuthor()">
            Click if Corresponding Author is same as Presenting Author
          </div>
          <div class="signup__form signup__form--submit" id="cAuthor">
            <label for="" class="item required">Nationality</label>
            <div class="inputwrap">
              <select v-if="state == 'paperInfo'" name="nation" v-model.lazy='abstract.corresponding_author_nationality_code' @change="changeSelectNation('c')" required>
                <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
              </select>
              <input v-else-if="state == 'preview'" type="text" :value="abstract.corresponding_author_nationality_code" style="background-color: #f2f2f2;">
            </div>
            <label for="" class="item required">Title</label>
            <div class="inputwrap inputwrap__radio">
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-corresponding-title" v-model.lazy="abstract.corresponding_author_title" value="1" required>
              <label>Dr.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-corresponding-title" v-model.lazy="abstract.corresponding_author_title" value="2" >
              <label>Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-corresponding-title" v-model.lazy="abstract.corresponding_author_title" value="3" >
              <label>Associate Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-corresponding-title" v-model.lazy="abstract.corresponding_author_title" value="4" >
              <label>Assistant Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-corresponding-title" v-model.lazy="abstract.corresponding_author_title" value="5" >
              <label>Mr.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-corresponding-title" v-model.lazy="abstract.corresponding_author_title" value="6" >
              <label>Mrs.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview radio-corresponding-title" v-model.lazy="abstract.corresponding_author_title" value="7" >
              <label>Ms.</label>
            </div>
          </div>
            <label for="" class="item required">First (Given) Name</label>
            <div class="inputwrap">
              <input type="text" class="preview" v-model="abstract.corresponding_author_first_name" required>
            </div>
            <label for="" class="item">Middle Name</label>
            <div class="inputwrap">
              <input type="text"  class="preview" v-model="abstract.corresponding_author_middle_name">
            </div>
            <label for="" class="item required">Last (Family) Name</label>
            <div class="inputwrap">
              <input type="text"  class="preview" v-model="abstract.corresponding_author_last_name" required>
            </div>
          <label class="item required" v-show="is_Chinese[1]">中文名字</label>
          <div class="inputwrap" v-show="is_Chinese[1]">
            <input type="text" id="input-chinese_name_correspond" class="preview" v-model.lazy="abstract.corresponding_author_chinese_name">
          </div>
            <label for="" class="item required">Gender</label>
            <div class="inputwrap inputwrap__radio">
              <input name="radio_gender" type="radio" value="1" class="preview radio-corresponding-gender" v-model.lazy="abstract.corresponding_author_gender" required>
              <label for="male">Male</label>
              <input name="radio_gender" type="radio" value="2" class="preview radio-corresponding-gender" v-model.lazy="abstract.corresponding_author_gender">
              <label for="female">Female</label>
            </div>
            <label for="" class="item required">Date of Birth</label>
            <div class="inputwrap">
              <input type="date" class="preview" v-model="abstract.corresponding_author_birth_date" required>
            </div>
            <label for="" class="item required">Job Position</label>
            <div class="inputwrap">
              <input type="text" class="preview" placeholder="Please enter your Job Title."  v-model="abstract.corresponding_author_job_position" required>
            </div>
            <label for="" class="item">Department</label>
            <div class="inputwrap">
              <input type="text" class="preview" placeholder="Please enter the full name of your department."  v-model="abstract.corresponding_author_department">
            </div>
            <label for="" class="item required">Affiliation</label>
            <div class="inputwrap">
              <input type="text" class="preview" placeholder="Please enter the full name of your institution."  v-model="abstract.corresponding_author_affiliation" required>
            </div>
            <label for="" class="item required">Country of Affiliation</label>
            <div class="inputwrap">
              <select v-if="state == 'paperInfo'" name="nation" class="preview" v-model.lazy='abstract.corresponding_author_country_of_affiliation_code' required>
                <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
              </select>
              <input v-else-if="state == 'preview'" type="text" :value="abstract.corresponding_author_country_of_affiliation_code" style="background-color: #f2f2f2;">
            </div>
            <label for="" class="item required">Address</label>
            <div class="inputwrap">
              <input type="text" class="preview" v-model="abstract.corresponding_author_address" required>

            </div>
            <label for="" class="item required">Tel Number</label>
            <div class="inputwrap inputwrap__phone">
              <input type="text" class="preview" v-model="abstract.corresponding_author_tel_number" required>

            </div>
            <label for="" class="item required">Mobile</label>
            <div class="inputwrap inputwrap__phone">
              <input type="text" class="preview" v-model="abstract.corresponding_author_mobile" required>

            </div>
            <label for="" class="item required">INS Membership</label>
            <div class="inputwrap inputwrap__radio">
              <input type="radio" name="radio_isMember" value="1" class="preview radio-corresponding-membership" v-model.lazy="abstract.corresponding_author_is_ins_member" required>
              <label for="yes">Yes, I am INS member</label>
              <hr class="forcewrap">
              <input type="radio" name="radio_isMember" value="0" class="preview radio-corresponding-membership" v-model.lazy="abstract.corresponding_author_is_ins_member">
              <label for="no">No, I am not INS member</label>
            </div>
            <label for="" class="item required">Student Status</label>
              <div class="inputwrap inputwrap__radio">
              <input type="radio" name="radio_student" class="preview radio-corresponding-student" v-model.lazy="abstract.corresponding_author_student_status" value="1" required>
              <label>Does Not Apply</label>
              <hr class="forcewrap">
              <input type="radio" name="radio_student" class="preview radio-corresponding-student" v-model.lazy="abstract.corresponding_author_student_status" value="2" >
              <label>Graduate Student</label>
              <hr class="forcewrap">
              <input type="radio" name="radio_student" class="preview radio-corresponding-student" v-model.lazy="abstract.corresponding_author_student_status" value="3" >
              <label>Post-Doctoral Fellow</label>
            </div>
            <label for="" class="item required">Email</label>
            <div class="inputwrap inputwrap__email">
              <input type="email"  class="preview" v-model="abstract.corresponding_author_email" required>
              <div class="description"><i>Please allow emails from us in your email spam filter, or make sure that “ins2023tw@gmail.com” is on your "Approved Sender List” or "White List" and/or in your "Address Book." Please enter your E-mail.</i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content summit summit--coAuthor ">
      <div class="coAuthor">
        <input v-if="state=='paperInfo'" type="button" class="btn--delete" @click="removeCoAuthor()">
        <input v-if="state=='paperInfo'" type="button" class="btn" value="New" @click="newCoAuthor()">
        <div class="title">Co-Authors</div>
      </div>
      <div class="coAuthorList">
        <div class="list-head">
          <div class="span2">Orders</div>
          <div class="span2">Title</div>
          <div class="span2">First Name</div>
          <div class="span2">Last Name</div>
          <div>Affiliation<br>(Working Institution</div>
          <div>Country of Affiliation</div>
          <div><button type="button" @click="sameAffiliation($event)">Same as <br>Presenting Author.</button></div>
          <div><button type="button" @click="sameCountry($event)">Same as <br>Presenting Author.</button></div>
        </div>
        <div class="list-row">
          <div class="wrapper"><input type="checkbox" disabled>01</div>
          <div>{{abstract.presenting_author_title != 0 ? titleList[abstract.presenting_author_title-1] : 'Presenting Author\'s Title'}}</div>
          <div>{{abstract.presenting_author_first_name|| 'Presenting Author'}}</div>
          <div>{{abstract.presenting_author_last_name|| 'Presenting Author'}}</div>
          <div>{{abstract.presenting_author_affiliation|| 'Presenting Author\'s Affiliation'}}</div>
          <div>{{abstract.presenting_author_country_of_affiliation_code || 'Presenting Author\'s Country of Affiliation'}}</div>
        </div>
        <div class="list-row list-new" v-for="(el,co_index) in abstract.co_authors" :key="co_index">
          <div class="wrapper"><input type="checkbox" v-model="deleteCoAuthorList" :value="co_index" class="preview">0{{co_index+2}}</div>
          <select v-model="el.title" v-if="state == 'paperInfo'">
            <option v-for="(title,index) in titleList" :key="index" :value="index+1">{{title}}</option>
          </select>
          <input v-else-if="state == 'preview'" type="text" :value="titleList[el.title-1]" style="background-color: #f2f2f2;">
          <input type="text" class="preview" placeholder="First Name" v-model="el.first_name" required>
          <input type="text" class="preview" placeholder="Last Name" v-model="el.last_name" required>
          <input type="text" class="preview" placeholder="Affiliation" v-model="el.affiliation" required>
          <div>
            <select v-if="state == 'paperInfo'" name="nation" v-model.lazy='el.country_of_affiliation_code' style="width:125px; margin: 0 6px;" required>
              <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
            </select>
            <input v-else-if="state == 'preview'" type="text" :value="el.country_of_affiliation_code" style="background-color: #f2f2f2;">
          </div>
        </div>
      </div>
    </div>
    <div class="page-content summit">
      <div class="subtitle warning">Body of Abstract <span class="warning" style="font-size: 12px">(All fields with * are required.)</span></div>
      <div class="bodyForm" id="bodyForm">
        <label for="" class="required">Title of Abstract</label>
        <input type="text" class="preview" v-model.lazy="abstract.abstract_title" required>
        <span class="warning" style="font-size: 12px; grid-column: 2/3;">*Please be reminded that only the first letter of the Title should be capitalized.</span>
        <label for="" class="required">Abstract Category</label>
        <select id="categorySelect" class="bodyForm__select" v-model="abstract.body_of_abstract_category"></select>
        <div class="tips">*Maximum of 3 Categories</div>
        <label class="item required">1. Objective</label>
        <ckeditor :editor="editor" v-model="abstract.body_of_abstract_objective_content" :config="editorObjConfig" :disabled="state == 'preview'"></ckeditor>
        <label class="item required">2. Participants and Methods</label>
        <ckeditor :editor="editor" v-model="abstract.body_of_abstract_participants_and_methods" :config="editorMethodConfig" :disabled="state == 'preview'"></ckeditor>
        <label class="item required">3. Results</label>
        <ckeditor :editor="editor" v-model="abstract.body_of_abstract_results" :config="editorResultConfig" :disabled="state == 'preview'"></ckeditor>
        <label class="item required">4. Conclusion(s)</label>
        <ckeditor :editor="editor" v-model="abstract.body_of_abstract_conclusions" :config="editorConclusionConfig" :disabled="state == 'preview'"></ckeditor>
        <label class="item--5 required">5. Keyword(s)</label>
        <select id="keywordSelect" class="bodyForm__select" v-model="abstract.body_of_abstract_keywords"></select>
        <div class="tips">*Maximum of 5 Keywords</div>
        <div class="limit">{{total}} / 2000 characters</div>
        <div class="tips" style="grid-column: 1/3;">*The body of the abstract (1~4 Total) should be typed in English and should be less than 2,000 characters. Please note that extra words would be cut off automatically.</div>
      </div>
    </div>
      <div class="btns" v-if="state == 'paperInfo'">
        <input type="button" class="backType" value="Cancel" @click="cancelEdit()">
        <input type="button" class="toPreview" value="Next" @click="isFilled()">
      </div>
      <template v-else-if="state == 'preview'">
        <div class="warning mb20">Please click “SUBMIT” to complete the Abstract submission process <br>or you can click “Back” to revise your Abstract Information.</div>
        <div class="btns">
          <input type="button" class="backType" value="Back" @click="toEditing()">
          <input type="button" class="toPreview" value="Submit" @click="SUBMITABSTRACT()">
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'EditAbstractComp',
  data () {
    return {
      state: 'paperInfo',
      author_switch: 'presenting',
      is_Chinese: [false, false],
      titleList: ['Dr.', 'Prof.', 'Associate Prof.', 'Assistant Prof.', 'Mr.', 'Mrs.', 'Ms.'],
      deleteCoAuthorList: [],
      categories: [],
      keywords: [],
      selectCat: null,
      selectKey: null,
      abstract: {
        presenting_author_nationality_code: '',
        presenting_author_title: 0,
        presenting_author_first_name: '',
        presenting_author_middle_name: '',
        presenting_author_last_name: '',
        presenting_author_chinese_name: '',
        presenting_author_gender: 0,
        presenting_author_birth_date: '',
        presenting_author_job_position: '',
        presenting_author_department: '',
        presenting_author_affiliation: '',
        presenting_author_country_of_affiliation_code: '',
        presenting_author_address: '',
        presenting_author_tel_number: '',
        presenting_author_mobile: '',
        presenting_author_is_ins_member: '',
        presenting_author_student_status: '',
        presenting_author_email: '',
        btn_Is_the_corresponding_author_same: 0,
        corresponding_author_nationality_code: '',
        corresponding_author_title: 0,
        corresponding_author_first_name: '',
        corresponding_author_middle_name: '',
        corresponding_author_last_name: '',
        corresponding_author_chinese_name: '',
        corresponding_author_gender: 0,
        corresponding_author_birth_date: '',
        corresponding_author_job_position: '',
        corresponding_author_department: '',
        corresponding_author_affiliation: '',
        corresponding_author_country_of_affiliation_code: '',
        corresponding_author_address: '',
        corresponding_author_tel_number: '',
        corresponding_author_mobile: '',
        corresponding_author_is_ins_member: 0,
        corresponding_author_student_status: 0,
        corresponding_author_email: '',
        co_authors: [{
          orders: '',
          title: '',
          first_name: '',
          last_name: '',
          affiliation: '',
          country_of_affiliation_code: ''
        }],
        abstract_id: '',
        abstract_title: '',
        body_of_abstract_category: [],
        body_of_abstract_objective_content: '',
        body_of_abstract_participants_and_methods: '',
        body_of_abstract_results: '',
        body_of_abstract_conclusions: '',
        body_of_abstract_keywords: []
      },
      totalCharacters: [0, 0, 0, 0],
      total: 0,
      editor: ClassicEditor,
      editorObjConfig: {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.totalCharacters[0] = stats.characters
            this.countWord()
          }
        }
      },
      editorMethodConfig: {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.totalCharacters[1] = stats.characters
            this.countWord()
          }
        }
      },
      editorResultConfig: {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.totalCharacters[2] = stats.characters
            this.countWord()
          }
        }
      },
      editorConclusionConfig: {
        languege: 'en',
        wordCount: {
          showCharCount: true,
          onUpdate: (stats) => {
            this.totalCharacters[3] = stats.characters
            this.countWord()
          }
        }
      }
    }
  },
  methods: {
    showAuthor (author) {
      this.author_switch = author
      if (author === 'presenting') {
        document.getElementById('pauthor_tab').classList.add('active')
        document.getElementById('cauthor_tab').classList.remove('active')
        if (this.abstract.presenting_author_title !== 0 && this.abstract.presenting_author_title !== undefined) {
          var q = 'input.radio-presenting-title[value=\'' + this.abstract.presenting_author_title + '\']'
          document.querySelector(q).checked = true
        }
        if (this.abstract.presenting_author_gender !== 0 && this.abstract.presenting_author_gender !== undefined) {
          var gq = 'input.radio-presenting-gender[value=\'' + this.abstract.presenting_author_gender + '\']'
          document.querySelector(gq).checked = true
        }
        if (this.abstract.presenting_author_is_ins_member !== 0 && this.abstract.presenting_author_is_ins_member !== undefined) {
          var mq = 'input.radio-presenting-membership[value=\'' + this.abstract.presenting_author_is_ins_member + '\']'
          document.querySelector(mq).checked = true
        }
        if (this.abstract.presenting_author_student_status !== 0 && this.abstract.presenting_author_student_status !== undefined) {
          var sq = 'input.radio-presenting-student[value=\'' + this.abstract.presenting_author_student_status + '\']'
          document.querySelector(sq).checked = true
        }
      } else if (author === 'corresponding') {
        document.getElementById('pauthor_tab').classList.remove('active')
        document.getElementById('cauthor_tab').classList.add('active')
        if (this.abstract.corresponding_author_title !== 0 && this.abstract.corresponding_author_title !== undefined) {
          var cq = 'input.radio-corresponding-title[value=\'' + this.abstract.corresponding_author_title + '\']'
          document.querySelector(cq).checked = true
        }
        if (this.abstract.corresponding_author_gender !== 0 && this.abstract.corresponding_author_gender !== undefined) {
          var cgq = 'input.radio-corresponding-gender[value=\'' + this.abstract.corresponding_author_gender + '\']'
          document.querySelector(cgq).checked = true
        }
        if (this.abstract.corresponding_author_is_ins_member !== 0 && this.abstract.corresponding_author_is_ins_member !== undefined) {
          var cmq = 'input.radio-corresponding-membership[value=\'' + this.abstract.corresponding_author_is_ins_member + '\']'
          document.querySelector(cmq).checked = true
        }
        if (this.abstract.corresponding_author_student_status !== 0 && this.abstract.corresponding_author_student_status !== undefined) {
          var csq = 'input.radio-corresponding-student[value=\'' + this.abstract.corresponding_author_student_status + '\']'
          document.querySelector(csq).checked = true
        }
      }
    },
    sameUser () {
      if (this.state === 'preview') {
        this.$popupError('Cannot apply this action in preview mode.')
        return true
      }
      this.abstract.presenting_author_nationality_code = this.$parent.member.nationality_code
      this.abstract.presenting_author_title = this.$parent.member.title
      this.abstract.presenting_author_first_name = this.$parent.member.first_name
      this.abstract.presenting_author_middle_name = this.$parent.member.middle_name
      this.abstract.presenting_author_last_name = this.$parent.member.last_name
      this.abstract.presenting_author_chinese_name = this.$parent.member.chinese_name
      this.abstract.presenting_author_gender = this.$parent.member.gender
      this.abstract.presenting_author_birth_date = this.$parent.member.birth_date
      this.abstract.presenting_author_job_position = this.$parent.member.job_position
      this.abstract.presenting_author_department = this.$parent.member.department
      this.abstract.presenting_author_affiliation = this.$parent.member.affiliation
      this.abstract.presenting_author_country_of_affiliation_code = this.$parent.member.country_of_affiliation_code
      this.abstract.presenting_author_address = this.$parent.member.address
      this.abstract.presenting_author_tel_number = this.$parent.member.tel_number
      this.abstract.presenting_author_mobile = this.$parent.member.mobile
      this.abstract.presenting_author_is_ins_member = this.$parent.member.is_ins_member
      this.abstract.presenting_author_student_status = this.$parent.member.student_status
      this.abstract.presenting_author_email = this.$parent.member.email
    },
    sameAuthor () {
      if (this.state === 'preview') {
        this.$popupError('Cannot apply this action in preview mode.')
        return true
      }
      this.abstract.btn_Is_the_corresponding_author_same = 1
      this.abstract.corresponding_author_nationality_code = this.abstract.presenting_author_nationality_code
      this.abstract.corresponding_author_title = this.abstract.presenting_author_title
      this.abstract.corresponding_author_first_name = this.abstract.presenting_author_first_name
      this.abstract.corresponding_author_middle_name = this.abstract.presenting_author_middle_name
      this.abstract.corresponding_author_last_name = this.abstract.presenting_author_last_name
      this.abstract.corresponding_author_chinese_name = this.abstract.presenting_author_chinese_name
      this.abstract.corresponding_author_gender = this.abstract.presenting_author_gender
      this.abstract.corresponding_author_birth_date = this.abstract.presenting_author_birth_date
      this.abstract.corresponding_author_job_position = this.abstract.presenting_author_job_position
      this.abstract.corresponding_author_department = this.abstract.presenting_author_department
      this.abstract.corresponding_author_affiliation = this.abstract.presenting_author_affiliation
      this.abstract.corresponding_author_country_of_affiliation_code = this.abstract.presenting_author_country_of_affiliation_code
      this.abstract.corresponding_author_address = this.abstract.presenting_author_address
      this.abstract.corresponding_author_tel_number = this.abstract.presenting_author_tel_number
      this.abstract.corresponding_author_mobile = this.abstract.presenting_author_mobile
      this.abstract.corresponding_author_is_ins_member = this.abstract.presenting_author_is_ins_member
      this.abstract.corresponding_author_student_status = this.abstract.presenting_author_student_status
      this.abstract.corresponding_author_email = this.abstract.presenting_author_email
    },
    countWord () {
      this.total = 0
      this.totalCharacters.forEach(el => {
        this.total += el
      })
    },
    changeSelectNation (type) {
      const mandarin = ['TW', 'CN', 'HK', 'MO']
      if (type === 'p') {
        if (mandarin.includes(this.abstract.presenting_author_nationality_code)) {
          this.is_Chinese[0] = true
          document.getElementById('input-chinese_name_present').classList.add('required')
          if (['HK', 'MO'].includes(this.abstract.presenting_author_nationality_code)) {
            document.getElementById('input-chinese_name_present').classList.remove('required')
          }
        } else {
          this.is_Chinese[0] = false
        }
      } else if (type === 'c') {
        if (mandarin.includes(this.abstract.corresponding_author_nationality_code)) {
          this.is_Chinese[1] = true
          document.getElementById('input-chinese_name_correspond').classList.add('required')
          if (['HK', 'MO'].includes(this.abstract.corresponding_author_nationality_code)) {
            document.getElementById('input-chinese_name_correspond').classList.remove('required')
          }
        } else {
          this.is_Chinese[1] = false
        }
      }
    },
    cancelEdit () {
      location.href = '/#/abstract'
    },
    isFilled () {
      document.getElementById('paperInfoRegular').classList.add('summitted')
      if (this.selectCat.getValue().length === 0) {
        this.$popupError('Please select Abstract Category.')
        return true
      }
      if (this.selectKey.getValue().length === 0) {
        this.$popupError('Please select Keywords.')
        return true
      }
      if (this.total > 2000) {
        this.$popupError('Your abstract is more than 2000 characters in total, please check again.')
      } else if (!document.getElementById('paperInfoRegular').reportValidity()) {
        this.$popupError('Please fill in all columns.')
      } else if (this.abstract.body_of_abstract_objective_content === '') {
        this.$popupError('Please submit your Objective.')
      } else if (this.abstract.body_of_abstract_participants_and_methods === '') {
        this.$popupError('Please submit your Participants and Methods.')
      } else if (this.abstract.body_of_abstract_results === '') {
        this.$popupError('Please submit your Results.')
      } else if (this.abstract.body_of_abstract_conclusions === '') {
        this.$popupError('Please submit your Conclusions.')
      } else {
        this.toPreview()
      }
    },
    toPreview () {
      this.state = 'preview'
      const previewInput = document.querySelectorAll('#paperInfoRegular .preview')
      Array.prototype.forEach.call(previewInput, function (el) {
        if (el.type === 'radio') {
          el.setAttribute('onclick', 'return false;')
        } else if (el.type === 'checkbox') {
          el.setAttribute('disabled', 'disabled')
        } else {
          el.setAttribute('readonly', true)
          el.style.backgroundColor = '#f2f2f2'
        }
      })
      const selectbtn = document.getElementsByClassName('selectr-tag-remove')
      Array.prototype.forEach.call(selectbtn, function (el) {
        el.style.display = 'none'
      })
      this.selectKey.disable()
      this.selectCat.disable()
    },
    toEditing () {
      this.state = 'paperInfo'
      this.selectKey.enable()
      this.selectCat.enable()
      const previewInput = document.querySelectorAll('#paperInfoRegular .preview')
      Array.prototype.forEach.call(previewInput, function (el) {
        if (el.type === 'radio') {
          el.removeAttribute('onclick')
        } else if (el.type === 'checkbox') {
          el.removeAttribute('disabled')
        } else {
          el.removeAttribute('readonly')
          el.style.backgroundColor = 'white'
        }
      })
      const selectbtn = document.getElementsByClassName('selectr-tag-remove')
      Array.prototype.forEach.call(selectbtn, function (el) {
        el.style.display = 'block'
      })
    },
    SUBMITABSTRACT () {
      const firstCoAuthor = {
        orders: '01',
        title: this.abstract.presenting_author_title,
        first_name: this.abstract.presenting_author_first_name,
        last_name: this.abstract.presenting_author_last_name,
        affiliation: this.abstract.presenting_author_affiliation,
        country_of_affiliation_code: this.abstract.presenting_author_country_of_affiliation_code
      }
      if (this.abstract.co_authors.length === 0) {
        this.abstract.co_authors.unshift(firstCoAuthor)
      } else {
        if (this.abstract.co_authors.length !== 0 && this.abstract.co_authors[0].orders !== '01') {
          this.abstract.co_authors.unshift(firstCoAuthor)
        }
      }
      this.abstract.disclosure_data = this.disclosure_data
      this.abstract.presentation_type = this.presentation_type
      const header = { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      this.$axios.post(this.$apiUrl + 'submission/edit_abstractlist', this.abstract, {
        headers: header
      }).then((res) => {
        if (res.data.resultCode === '000') {
          this.$popupSuccess('Your Abstract has been changed!')
          location.href = '/#/abstract'
        } else if (res.data.resultCode === '006') {
          this.$tokenError()
        } else {
          this.$popupError()
        }
      })
    },
    setRadio () {
      if (this.abstract.presenting_author_title !== 0 && this.abstract.presenting_author_title !== undefined) {
        var q = 'input.radio-presenting-title[value=\'' + this.abstract.presenting_author_title + '\']'
        document.querySelector(q).checked = true
      }
      if (this.abstract.presenting_author_gender !== 0 && this.abstract.presenting_author_gender !== undefined) {
        var gq = 'input.radio-presenting-gender[value=\'' + this.abstract.presenting_author_gender + '\']'
        document.querySelector(gq).checked = true
      }
      if (this.abstract.presenting_author_is_ins_member !== 0 && this.abstract.presenting_author_is_ins_member !== undefined) {
        var mq = 'input.radio-presenting-membership[value=\'' + this.abstract.presenting_author_is_ins_member + '\']'
        document.querySelector(mq).checked = true
      }
      if (this.abstract.presenting_author_student_status !== 0 && this.abstract.presenting_author_student_status !== undefined) {
        var sq = 'input.radio-presenting-student[value=\'' + this.abstract.presenting_author_student_status + '\']'
        document.querySelector(sq).checked = true
      }
    }
  },
  beforeMount () {
    console.log(this.$route.query.t)
    console.log('?????')
    if (this.$route.query.t !== undefined) {
      localStorage.setItem('accessToken', this.$route.query.t)
      localStorage.setItem('memberId', this.$route.query.mid)
    }
  },
  mounted () {
    console.log('????dsdl;sf')
    if (localStorage.getItem('accessToken') !== null) {
      this.$axios.post(this.$apiUrl + 'submission/abstract', { abstract_id: this.$route.params.id }, {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      })
        .then((res) => {
          if (res.data.resultCode === '000') {
            if (res.data.data.presentation_type !== '4') {
              this.abstract = res.data.data
              this.abstract.co_authors.shift()
              this.abstract.abstract_id = this.$route.params.id
            } else {
              location.href = '/#/submit/edit_symposium/' + this.$route.params.id
            }
            this.setRadio()
          } else if (res.data.resultCode === '006') {
            this.$tokenError()
          } else if (res.data.resultCode === '007') {
            this.$popupError()
            location.href = '/#/abstract'
          } else {
            location.reload()
          }
        })
    } else {
      this.$popupHint('Please login your account before editting abstracts.')
      location.href = '/#/login'
    }
    this.changeSelectNation('p')
    const categoryApi = 'submission/category'
    this.$axios.get(this.$apiUrl + categoryApi).then((res) => {
      this.categories = res.data.data
      // eslint-disable-next-line
      this.selectCat = new Selectr('#categorySelect', {data: this.categories, multiple: true, maxSelections: 3})
      this.selectCat.setValue(this.abstract.body_of_abstract_category)
    })
    const keywordApi = 'submission/keywords'
    this.$axios.get(this.$apiUrl + keywordApi, {
      headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken') }
    }).then((res) => {
      this.keywords = res.data.data
      // eslint-disable-next-line
      this.selectKey = new Selectr('#keywordSelect', {data: this.keywords, multiple: true, maxSelections: 5})
      this.selectKey.setValue(this.abstract.body_of_abstract_keywords)
    })
  }
}
</script>
