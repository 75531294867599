<template>
  <div>
    <div class="page-holder">
      <div class="page-content abstract">
        <div class="title abstract__title">INS 2023 Taiwan Meeting Online<br>Submission System</div>
        <div class="abstract__description">Welcome to the INS 2023 Taiwan Meeting Online Abstract Submission System</div>
        <div class="abstract__note">
          <p class="warning">Please note that the modification and revision service will be terminated on Deadline of Abstract Submission, <b class="warning">March 24, 2023.</b></p>
          <hr>
          <div><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-tittle-date.png" style="margin-right:8px;">Important Dates</div>
          <ul class="abstract__tips">
            <li><span>Abstract Submission Opens: </span><span>December 1, 2022</span></li>
            <li><span>Abstract Submission Closes: </span><span>March 24, 2023</span></li>
            <li><span>Notice of Abstract Acceptance: </span><span>April 25, 2023</span></li>
            <li><span>Author Registration Deadline: </span><span>May 25, 2023</span></li>
          </ul>
        </div>
        <div class="abstract__steps">
          <div><b>Step 1:</b></div>
          <div>Click "New" to start a new submission.</div>
          <div><b>Step 2:</b></div>
          <div>Read the abstract submission instructions carefully on the next page.</div>
          <div><b>Step 3:</b></div>
          <div>Click "Modify" to preview the detailed information or revise each submitted abstract.</div>
        </div>
        <p>Please note that the revision service will be terminated on <span class="warning">March 24, 2023 .</span></p>
      </div>
      <div class="page-content abstract__list">
        <div class="title">
          Abstract List
          <!-- <input type="button" value="New" @click="newAbstract()"> -->
        </div>
        <div class="table">
          <div class="table__row table__row--th">
            <div>Modify</div>
            <div>NO</div>
            <div>Ref. NO</div>
            <div>Title of Abstract</div>
            <div>Submission<br>Date</div>
            <div>Abstract</div>
            <div>Oral<br>Video</div>
            <div>Poster File<br>PDF</div>
            <div>Delete</div>
          </div>
          <div class="table__row" v-if="abstract_list.length === 0">
            <div>-</div>
            <div>-</div>
            <div>-</div>
            <div>-</div>
            <div>-</div>
            <div>-</div>
            <div>-</div>
            <div>-</div>
            <div>-</div>
          </div>
          <template v-for="abs in abstract_list" >
            <div class="table__row" :key="abs.no">
              <input type="button" class="modify" @click="modifyAbstract(abs.ref_no, abs.abstract_id, abs.ref_no[0])">
              <div class="no">{{abs.no}}</div>
              <div class="ref">{{abs.ref_no}}</div>
              <div class="titleOfAbstract">
                <div class="text">{{abs.abstract_title}}</div>
                <div class="titletooltip">{{abs.abstract_title}}</div>
              </div>
              <div class="date">{{abs.submission_date.split(' ')[0]}}<br>{{abs.submission_date.split(' ')[1]}}</div>
              <input type="button" class="abstract" value="Download" @click="downloadAbstract(abs.file_pdf_url)">
              <input type="button" class="oralVideo" value="Upload" style="cursor: default;">
              <input type="button" class="pdf" value="Upload" style="cursor: default;">
              <input type="button" class="delete" @click="checkDelete(abs.abstract_title, abs.abstract_id)">
            </div>
          </template>
        </div>
      </div>
      <!-- submission steps comp -->
    </div>

  </div>
</template>

<script>
export default {
  name: 'AbstractComp',
  data () {
    return {
      abstract_list: []
    }
  },
  methods: {
    newAbstract () {
      location.href = '/#/submit/new'
    },
    checkDelete (name, id, type) {
      let warningText = ''
      if (type === 'S') {
        warningText = 'Are you sure to delete abstract "' + name + '"'
      } else {
        warningText = 'Are you sure to delete abstract "' + name + '"? Please note that all abstracts in this symposium will be deleted.'
      }
      this.$swal.fire({
        icon: 'warning',
        title: 'Delete Abstract',
        text: warningText,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'Cancel',
        focusConfirm: false,
        confirmButtonColor: '#601985',
        showCancelButton: true
        // reversesButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAbstract(id)
        }
      })
    },
    deleteAbstract (id) {
      this.$axios.post(this.$apiUrl + 'submission/del_abstractlist', { abstract_id: id }, {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      }).then((res) => {
        if (res.data.resultCode === '000') {
          this.$popupSuccess('Abstract has been deleted.')
          location.reload()
        } else if (res.data.resultCode === '006') {
          this.$tokenError()
        } else {
          this.$popupError()
        }
      })
    },
    modifyAbstract (ref, id) {
      if (ref[0] === 'S') {
        location.href = '/#/submit/edit_symposium/' + id
      } else {
        location.href = '/#/submit/edit_abstract/' + id
      }
    },
    downloadAbstract (url) {
      window.open(url, '_blank')
    }
  },
  mounted () {
    if (localStorage.getItem('accessToken') !== null) {
      this.$axios.get(this.$apiUrl + 'submission/abstractlist', {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      })
        .then((res) => {
          if (res.data.resultCode === '000') {
            this.abstract_list = res.data.data
            // console.log(res.data.data)
          } else if (res.data.resultCode === '006') {
            this.$tokenError()
          } else {
            location.reload()
          }
        })
    } else {
      this.$popupHint('Please login your account before submitting abstracts.')
      location.href = '/#/login'
    }
  }
}
</script>
