<template>
  <div class="page-holder">
    <div class="page-content glance">
      <div class="title" style="margin-bottom:16px;">Program at a Glance (Tentative)</div>
      <div style="color:rgb(95 25 133); font-size:20px;">Toward Multi-Disciplinary Collabotation in Neuropsychology </div>
      <div class="glance__subtitle">6th July, 2023 (Thursday)</div>
      <div class="glance__schedule">
        <div class="col-head">Time</div>
        <div class="col-head">Lobby</div>
        <div class="col-head">Room 101 D</div>
        <div class="col-head">Room 101</div>
        <div class="col-head">Room 101 A</div>
        <div class="col-head">Room 101 B</div>
        <div class="col-head">Room 101 C</div>
        <div class="col-head">Room 105</div>
        <div class="col-head">Room 202 A</div>
        <div class="row-head">08:30-09:30</div>
        <div style="grid-row: span 10; text-orientation: mixed; writing-mode: vertical-rl;">Registration & Exhibition / Preview Room (start from 9AM)</div>
        <div class="empty" style="grid-row: span 2; text-orientation: mixed; writing-mode: vertical-rl;"></div>
        <div class="empty" style="grid-row: span 2;"></div>
        <div class="empty" style="grid-row: span 2;"></div>
        <div class="empty" style="grid-row: span 2;"></div>
        <div class="empty" style="grid-row: span 2;"></div>
        <div class="empty" style="grid-row: span 1;"></div>
        <div class="empty" style="grid-row: span 1;"></div>
        <div class="row-head">09:30-11:30</div>
        <div>CE Workshop B: Social Cognition in Pediatric Neuropsychology</div>
        <div>CE Workshop A: The  principles of transcranial direct current stimulation (tDCS) and apply tDCS protocols for clinical treatment </div>
        <!-- <div>CE Workshop B: Social Cognition in Pediatric Neuropsychology</div> -->
        <div class="row-head">11:30-12:00</div>
        <div class="span7">Poster Session 1</div>
        <div class="row-head">12:00-13:30</div>
        <div class="empty" style=""></div>
        <div class="empty" style=""></div>
        <div class="empty" style=""></div>
        <div class="empty" style=""></div>
        <div class="empty" style=""></div>
        <div>Pre-Opening Symposium: Healthy Aging: Brain and Mind Chair: Hsu Wen Huang Presenter:Yun-Hsuan Chang Yu-Ling Chang Wei-Ju Li</div>
        <div>Pre-Opening Symposium: "Neuromodulation" Presenter: Benjamin Hampstead Michael Nitsche Shawn McClintock"</div>
        <!-- <div>Pre-Opening Symposium:  Neuromodulation<br>Dr. Benjamin Hampstead  Dr.Michael Nitsche<br>Dr.Shawn McClintock</div> -->
        <div class="row-head">13:30-14:00</div>
        <div class="span7">Break</div>
        <div class="row-head">14:00-15:00</div>
        <div class="empty" style="grid-row: span 5;"></div>
        <div>Opening Ceremony</div>
        <div class="empty span5" style="grid-row: span 4;"></div>
        <div class="row-head">15:00-15:30</div>
        <div>Coffee Break</div>
        <div class="row-head">15:30-16:30</div>
        <div>Keynote Speech 1.Jon Evans: "Grand Challenges for Global Neuropsychology"</div>
        <div class="row-head">16:30-17:30</div>
        <div>Keynote Speech 2. Masaru Mimura: "Early Detection of MCI and Dementia"</div>
        <div class="row-head">17:30-19:30</div>
        <!-- <div class="empty"></div> -->
        <div class="span6" style="border-right: 1px solid rgba(96, 25, 133, 0.5);">Welcome Reception and Round Table Dinner, V.I.P. Room 4F</div>
        <!-- <div class="empty"></div> -->
      </div>
      <div class="glance__subtitle">7th July, 2023 (Friday)</div>
      <div class="glance__schedule">
        <div class="col-head">Time</div>
        <div class="col-head">Lobby</div>
        <div class="col-head">Room 105</div>
        <div class="col-head">Room 101</div>
        <div class="col-head">Room 101 A</div>
        <div class="col-head">Room 101 B</div>
        <div class="col-head">Room 101 C</div>
        <div class="col-head">Room 101 D</div>
        <div class="col-head">Room 102</div>
        <div class="row-head">08:30-09:00</div>
        <div style="grid-row: span 11; text-orientation: mixed; writing-mode: vertical-rl;">Registration & Exhibition / Preview Room (start from 9AM)</div>
        <div class="empty" style="grid-row: span 11; text-orientation: mixed; writing-mode: vertical-rl;"></div>
        <div class="span6">Poster Session 2</div>
        <div class="row-head">09:00-10:00</div>
        <div>Keynote Speech 3. Akira Midorikawa: "Paradoxical functional facilitation in various brain injuries and diseases"</div>
        <div class="span4 empty"></div>
        <div>Paper Session 1. Cognitive and social function in Pediatric populations</div>
        <div class="row-head">10:00-10:30</div>
        <div class="span6">Coffee Break</div>
        <div class="row-head">10:30-11:30</div>
        <div>Keynote Speech 4. Michael Chee Wei Liang: "Napping: A Contemporary Review of an Age Old Practice"</div>
        <div class="span4 empty" style="grid-row: span 2"></div>
        <div style="grid-row: span 2">Paper Session 2 Traumatic Brain Injury related topics</div>
        <div class="row-head">11:30-12:00</div>
        <div class="empty"></div>
        <div class="row-head">12:00-12:30</div>
        <div class="span6">Poster Session 3</div>
        <div class="row-head">12:30-14:00</div>
        <div class="empty" style="grid-row: span 3"></div>
        <div class="empty"></div>
        <!-- <div>Novel Guiding Concepts in Neuropsychological Rehabilitation Chair: Giles Yeates Discussant: Jon Evans Presenter: Giles Yeates, Christian salas</div> -->
        <div>Multiple Perspectives on Vascular Cognitive Impairment Chair:Yen-Hsuan Hsu Discussant: Yen-Hsuan Hsu Presenter: Min-Chien Tu,  Li-Kai Huang, Yen-Hsuan Hsu, Hsin-Te Chang</div>
        <div class="empty"></div>
        <!-- <div>Exploring the relationship between subjective cognitive complaints, objective cognitive functions and quality of life in patients with fibromyalgia Chair:Chi Cheng Yang Discussant: Yan Ping Keung Presenter: Chi Cheng Yang, Chih Peng Lin, Yan Keung</div> -->
        <div>Smartphones as a window into everyday brain health: applications of keystroke dynamics, ecological momentary assessment, and accelerometry Chair:Michelle Chen Discussant: Alex Leow Presenter: Tammy Chung, Andrea Cladek, Michelle Chen, Alex Leow</div>
        <div>Advent of Clinical Neuropsychological Profession in the Asian Countries.: Japan, Taiwan, Malesia, Indonesia, Macao and Thailand. Moderator: Jonathan Evans. Presenter:  Nai-Wen Guo, Augustina Sulastri, Parisuth Sumransub, Sakamoto Pomeroy Maiko, Vigneswaran Veeramuthu, Lei Seng Hang.</div>
        <div class="row-head">14:00-14:15</div>
        <div class="span5">break</div>
        <div class="row-head">14:15-15:45</div>
        <div>Paper Session 3. Sleep and Cognition related topics</div>
        <div>Paper Session 4. Neuroimaging and Neuromodulation related topics</div>
        <div>Paper Session 5. Intervention and Rehabilitation topics</div>
        <div>Advancing Late-Life Depression Research: Neuropsychological and AI Modelling Studies. Tatia Lee. Charlene Lam. Mengxia Gao Chih-Mao Huang</div>
        <div>Asian Neuropsychology in the U.S.: Current State and Future Development Chair: Daryl Fujii Discussant: Chris Nguyen Presenter: Porrselvi AP, BaoChan Tran, Vigneswaran Veeramuthu, Wen-Yu Cheng</div>
        <div class="row-head">15:45-16:00</div>
        <div class="span6">Coffee Break</div>
        <div class="row-head">16:00-17:30</div>
        <div class="empty" style="grid-row: span 2"></div>
        <div class="empty" style="grid-row: span 2"></div>
        <!-- <div style="grid-row: span 2">CE Workshop C: Bias and equivalence in cross-cultural assessmen Presenter: Alberto Luis Fernández</div> -->
        <div style="grid-row: span 2">CE Workshop D: Exploring the Past, Present, and Future of Cerebrovascular Disease & Cognitive Aging Presenter: Melissa Lamar</div>
        <div>Paper Session 6. Language and Visual Function</div>
        <div style="grid-row: span 2">Student's panel 1  Neuropsychology Training  Pathways, Tips, and Tricks Panelists: Skye McDonald (Australia), Cady Block (US), Christopher Benjamin (US), Maiko Sakamoto (Japan)</div>
        <div>Multicultural Neuropsychological Approaches to Test Development, Adaptation, and Data Collection. Chair: Jonathan Evans Discussant: Chris Nguyen Presenter: Chris Nguyen, Jon Evans, Porrselvi A.P., Truc Tran Thanh Nguyen,Bob Bilder </div>
        <div class="row-head">17:30-18:00</div>
        <div class="empty"></div>
        <div class="empty"></div>
        <div class="empty"></div>
        <div class="empty"></div>
        <div class="row-head">18:00~</div>
        <div class="span8">Night Market Tour</div>
      </div>
      <div class="glance__subtitle">8th July, 2023 (Saturday)</div>
      <div class="glance__schedule">
        <div class="col-head">Time</div>
        <div class="col-head">Lobby</div>
        <div class="col-head">Room 105</div>
        <div class="col-head">Room 101</div>
        <div class="col-head">Room 101 A</div>
        <div class="col-head">Room 101 B</div>
        <div class="col-head">Room 101 C</div>
        <div class="col-head">Room 101 D</div>
        <div class="col-head">Room 102</div>
        <div class="row-head">08:30-09:00</div>
        <div class="empty"></div>
        <div class="empty" style="grid-row: span 11;"></div>
        <div class="span5">Poster Session 4</div>
        <!-- <div class="empty" style="grid-row: span 4;"></div> -->
        <div class="empty"></div>
        <div class="row-head">09:00-10:30</div>
        <div style="grid-row: span 3;text-orientation: mixed; writing-mode: vertical-rl;">Exhibition / Preview Room</div>
        <!-- <div style="text-orientation: mixed; writing-mode: vertical-rl;">Student's panel / workshop</div> -->
        <div class="empty"></div>
        <div>Invited Symposium 1: Pediatric Brain Tumor Presenter: Stephen A. Sands, Wan Ru Huang</div>
        <div>Invited Symposium 2: Awake craniectomy Dr. Chi-Cheng Yang Dr. Vigneswaran Veeramuthu, Dr. Ko-Ting Chen</div>
        <div>Paper Session 7. Test Development and Adaptation</div>
        <div>Paper Session 8. Social Cognition related topics</div>
        <div>Student's panel 2  Rehabilitation Across the Lifespan Panelists: Prof Jonathan Evans (Scotland), Prof Vicki Anderson (Australia), Dr Rumi Tanemura (Japan), Prof Akira Midorikawa (Japan)</div>
        <div class="row-head">10:30-10:45</div>
        <!-- <div class="empty" style="grid-row: span 7;text-orientation: mixed; writing-mode: vertical-rl;"></div> -->
        <div class="span6">Coffee Break</div>
        <div class="row-head">10:45-12:15</div>
        <div class="empty"></div>
        <div>Invited Symposium 3: The Application of Clinical Neuropsychology in Epilepsy Medicine, Dr. David W. Loring, Dr. Christopher Benjamin, Dr. Hsiang-Yu Yu, Dr. Wei-Han Wang</div>
        <div>Invited Symposium 4.Behavioral Neurology and Clinical Neuropsychology Presenter:Yi-Chien Liu, Ming-Chyi Pai, Yu-Wen Cheng, Kai-Ming Jhang, Jung Lung Hsu, Yi-Chun Kuan</div>
        <div>Paper Session 9. Aging and Alzheimer’s disease related topics</div>
        <div>Paper Session 10. Multicultural and diversity topics</div>
        <div>Novel Guiding Concepts in Neuropsychological Rehabilitation. Chair: Giles Yeates Discussant: Jonathan Evans Presenter: Giles Yeates, Christian salas</div>
        <div class="row-head">12:45-13:30</div>
        <div class="empty" style="grid-row: span 7;"></div>
        <div class="span6">Break</div>
        <div class="row-head">13:30-14:30</div>
        <div>Keynote Speech 5. Rumi Tanemura: "Friendly assistive technology for patients with cognitive impairments"</div>
        <div class="empty span5" style="grid-row: span 6;"></div>
        <div class="row-head">14:30-15:30</div>
        <div>Keynote Speech 6. Vicki Anderson: "Clinical Research in Child Brain Injury: Generating,Knowledge, Implementing Evidence, Improving Outcomes"</div>
        <div class="row-head">15:30-15:40</div>
        <div>break</div>
        <div class="row-head">15:40-17:00</div>
        <div>Closing & Award Ceremony</div>
        <div class="row-head">17:00-17:20</div>
        <div class="">break</div>
        <div class="row-head">17:00-19:00</div>
        <!-- <div class="empty"></div> -->
        <div>Gala Party</div>
      </div>
      <router-link class="glance__subtitle" to="/program/social">9th July, 2023 (Sunday): Special Event!</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramComp'
}
</script>
