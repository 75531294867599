<template>
  <div class="page-holder">
    <div class="page-content aboutInfo welcome-address">
        <div class="welcome-address-title">Welcome Address</div>
        <div class="welcome-address-name">2023 INS Taiwan Meeting Program Committee:</div>
        <div class="welcome-address-sub">Toward Multi-Disciplinary Collaborations in Neuropsychology</div>
        <div class="welcome-address-wrapper">
            <img class="welcome-address-profile" src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/welcome-jon.jpg">
            <div class="welcome-address-text">
                I am delighted to welcome you to the International Neuropsychological Society (INS) 2023 meeting in Taipei, Taiwan, held in association with the Taiwan Association of Neuropsychological Development and Mental Rehabilitation (TANDMR). <br>
                This meeting represents a landmark for INS as it is our first ever meeting in East Asia. The INS is committed to supporting the development of neuropsychological science and practice across the world and this meeting provides us with a wonderful opportunity to showcase excellent neuropsychological research from all parts of Asia, as well as from the rest of the world. <br>
                I would like to congratulate our Program Co-Chairs, Professor Nai-Wen Guo and Dr Maiko Sakamoto, and all members of their local organising team, for putting together a wonderful programme of keynote speakers, symposia, free paper sessions, and continuing education workshops. In addition we have a wonderful social program to look forward to!<br>
                The motto of the INS is ‘Where the World Meets’ and this will definitely be the case here in Taipei, where you will have the opportunity to meet and discuss neuropsychology with colleagues from at least 24 countries. <br>
                The last few years have been challenging for all of us. In many ways the pandemic brought us closer with our shared experience of coping with Covid and remote meetings that have allowed us to meet virtually throughout the periods where travel was not possible. But there is nothing like meeting in person, and here in Taipei you will receive a very warm Taiwanese welcome in the city known for its friendliness and wonderful mix of cultural influences. <br>
                Have a wonderful conference - I look forward to meeting you!<br>
                <br>
                <b>Jon Evans, PhD.</b><br>
                <span class="gray">INS President</span>
            </div>
        </div>
        <div class="welcome-address-wrapper">
            <img class="welcome-address-profile" src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/welcome-beach.jpg">
            <div class="welcome-address-text">
                Honorable INS faculty, distinguished speakers, dearest members and friends:<br>
                It is my great honor and privilege to host this great conference of INS and invite everyone to come together at Taiwan, so that more Asian patients and professionals can participate and consequently be more familiar with the perspective of neuropsychology. Through this meeting, I believe, more people will get substantial benefits due to the advent of neuropsychology and not surprisingly, this meeting will have a strong impact at the Asian region, especially Taiwan. <br>
                We all believe that mind and brain health is a fact, a real event, and surely true enough. Through the study of neuropsychology, we know more about the mental health based on the special brain-driven systems. Moreover, through the monitoring of emotions, cognition, and behavior, the happy brain produces a more complete sense of self that we should always be grateful. Personally, I had been both attracted and overwhelmed by the knowledge of neuropsychology and the therapeutic techniques developed from it over the past 4 decades. After 18 years of my career in rehabilitation field as a clinical psychologist, Taiwan started Psychologist Act and National Board Examination. After another 18 years, I established Taiwan Association of Neuropsychological Development and Mental Rehabilitation, TANDMR) to verify the specialty of clinical neuropsychologists. And in 2024, I am reaching a milestone of 40 years as a clinical neuropsychologist to serve in clinical healthcare and, consequently, time for retirement. <br>
                Passing the four years of fighting with covid-19 and when the world is gradually returning to order and peace, stability and trust, it is a great honor to learn that fellow neuropsychologists from 24 counties have come to Taiwan to attend the 2023 INS Taiwan meeting, a real event for us to communicate and share in person. For this particular reason, I would like to express my most sincere gratitude.<br>
                <br>
                <b>Nai-Wen Guo, Ph.D, Professor</b><br>
                <span class="gray">Program Co-Chair, INS 2023 Taiwan Meeting</span><br>
                <span class="gray">President, Taiwan Association of Neuropsychological Development and Mental Rehabilitation</span><br>
                <span class="gray">Professor, Institute of Behavioral Medicine,National Cheng Kung University</span>
            </div>
        </div>

        <div class="welcome-address-wrapper">
            <img class="welcome-address-profile" src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/welcome-sakamoto.jpg">
            <div class="welcome-address-text">
                On behalf of the INS Taiwan Program Committee, I am delighted to extend a warm welcome to the esteemed participants of the Mid-Year Meeting of the International Neuropsychological Society in Taipei, scheduled for July 6 – 8, 2023. It is my utmost privilege to serve as a program co-chair for our inaugural meeting in East Asia, with a sincere commitment to fostering the growth and advancement of the field of neuropsychology in Asian countries.<br>
                Undoubtedly, Asian countries, like many others, have been significantly impacted by the global COVID-19 pandemic, which has impeded our daily activities. Nevertheless, this challenging period has also presented us with an opportunity to explore new avenues of communication and collaboration, enabling seamless interaction with colleagues across the globe. Consequently, our professional bonds have grown stronger, laying a robust foundation of mutual support.<br>
                This gathering bears a vital purpose of fostering camaraderie and knowledge sharing. It is important to acknowledge that the development of neuropsychology varies across Asian countries, each with its unique trajectory and processes. For instance, Japan recently conducted its first clinical neuropsychologist examination in 2022, certifying a total of 401 clinical neuropsychologists. Hence, recognizing the distinct histories and advancements in our respective nations, we are passionately committed to building bridges that facilitate the exchange of research, clinical practices, and educational experiences among peers within Asia and worldwide.<br>
                I take great pleasure in extending invitations to distinguished researchers and clinicians of international acclaim, who will grace our event as keynote speakers, invited symposium speakers, and CE session speakers. Their contributions will undoubtedly enrich our collective knowledge through their advanced research findings and clinical expertise. Gratitude is due to Dr. Benjamin M. Hampstead, CE Chair, for orchestrating cutting-edge workshops, as well as to Dr. Marc A. Norman, the INS Executive Director, Dr. Jonathan Evans, the President, Chantal Marcks, and Matra Robinet at the INS Office, for their invaluable support in bringing this conference to fruition. I would also like to express my sincere appreciation to the INS Taiwan Team, particularly Drs. Yu-Ling Chang and Ya-Wen Jang, for their close collaboration with Dr. Guo and myself.<br>
                With great anticipation, we eagerly await your presence at this conference. May it provide a platform for reconnecting with old acquaintances, forging new relationships, and cultivating networks for future endeavors.   <br>
                <br>
                <b>Maiko Sakamoto, Ph.D.</b><br>
                <span class="gray">Program Co-Chair, INS 2023 Taiwan Meeting</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutWelcomeComp'
}
</script>
