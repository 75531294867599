<template>
  <div>
    <div class="page-holder">
        <div class="page-content account">
          <div class="account__title title">Personal Information</div>
          <div class="subtitle ">Logout</div>
          <input type="button" value="Log Out" @click="logout()" style="margin: 20px; background-color: #601985; color: white; border:transparent;">
          <div class="subtitle ">Instructions</div>
          <div class="account__rows wrapper">
              <div class="row wrapper">
                  <div class="row__title">REGISTRAION</div>
                  <router-link to="/registration/"><input type="button" class="btn" value="Register Now"></router-link>
              </div>
              <div class="row wrapper">
                  <div class="row__title">EXTENSION TOUR</div>
                  <router-link to="/registration/trip"><input type="button" class="btn" value="Join Now"></router-link>
              </div>
              <div class="row wrapper">
                  <div class="row__title">RECEIPT</div>
                  <router-link to="/registration/receipt"><input type="button" class="btn" value="Check Orders"></router-link>
              </div>
              <div class="row wrapper">
                  <div class="row__title">ABSTRACT</div>
                  <router-link to="/abstract"><input type="button" class="btn" value="Check Submission"></router-link>
              </div>
          </div>
          <div class="account__hint">
              1. Click "Edit" to review your personal information.<br>
              2. Click "Change Password" to change your password.
          </div>
          <form class="signup__form">
            <label for="" class="item required">Nationality</label>
            <div class="inputwrap">
              <select style="background-color: #f2f2f2;" disabled="disabled">
                <option>{{user.nationality}}</option>
              </select>
            </div>
            <label for="" class="item required">Title</label>
            <div class="inputwrap inputwrap__radio">
              <div class="inputwrap">
                <input class="editable" type="radio" v-model="user.title" id="dr" name="dr" value="1" >
                <label for="dr">Dr.</label>
              </div>
              <div class="inputwrap">
                <input class="editable" type="radio" v-model="user.title" id="prof" name="prof" value="2" >
                <label for="prof">Prof.</label></div>
              <div class="inputwrap">
                <input class="editable" type="radio" v-model="user.title" id="associate" name="associate" value="3" >
                <label for="associate">Associate Prof.</label></div>
              <div class="inputwrap">
                <input class="editable" type="radio" v-model="user.title" id="assistant" name="assistant" value="4" >
                <label for="assistant">Assistant Prof.</label><br></div>
              <div class="inputwrap">
                <input class="editable" type="radio" v-model="user.title" id="mr" name="mr" value="5" >
                <label for="mr">Mr.</label></div>
              <div class="inputwrap">
                <input class="editable" type="radio" v-model="user.title" id="mrs" name="mrs" value="6" >
                <label for="mr">Mrs.</label></div>
              <div class="inputwrap">
                <input class="editable" type="radio" v-model="user.title" id="ms" name="ms" value="7" >
                <label for="mr">Ms.</label></div>
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">First (Given) Name</label>
            <div class="inputwrap">
              <input class="editable" type="text" v-model="user.first_name">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item">Middle Name</label>
            <div class="inputwrap">
              <input  class="editable" type="text" v-model="user.middle_name">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Last (Family) Name</label>
            <div class="inputwrap">
              <input  class="editable" type="text" v-model="user.last_name">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required" v-show="is_Chinese" id="cnLabel">中文名字</label>
            <div class="inputwrap" v-show="is_Chinese">
              <input  class="editable" type="text">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Gender</label>
            <div class="inputwrap inputwrap__radio">
              <input  class="editable" type="radio" v-model="user.gender" id="male" name="male" value="1" >
              <label for="male">Male</label>
              <input  class="editable" type="radio" v-model="user.gender" id="female" name="female" value="2" >
              <label for="female">Female</label>
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Date of Birth</label>
            <div class="inputwrap">
              <input  class="editable" type="date" v-model="user.birth_date">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Job Position</label>
            <div class="inputwrap">
              <input  class="editable" type="text" placeholder="Please enter your Job Title." v-model="user.job_position">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item">Department</label>
            <div class="inputwrap">
              <input  class="editable" type="text" placeholder="Please enter the full name of your department." v-model="user.department">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Affiliation</label>
            <div class="inputwrap">
              <input  class="editable" type="text" placeholder="Please enter the full name of your institution." v-model="user.affiliation">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Country of Affiliation</label>
            <div class="inputwrap">
              <select name="nation" v-model.lazy='user.country_of_affiliation_code'>
                <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
              </select>
            </div>
            <label for="" class="item required">Address</label>
            <div class="inputwrap">
              <input  class="editable" type="text" v-model="user.address">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Tel Number</label>
            <div class="inputwrap inputwrap__phone">
              <input  class="editable" type="text" v-model="user.tel_number">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Mobile</label>
            <div class="inputwrap inputwrap__phone">
              <input  class="editable" type="text" v-model="user.mobile">
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">INS Membership</label>
            <div class="inputwrap inputwrap__radio">
              <input class="editable" type="radio" v-model="user.is_ins_member" id="yes" name="yes" value="1" >
              <label for="yes">Yes, I am INS member</label>
              <hr class="forcewrap">
              <input class="editable" type="radio" v-model="user.is_ins_member" id="no" name="no" value="0" >
              <label for="no">No, I am not INS member</label>
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">Student Status</label>
            <div class="inputwrap inputwrap__radio">
              <input  class="editable" type="radio" v-model="user.student_status" value="1" >
              <label>Does Not Apply</label>
              <hr class="forcewrap">
              <input  class="editable" type="radio" v-model="user.student_status" value="2" >
              <label>Graduate Student</label>
              <hr class="forcewrap">
              <input  class="editable" type="radio" v-model="user.student_status" value="3" >
              <label>Post-Doctoral Fellow</label>
              <div class="hint">hint: error input</div>
            </div>
            <label for="" class="item required">E-mail (Login Account)</label>
            <input type="text" class="copyBlackList" v-model="user.email" readonly="readonly">
            <label class="item required">Password</label>
            <input type="button" style="background-color: #601985; color: white; border: 1px solid transparent;" value="Change" @click="popupChPWD()">
            <div class="btns" v-if="state == 'viewing'">
              <input type="button" value="Edit" @click="changeState()">
            </div>
            <div class="btns" v-else>
              <input type="button" value="cancel" style="background-color: white; border: 1px solid #e4e4e4; color: #333;" @click="cancelChange()">
              <input type="button" value="Summit Change" @click="summitChange()" style="background-color:#601985; color: white;">
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountComp',
  data () {
    return {
      user: {},
      backup_user: {},
      state: 'viewing',
      is_Chinese: false
    }
  },
  methods: {
    debugger () {
      this.user = {
        id: '4',
        first_name: 'aa',
        middle_name: 'bb',
        last_name: 'cc',
        chinese_name: '中文',
        email: 'ccc@mail.com',
        title: '2',
        gender: '1',
        birth_date: '1999-11-11',
        job_position: 'ttt',
        department: 'yyy',
        country_of_affiliation_code: 'TW',
        country_of_affiliation: 'Taiwan',
        address: 'xxx',
        tel_number: '222',
        mobile: '6666',
        is_ins_member: '1',
        student_status: '1',
        nationality: 'Taiwan',
        nationality_code: 'TW',
        is_deleted: '0',
        is_subscribe: null,
        status: '1'
      }
      this.backup_user = {
        id: '4',
        first_name: 'aa',
        middle_name: 'bb',
        last_name: 'cc',
        chinese_name: '中文',
        email: 'ccc@mail.com',
        title: '2',
        gender: '1',
        birth_date: '1999-11-11',
        job_position: 'ttt',
        department: 'yyy',
        country_of_affiliation_code: 'TW',
        country_of_affiliation: 'Taiwan',
        address: 'xxx',
        tel_number: '222',
        mobile: '6666',
        is_ins_member: '1',
        student_status: '1',
        nationality: 'Taiwan',
        nationality_code: 'TW',
        is_deleted: '0',
        is_subscribe: null,
        status: '1'
      }
    },
    popupChPWD () {
      const apiUrlChPWD = 'members/updPassword'
      this.$swal.fire({
        html: `
        <div class="popup">
          <div class="title" >Change Your Password</div>
          <div class="wrapper flex-column popup__chpwd">
            <div class="grid">
              <label for="">Current Password</label>
              <input type="password" id="updCurPWD">
            </div>
            <div class="grid">
              <label for="">New Password</label>
              <input type="password" id="updNewPWD">
              <hr class="forcewrap">
            </div>
            <div class="grid prohibit">
              <label for="">Confirm Password</label>
              <input type="password" id="updNewConf" class="copyBlackList">
            </div>
          </div>
        </div>
        `,
        focusConfirm: false,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Change Password',
        cancelButtonText: 'Cancel',
        width: '550px',
        padding: '20px 12px 30px',
        customClass: {
          confirmButton: 'popup__btn ',
          cancelButton: 'popup__btn popup__btn--cancel'
        },
        didOpen: () => {
          const stopCopyPaste = document.getElementsByClassName('copyBlackList')
          Array.prototype.forEach.call(stopCopyPaste, function (el) {
            el.onpaste = e => e.preventDefault()
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const user = {
            cur: document.getElementById('updCurPWD').value,
            new: document.getElementById('updNewPWD').value,
            conf: document.getElementById('updNewConf').value
          }
          if (user.cur !== '' && user.new !== '' && user.conf !== '') {
            this.$axios.post(this.$apiUrl + apiUrlChPWD, {
              current_password: user.cur,
              password: user.new,
              passconf: user.conf
            }, { headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') } })
              .then((res) => {
                if (res.data.resultCode === '000') {
                  this.$popupSuccess('Your password has been changed! Please login again!')
                  this.logout()
                } else if (res.data.resultCode === '006') {
                  this.$tokenError()
                } else {
                  this.$popupError()
                }
              })
          } else {
            this.$popupError('Please check your password again.')
          }
        }
      })
    },
    changeState () {
      if (this.state === 'viewing') {
        this.state = 'editing'
      } else {
        this.state = 'viewing'
      }
    },
    editState () {
      const editableElements = document.getElementsByClassName('editable')
      Array.prototype.forEach.call(editableElements, function (el) {
        if (el.type === 'radio') {
          el.removeAttribute('onclick')
        } else {
          el.removeAttribute('readonly')
          el.style.backgroundColor = 'white'
        }
      })
    },
    viewState () {
      const editableElements = document.getElementsByClassName('editable')
      Array.prototype.forEach.call(editableElements, function (el) {
        if (el.type === 'radio') {
          el.setAttribute('onclick', 'return false;')
        } else {
          el.setAttribute('readonly', true)
          el.style.backgroundColor = '#f2f2f2'
        }
      })
    },
    cancelChange () {
      this.changeState()
      this.user = this.backup_user
    },
    summitChange () {
      // 要先檢查
      this.$axios.post(this.$apiUrl + 'members/updMember', {
        first_name: this.user.first_name,
        middle_name: this.user.middle_name,
        last_name: this.user.last_name,
        chinese_name: this.user.chinese_name,
        title: this.user.title,
        gender: this.user.gender,
        birth_date: this.user.birth_date,
        affiliation: this.user.affiliation,
        job_position: this.user.job_position,
        department: this.user.department,
        country_of_affiliation_code: this.user.country_of_affiliation_code,
        address: this.user.address,
        tel_number: this.user.tel_number,
        mobile: this.user.mobile,
        is_ins_member: this.user.is_ins_member,
        student_status: this.user.student_status,
        nationality_code: this.user.nationality_code
      }, { headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') } })
        .then((res) => {
          if (res.data.resultCode === '000') {
            this.$popupSuccess('Your personal information has been changed.')
            location.reload()
          } else if (res.data.resultCode === '006') {
            this.$tokenError()
          } else {
            this.$popupError()
          }
        })
    },
    logout () {
      localStorage.removeItem('accessToken')
      localStorage.setItem('memberId', 0)
      location.href = '/#/login'
    }
  },
  mounted () {
    this.viewState()
    const self = this
    if (localStorage.getItem('accessToken') !== null) {
      this.$axios.get(this.$apiUrl + 'members/info', {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      })
        .then((res) => {
          if (res.data.resultCode === '000') {
            self.user = res.data.data
            self.backup_user = res.data.data
          } else if (res.data.resultCode === '006') {
            this.$tokenError()
          } else {
            this.$popupError()
          }
        })
    } else {
      location.href = '/#/login'
    }
  },
  watch: {
    state: {
      handler: function (newVal) {
        if (newVal === 'editing') {
          this.editState()
        } else if (newVal === 'viewing') {
          this.viewState()
        }
      }
    },
    'user.nationality_code': {
      handler: function (newVal) {
        const mandarin = ['TW', 'CN', 'HK', 'MO']
        if (mandarin.includes(newVal)) {
          this.is_Chinese = true
          if (['HK', 'MO'].includes(newVal)) {
            document.getElementById('cnLabel').classList.remove('required')
          }
        }
      }
    }
  }
}
</script>
