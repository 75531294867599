<template>
  <div class="page-holder summit-holder">
    <router-view/>
    <!-- 直接轉到new吧 -->
  </div>
</template>

<script>
export default {
  name: 'SubmitComp',
  components: {
  },
  data () {
    return {
      member: {}
    }
  },
  methods: {
    getMemberInfo () {
      this.$axios.get(this.$apiUrl + 'members/info', {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      })
        .then((res) => {
          if (res.data.resultCode === '000') {
            this.member = res.data.data
          } else if (res.data.resultCode === '006') {
            this.$tokenError()
          } else {
            this.$popupError()
          }
        })
    }
  },
  mounted () {
    this.getMemberInfo()
    // location.href = '/#/submit/new'
    // 要怎麼鎖起來這一頁啊還是要用query
  }
}
</script>
