<template>
  <div class="page-holder">
    <div class="page-content signup" v-show="stage != 'last'">
      <div class="signup__title title" style="margin-bottom: 20px">Sign Up to INS 2023 Taiwan Meeting Online System</div>
      <div class="signup__subtitle subtitle" v-show="stage =='signup'">INS 2023 Taiwan Meeting Online System</div>
      <div class="signup__text text" v-show="stage =='signup'">The Organizing Committee cordially invites you to participate INS 2023 Taiwan Meeting. <br><br> To receive the most updated information on the INS 2023 Taiwan Meeting and/or make the abstract submission and register for the INS 2023 Taiwan Meeting, please sign up for an account by completing the information below.</div>
      <div class="signup__subtitle subtitle" v-show="stage =='signup'">Sign Up to INS 2023 Taiwan Meeting Online System</div>
      <div class="signup__hint" v-show="stage =='signup'"><span>*</span> = required field (Please complete the request fields in English)</div>
      <div class="signup__subtitle subtitle mb20" v-show="stage =='preview'">Personal Information Preview</div>
      <form class="signup__form" id="signUpForm">
          <label class="item required">Nationality</label>
          <div class="inputwrap">
            <select name="nation" v-model.lazy='user.nationality_code' @change="changeSelectNation()">
              <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
            </select>
          </div>
          <label class="item required">Title</label>
          <div class="inputwrap inputwrap__radio">
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview" v-model.lazy="user.title" value="1" required>
              <label>Dr.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview" v-model.lazy="user.title" value="2" >
              <label>Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview" v-model.lazy="user.title" value="3" >
              <label>Associate Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview" v-model.lazy="user.title" value="4" >
              <label>Assistant Prof.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview" v-model.lazy="user.title" value="5" >
              <label>Mr.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview" v-model.lazy="user.title" value="6" >
              <label>Mrs.</label>
            </div>
            <div class="inputwrap">
              <input type="radio" name="radio_title" class="preview" v-model.lazy="user.title" value="7" >
              <label>Ms.</label>
            </div>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">First (Given) Name</label>
          <div class="inputwrap">
            <input type="text" id="input-first_name" class="preview" v-model.lazy="user.first_name" required>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item">Middle Name</label>
          <div class="inputwrap">
            <input type="text" class="preview" v-model.lazy="user.middle_name">
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Last (Family) Name</label>
          <div class="inputwrap">
            <input type="text" id="input-last_name" class="preview" v-model.lazy="user.last_name" required>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required" v-show="is_Chinese">中文名字</label>
          <div class="inputwrap" v-show="is_Chinese">
            <input type="text" id="input-chinese_name" class="preview" v-model.lazy="user.chinese_name">
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Gender</label>
          <div class="inputwrap inputwrap__radio">
            <input name="radio_gender" type="radio" value="1" class="preview" v-model.lazy="user.gender" required>
            <label for="male">Male</label>
            <input name="radio_gender" type="radio" value="2" class="preview" v-model.lazy="user.gender">
            <label for="female">Female</label>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Date of Birth</label>
          <div class="inputwrap">
            <input type="date"  class="preview" v-model.lazy="user.birth_date" required>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Job Position</label>
          <div class="inputwrap">
            <input type="text" id="input-job_position" placeholder="Please enter your Job Title."  class="preview" v-model.lazy="user.job_position" required>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item">Department</label>
          <div class="inputwrap">
            <input type="text" placeholder="Please enter the full name of your department." class="preview" v-model.lazy="user.department">
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Affiliation</label>
          <div class="inputwrap">
            <input type="text" id="input-affiliation" placeholder="Please enter the full name of your institution." class="preview" v-model.lazy="user.affiliation" required>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Country of Affiliation</label>
          <div class="inputwrap">
            <div class="inputwrap">
              <select name="nation" v-model.lazy='user.country_of_affiliation_code'>
                <option v-for="item in $countryList" :key="item.code" :value="item.code">{{item.name}}</option>
              </select>
            </div>
          </div>
          <label class="item required">Address</label>
          <div class="inputwrap">
            <input type="text" id="input-address" class="preview" v-model.lazy="user.address" required>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Tel Number</label>
          <div class="inputwrap inputwrap__phone">
            <input type="text" id="input-tel_phone" class="preview" v-model.lazy="user.tel_number" required>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Mobile</label>
          <div class="inputwrap inputwrap__phone">
            <input type="text" id="input-mobile" class="preview" v-model.lazy="user.mobile" required>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">INS Membership</label>
          <div class="inputwrap inputwrap__radio">
            <input type="radio" name="radio_isMember" value="1" class="preview" v-model.lazy="user.is_ins_member" required>
            <label for="yes">Yes, I am INS member</label>
            <hr class="forcewrap">
            <input type="radio" name="radio_isMember" value="0" class="preview" v-model.lazy="user.is_ins_member">
            <label for="no">No, I am not INS member</label>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Student Status</label>
          <div class="inputwrap inputwrap__radio">
            <input type="radio" name="radio_student" class="preview" v-model.lazy="user.student_status" value="1" required>
            <label>Does Not Apply</label>
            <hr class="forcewrap">
            <input type="radio" name="radio_student" class="preview" v-model.lazy="user.student_status" value="2" >
            <label>Graduate Student</label>
            <hr class="forcewrap">
            <input type="radio" name="radio_student" class="preview" v-model.lazy="user.student_status" value="3" >
            <label>Post-Doctoral Fellow</label>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Email (Login Account)</label>
          <div class="inputwrap inputwrap__email">
            <input type="email" id="input-email" class="preview" v-model.lazy="user.email">
            <div class="hint">hint: error input</div>
            <div class="description">This email will be used as your account for future login.<br>Please make sure your Email account is valid and frequently checked, we will contact you through this Email address.</div>
            <div class="description"><i>Please allow emails from us in your email spam filter, or make sure that “ins2023tw@gmail.com” is on your "Approved Sender List” or "White List" and/or in your "Address Book." Please enter your E-mail.</i></div>
          </div>
          <label class="item required">Confirm E-mail</label>
          <div class="inputwrap inputwrap__confirm" >
            <input type="text" id="input-emailconf" class="copyBlackList preview" v-model.lazy="user.emailconf">
            <div class="caution">Prohibit copy and paste.</div>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Password</label>
          <div class="inputwrap inputwrap__confirm">
            <input type="password" id="input-password" class="copyBlackList preview" v-model.lazy="user.password">
            <div class="caution">Please enter a combination of 6 -12 numbers or characters.</div>
            <div class="hint">hint: error input</div>
          </div>
          <label class="item required">Confirm Password</label>
          <div class="inputwrap inputwrap__confirm">
            <input type="password" id="input-passconf" class="copyBlackList preview" v-model.lazy="user.passconf">
            <div class="caution">Prohibit copy and paste.</div>
            <div class="hint">hint: error input</div>
          </div>
          <div class="btns">
            <input type="button" value="Modify" @click="toSignup()" v-show=" stage =='preview'">
            <input type="button" value="Next" @click="isFilled()" v-show=" stage =='signup'">
            <input type="button" value="Confirm" @click="toLast()" v-show=" stage =='preview'">
          </div>
      </form>
    </div>
    <div class="page-content signup"  v-show="stage == 'last'">
      <div class="signup__title title" style="margin-bottom: 20px">Personal Information</div>
      <div class="signup__subtitle subtitle">One More Step!</div>
      <div class="signup__text text">
        <p>The system will send you a verification e-mail to the e-mail address you provided in the previous step within 24 hours. So, please check your e-mail and activate your account with INS 2023 Taiwan Meeting Online System!</p>
        <p>If you did not receive the verification e-mail within the past 24 hours, don't hesitate to contact the INS 2023 Taiwan Meeting Registration Office Secretriat.</p>
        <p class="warning">If you use Gmail or Hotmail, our mail may automatically recognize spam under their service. Please add the INS 2023 Taiwan Meeting Registration Office e-mail (ins2023tw@gmail.com) to your safe e-mail list.</p>
        <div>
          Sincerely,<br>
          INS 2023 Taiwan Meeting Registration Office Secretriat<br>
          Shaan Hsieh<br>
          Tel: +886-2-2798-8329 *13<br>
          Fax: +886-2-2798-6225<br>
          Email: <span class="link">ins2023tw@gmail.com</span><br>
          Website: <span class="link">https://www.instaiwan2023.org</span>
        </div>
      </div>
      <input type="button" value="Done" class="signup__btn signup__btn--done" style="align-self: center;" @click="done()">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignupComp',
  data () {
    return {
      user: {
        first_name: '',
        middle_name: '',
        last_name: '',
        chinese_name: '',
        email: '',
        emailconf: '',
        password: '',
        passconf: '',
        title: '',
        gender: '',
        birth_date: '',
        job_position: '',
        affiliation: '',
        department: '',
        country_of_affiliation_code: '',
        address: '',
        tel_number: '',
        mobile: '',
        is_ins_member: '',
        student_status: '',
        nationality_code: ''
      },
      stage: 'signup',
      is_Chinese: false,
      preview: {}
    }
  },
  methods: {
    changeSelectNation () {
      const mandarin = ['TW', 'CN', 'HK', 'MO']
      if (mandarin.includes(this.user.nationality_code)) {
        this.is_Chinese = true
        document.getElementById('input-chinese_name').classList.add('required')
        if (['HK', 'MO'].includes(this.user.nationality_code)) {
          document.getElementById('input-chinese_name').classList.remove('required')
        }
      } else {
        this.is_Chinese = false
      }
    },
    changeSelectAffiliationNation (val) {
      this.user.country_of_affiliation_code = val.code
      this.preview.affiliation = val.name
    },
    toSignup () {
      this.stage = 'signup'
      const previewInput = document.getElementsByClassName('preview')
      Array.prototype.forEach.call(previewInput, function (el) {
        if (el.type === 'radio') {
          el.removeAttribute('onclick')
        } else {
          el.removeAttribute('readonly')
          el.style.backgroundColor = 'white'
        }
      })
    },
    toPreview () {
      this.stage = 'preview'
      const errorInput = document.getElementsByClassName('error')
      Array.prototype.forEach.call(errorInput, function (el) {
        el.classList.remove('error')
      })
      const previewInput = document.getElementsByClassName('preview')
      Array.prototype.forEach.call(previewInput, function (el) {
        if (el.type === 'radio') {
          el.setAttribute('onclick', 'return false;')
        } else {
          el.setAttribute('readonly', true)
          el.style.backgroundColor = '#f2f2f2'
        }
      })
    },
    toLast () {
      let errorList = []
      this.$axios.post(this.$apiUrl + 'members/register', {
        first_name: this.user.first_name,
        middle_name: this.user.middle_name,
        last_name: this.user.last_name,
        chinese_name: this.user.chinese_name,
        email: this.user.email,
        emailconf: this.user.emailconf,
        password: this.user.password,
        passconf: this.user.passconf,
        title: this.user.title,
        gender: this.user.gender,
        birth_date: this.user.birth_date,
        job_position: this.user.job_position,
        affiliation: this.user.affiliation,
        department: this.user.department,
        country_of_affiliation_code: this.user.country_of_affiliation_code,
        address: this.user.address,
        tel_number: this.user.tel_number,
        mobile: this.user.mobile,
        is_ins_member: this.user.is_ins_member,
        student_status: this.user.student_status,
        nationality_code: this.user.nationality_code
      }, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.resultCode === '009') {
            errorList = res.data.resultMsg_key
            this.$swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Please check your infomation again',
              confirmButtonColor: '#601985',
              willOpen: () => {
                this.toSignup()
                errorList.forEach((el) => {
                  if (el === 'email' || el === 'emailconf') {
                    document.getElementById('input-email').classList.add('error')
                    document.getElementById('input-emailconf').classList.add('error')
                  } else if (el === 'password' || el === 'passconf') {
                    document.getElementById('input-password').classList.add('error')
                    document.getElementById('input-passcong').classList.add('error')
                  } else {
                    document.getElementById('input-' + el).classList.add('error')
                  }
                })
              }
            })
          } else if (res.data.resultCode === '000') {
            this.stage = 'last'
          } else {
            this.$popupError()
          }
        })
    },
    done () {
      location.href = '/#/login'
    },
    isFilled () {
      if (this.user.email !== this.user.emailconf || !this.$checkEmail(this.user.email)) {
        this.$popupError('Please check your email again.')
        return true
      } else if (this.user.password !== this.user.passconf || !/^([a-zA-Z0-9]{6,12})$/.test(this.user.password)) {
        this.$popupError('Please check your password again.')
        return true
      } else if (!document.getElementById('signUpForm').reportValidity()) {
        document.getElementById('signUpForm').classList.add('signup__form-submitted')
        this.$popupError('Please fill in all columns.')
        return true
      } else {
        this.toPreview()
      }
    },
    checkInputStyle () {
      const previewInput = document.getElementsByClassName('preview')
      Array.prototype.forEach.call(previewInput, function (el) {
        if (el.value === '') {
          el.classList.add('error')
        }
      })
    }
  },
  mounted () {
    const stopCopyPaste = document.getElementsByClassName('copyBlackList')
    Array.prototype.forEach.call(stopCopyPaste, function (el) {
      el.onpaste = e => e.preventDefault()
    })
    // prohibit copy paste on confirm column
  }
}
</script>
