<template>
  <div id="app">
    <nav-bar/>
    <router-view/>
    <footer-comp/>
  </div>
</template>

<script>
import NavBar from './components/Navbar.vue'
import FooterComp from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    FooterComp
  }
}

</script>

<style lang="scss">
// @import '@/assets/scss/main.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
