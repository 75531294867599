<template>
  <div class="page-holder">
    <div class="page-content guideline">
        <div class="title mb20">INS 2023 Taiwan Meeting<br>Abstract Submission Guideline</div>
        <div class="guideline__note">
          <div><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-tittle-date.png" style="margin-right:8px;">Important Dates</div>
          <ul class="guideline__tips">
            <li><span>Abstract Submission Opens: </span><span>December 1, 2022</span></li>
            <li><span>Abstract Submission Closes: </span><span>March 24, 2023</span></li>
            <li><span>Notice of Abstract Acceptance: </span><span>April 25, 2023</span></li>
            <li><span>Author Registration Deadline: </span><span>May 25, 2023</span></li>
          </ul>
          <hr>
          <div><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-tittle-process.png" style="margin-right:8px;">Abstract Submission Process</div>
          <div class="guideline__graph">
              <div class="item">
                  <div class="container"><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-submit.png" alt=""></div>
                  <div class="text">Submit Abstracts Website ONLY</div>
              </div>
              <div class="item">
                  <div class="container"><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-recive-abstract.png" alt=""></div>
                  <div class="text">Receive Abstracts Submission Confirmation</div>
              </div>
              <div class="item">
                  <div class="container"><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-review.png" alt=""></div>
                  <div class="text">Review & Modification by March 24th</div>
              </div>
              <div class="item">
                  <div class="container"><img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-acceptance.png" alt=""></div>
                  <div class="text">Receive Acceptance Notice by April 25th</div>
              </div>
          </div>
        </div>
        <div class="guideline__subtitle">General Information</div>
        <ul>
            <li>INS Membership is not required for abstract submission. INS members will receive discounts on meeting registration.</li>
            <li>The abstract submission deadline is March 24, 2023, at 11:59 pm. Any submissions still in draft status at that time will not be considered. No late submissions will be accepted.</li>
            <li>Abstracts must be submitted in clear <span class="warning">ENGLISH.</span></li>
            <li>By submitting, authors commit to be available to present on the date and time they are assigned. Accepted abstracts may be scheduled at any time during the meeting at the discretion of the Program Chair.</li>
            <li>There is no limit on the number of submissions, but authors are asked to use discretion because submitting multiple abstracts is likely to create scheduling problems.</li>
            <li>All submissions must consist of original work and actual data. Abstracts submitted to other meetings or that have been previously published should not be submitted and will not be considered.</li>
            <li>All research, results, and conclusions must be final at the time of submission. Submissions that are promissory in nature will not be considered.</li>
            <li>Submissions will be published exactly as received. Please proofread carefully before finalizing your submission. Once the deadline has passed, Program Committee reserves the right to decline to accommodate any requests for corrections to typos or other entry errors made by the author during submission.</li>
            <li>All submissions will be peer reviewed by the Program Committee. The Program Committee Chairs will make the final decision on all submissions.</li>
            <li>Accepted abstracts must be presented by the presenting author or an officially designated representative. The corresponding author must formally communicate any changes in the presenting author to ins2023tw@gmail.com. Any author who plans to attend the meeting must register and pay the appropriate meeting registration fee.</li>
            <li>The on-line submission also indicates the copyright agreement to publish the abstracts for all purposes of the INS 2023 Taiwan Meeting usage without further notice.</li>
            <li>All accepted abstracts will be published in Electric Abstract Book and Journal of the International Neuropsychological Society (JINS).</li>
            <li>The abstract contains Objective, Participants and Methods, Results, Conclusions, maximum of 3 categories and maximum of 5 keywords. (View category list and keyword list.)</li>
        </ul>
        <div class="guideline__subtitle">Abstract Information</div>
        <ul>
            <li>Words limitation
                <ul>
                    <li>Title - No limitations (First letter of each word should be capitalized except for preposition).</li>
                    <li>Body - maximum of 2,000 characters.</li>
                </ul>
            </li>
            <li>The abstract contains Objective, Participants and Methods, Results, Conclusions, maximum of 3 categories and maximum of 5 keywords. (View <a href="../../Category_List.pdf" target="_blank">category list</a> and <a href="../../Keyword_List.pdf" target="_blank">keyword list</a>.)</li>
        </ul>
        <div class="guideline__subtitle">Presentation Type</div>
        <ul>
            <li>Paper Preferred</li>
            <li>Paper or Poster</li>
            <li>Poster Only</li>
            <li>My abstract is part of a Symposium presentation<br><span style="font-weight:300"> Note: All submissions accepted as posters, papers or symposia will be presented in person. All presenters will be informed of presentation location, day and time once these decisions have been finalized by the INS. There may also be a digital component and additional information and instruction will be sent once decisions are finalized.</span>
                <ol>
                    <li>Poster sessions: Posters have proven to be an effective and popular method for communication of scientific information, providing a more intimate forum for informal discussion than is permitted by regular platform presentations. Poster presenters should stay with their poster for the duration of the poster session (approximately 60-90 minutes) and accepted poster presenters are invited to upload a digital poster for the poster E-Gallery.</li>
                    <li>Paper sessions: Paper sessions are topical oral sessions that are arranged at the discretion of the INS Program Committee Chairs. Four to six outstanding abstracts are typically selected for each paper session. Each abstract is allotted approximately 12-20 minutes (depending on the number of presenters per session) for oral presentation and audience discussion. A moderator is assigned to each session to introduce speakers, help solve any problems, encourage discussion, and keep the session running on time through strict adherence to stated time limits.</li>
                    <li>Symposia sessions: Symposia are topical platform sessions dealing with specific issues in clinical neuropsychology. Symposia are submitted as integrated session proposals (each consisting of an overall proposal and 5-6 participating abstracts) that have been pre-organized by a single symposium chair. Symposium proposals that do not meet the criteria listed below will not be considered.
                        <ul>
                            <li>A complete symposium proposal consists of an overall Symposium Proposal, plus four to five Participating Symposium Abstracts (submitted by the symposium chair or symposium participants).</li>
                            <li>Symposium abstracts should be closely linked and integrated. Symposia are allotted approximately 90 minutes, so each participating abstract should be given approximately 15-20 minutes. Each symposium should include a review led by the symposium organizer or a discussant, as well as a dedicated question and answer period. In the case of a symposium that expresses divergent views on a controversial topic, two longer debate-style talks may also be appropriate.</li>
                        </ul>
                            <div style="font-weight: 600;">The symposium chair is SOLELY responsible for submitting a complete symposium proposal as described.</div>
                    </li>
                </ol>
            </li>
        </ul>
        <div class="guideline__subtitle">Author Information</div>
        <ul>
            <li>Maximally, 10 co-authors can be involved in an abstract. If co-authors' name are not filled in the system, they will not be shown in the final program.</li>
            <li>The Primary Author (first author/presenting author) MUST register INS 2023 Taiwan Meeting and present the abstract(s) in person during the Meeting to ensure his or her accepted abstract(s) are included in the abstract publication and website. Deadline for the registration is <span class="warning">May 25, 2023.</span> Please note that register once only while the presenting author presents multiple abstracts.</li>
            <div class="warning">*Before Submitting, please prepare your abstract strictly according to the Abstract Guideline. If abstracts received do not meet the specification, Scientific Program Committee reserves the right to reject the submission.</div>
        </ul>
        <router-link to="/abstract" class="guideline__btn">Submit Now</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubmissionGuidelineComp'
}
</script>
