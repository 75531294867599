<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">About the INS 2023</div>
        <div class="aboutInfo__title">Conference Name</div>
        <div class="aboutInfo__content">2023 International Neuropsychological Society Taiwan Meeting (INS 2023 Taiwan Meeting)</div>
        <div class="aboutInfo__title">Date</div>
        <div class="aboutInfo__content">Conference Date: July 6-8, 2023<br>Special Event: July 9, 2023</div>
        <div class="aboutInfo__title">Venue</div>
        <div class="aboutInfo__content">
        Taipei International Convention Center<br>
        No. 1, Sec. 5, Xinyi Rd., Xinyi Dist., Taipei City, Taiwan (R.O.C.)<br>
        Official Website: <a href="https://www.ticc.com.tw/main_en/index.aspx">https://www.ticc.com.tw/main_en/index.aspx</a>
        </div>
        <div class="aboutInfo__title">Organizers</div>
        <div class="aboutInfo__content">
        International Neuropsychological Society<br>
        Taiwan Association of Neuropsychological Development and Mental Rehabilitation
        </div>
        <div class="aboutInfo__title">Official Language</div>
        <div class="aboutInfo__content">English</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutConferenceComp'
}
</script>
