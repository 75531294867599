<template>
  <div class="page-holder" ref="albumComp">
    <div class="page-content album">
        <div class="album__location"><a class="root" href="/#/albums">Photos</a> > <div class="ici">{{album_detail.albums_title}}</div></div>
        <div class="album__title">{{album_detail.albums_title}}</div>
        <div class=album__datetime>{{album_detail.albums_create_time}}</div>
        <div class=album__description>{{album_detail.albums_description}}</div>
        <hr>
        <div class="album__field">
          <div class="album__container" v-for="(pic,index) in photos" :key="pic.id" @click="showPhoto(index)">
            <div class="overlay"></div>
            <img class="album__photo" :src="pic.path">
          </div>
        </div>
        <div class="album__float" v-show="playing >= 0">
          <div class="album__floatbtn album__floatbtn--pre" @click="showPhoto(playing-1)" v-show="playing != 0">
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/arrow.png" alt="">
          </div>
          <div class="album__floatbtn album__floatbtn--next" @click="showPhoto(playing+1)" v-show="playing != photos.length-1">
            <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/arrow.png" alt="">
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlbumComp',
  data () {
    return {
      album_api: '/albums/getAlbumsData',
      album_id: -1,
      photos: [],
      album_detail: {},
      playing: -1
    }
  },
  methods: {
    showPhoto (index) {
      const self = this
      if (index >= this.photos.length || index < 0) {
        return true
      }
      this.playing = index
      this.$swal.fire({
        html: `
          <div class="photo__container">
            <div class="photo__left">
              <img class="photo__pop" src="` + self.photos[index].path + `" alt="">
            </div>
            <div class="photo__detail">
              <div class="photo__title">` + self.photos[index].title + `</div>
              <div class="photo__date">` + self.photos[index].create_time + `</div>
              <div class="photo__description">` + self.photos[index].description + `</div>
            </div>
          </div>
        `,
        width: '85vw',
        padding: '1.4em',
        color: '#601985',
        background: '#3C3C3CFF',
        backdrop: 'rgba(0,0,0,0.4)',
        showCloseButton: true,
        focusConfirm: false,
        focusCancel: false,
        showConfirmButton: false,
        customClass: {
          heightAuto: false, // 需要將 heightAuto 設為 false
          popup: 'photo-popup', // 自訂 CSS 類別名稱
          htmlContainer: 'photo-container'
        },
        showClass: {
          popup: 'photo-animation-show'
        },
        hideClass: {
          popup: 'photo-animation-hide'
        },
        willClose: () => {
          self.playing = -1
        }
      })
    }
  },
  mounted () {
    if (localStorage.getItem('accessToken') == null) {
      this.$popupHint('Please login your account before viewing photos.')
      location.href = '/#/login'
    } else {
      console.log(this.$route.params.album_id)
      if (this.$route.params.album_id < 0) {
        location.href = '/#/albums'
      }
      const bodyFormData = new FormData()
      this.album_id = this.$route.params.album_id
      bodyFormData.set('albums_id', this.album_id)
      this.$axios.post(this.$apiUrl + this.album_api, bodyFormData, {
        headers: { 'Content-Type': 'form-data', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      }).then((res) => {
        if (res.data.resultCode === '000') {
          this.album_detail = res.data.data.album
          this.photos = res.data.data.photos
          console.log(res.data.data.album)
          if (res.data.data.album.length === 0) {
            location.href = '/#/albums'
          }
        } else if (res.dataresultCode === '006') {
          this.$tokenError()
        } else {
          this.$popupError()
        }
      })
    }
  }
}
</script>
