<template>
  <div class="page-holder">
    <div class="page-content aboutInfo">
        <div class="title" style="margin-bottom:16px;">About Taiwan</div>
        <div class="aboutInfo__content">Taiwan situated in the heart of Asia Pacific. Many airlines fly to Taiwan making it become the perfect travel destination. Taiwan is a quality convention destination, high-quality human resources, and successful development of the capital and technology-intensive industrial sectors. With natural sceneries, convenient transportation and delicious cuisine, Taiwan will guarantee an enjoyably journey.</div>
        <div class="aboutInfo__title">Language</div>
        <div class="aboutInfo__content">Mandarin is the official language. English is spoken quite extensively.</div>
        <div class="aboutInfo__title">Climate</div>
        <div class="aboutInfo__content">Taiwan enjoys warm weather all year round and is extremely suitable for travelling. The average temperature in July is 30°C. For more weather information, please visit Central Weather Bureau. (https://www.cwb.gov.tw/eng/).</div>
        <div class="aboutInfo__title">Time Zone</div>
        <div class="aboutInfo__content">Taiwan is 8 hours ahead of Greenwich Mean Time (GMT)</div>
        <div class="aboutInfo__title">Currency</div>
        <div class="aboutInfo__content">The currency of Taiwan is the New Taiwan Dollar (NT$). Foreign currencies can be exchanged at the banks, airport and hotels. Payment by major credit card is accepted almost everywhere around Taiwan.</div>
        <div class="aboutInfo__title">Electricity</div>
        <ul style="padding-left: 20px;">
          <li>Taiwan uses an electric current of 110 volts at 60 cycles. Many buildings also have 220-volt sockets.</li>
          <li>Visitors with appliances of different voltages are urged to bring adapters with them.</li>
        </ul>
        <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-visit-pugin.png" alt="" style="width:50%; height: 50%;">
        <div class="aboutInfo__title">Exchange Rates</div>
        <div class="aboutInfo__content">The New Taiwan Dollar (NT$) is the currency used today in Taiwan. The exchange rate is around US$1 to NT$30. Like most countries, they come in the forms of bills and coins. Foreign currency exchanges are easy to access. Exchange service is available at the airport. banks, hotels and large department stores. For up to date exchange rates, travelers please visit the currency website.</div>
        <div class="aboutInfo__title">Tipping & Tax</div>
        <div class="aboutInfo__content">Sales tax is included in the listed price in Taiwan. Tipping is not customary in Taiwan, but some hotels and restaurants automatically add a 10% service fee to the bill.</div>
        <div class="aboutInfo__title">Telephone & Wi-Fi</div>
        <div class="aboutInfo__content">Free public Wi-Fi service is available across Taipei city both indoors and outdoors. The SSID of Wi-Fi access is TPE-Free. Visitors who wish to use the service can set up an account with valid passport or entry permit at Twelve Tourist Information Center at Taipei included Taipei Main Station and Songshan Airport. To check the free public Wi-Fi service in Taipei, please click <a target="_blank" href="https://itaiwan.gov.tw/EN">here.</a><br>In addition to the free Wi-Fi access, payable Wi-Fi card is also available in most of 7-11 convenience stores across Taiwan. Prepaid access cards can be purchased over the counter at the stores, and the costs vary.</div>
        <div class="aboutInfo__title">Getting Around</div>
        <div class="aboutInfo__content">
          <div class="bold">Taiwan High Speed Railway</div>
          The THSR goes through 10 main cities along the western part of Taiwan at the moment. Taiwan High speed Railway (<a target="_blank" href="https://en.thsrc.com.tw/">Booking</a>)
          <div class="bold">Railway</div>
          Trains on the round-the-island rail network provide extremely convenient services. Tickets may be purchased three days in advance. (<a target="_blank" href="https://tip.railway.gov.tw/tra-tip-web/tip/tip001/tip123/query">Booking</a>)
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisitTaiwanComp'
}
</script>
