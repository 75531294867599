<template>
  <div class="page-holder">
    <div class="page-content albums">
        <div class="title">Photo</div>
        <div>View photos at <a target="_blank" style="color:#601985; text-decoration:underline; cursor: pointer;" href="https://drive.google.com/drive/folders/1_ux_OksCWIaVvkDtYAqNJox2-2fOUvuy?usp=sharing"> Google Drive.</a></div>
        <div v-if="albums.length == 0">no data.</div>
        <div v-else class="albums__field">
            <div class="albums__item" v-for="book in albums" :key="book.albums_id" @click="openAlbum(book.albums_id)">
              <div class="left">
                <img :src="book.albums_preview" alt="">
              </div>
              <div class="right">
                <div class="albumTitle">{{book.albums_title}}</div>
                <div class="albumDate">{{book.albums_create_time}}</div>
                <div class="albumDes">{{book.albums_description}}</div>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlbumsComp',
  data () {
    return {
      albums_api: 'albums/getAlbums',
      albums: []
    }
  },
  methods: {
    openAlbum (id) {
      location.href = '/#/album/' + id
    }
  },
  mounted () {
    if (localStorage.getItem('accessToken') == null) {
      this.$popupHint('Please login your account before viewing photos.')
      location.href = '/#/login'
    } else {
      this.$axios.get(this.$apiUrl + this.albums_api, {
        headers: { 'Content-Type': 'application/json', 'member-token': localStorage.getItem('accessToken'), 'member-mid': localStorage.getItem('memberId') }
      }).then((res) => {
        if (res.data.resultCode === '000') {
          this.albums = res.data.data
        } else if (res.data.resultCode === '006') {
          this.$tokenError()
        } else {
          this.$popupError()
        }
      })
    }
  }
}
</script>
