<template>
    <div class="page-holder">
    <div class="page-content sponsor">
        <div class="title mb20">Sponsorship & Exhibition</div>
        <div class="catalog">
            <div class="wrapper catalog__item">
                <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-pdf.png" class="icon--pdf" alt="">
                <div class="catalog__text">Sponsorship Prospectus in English</div>
                <a class="download" target="_blank" href="../../sponsership-en-0508.pdf">Download</a>
            </div>
            <div class="wrapper catalog__item">
                <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/ic-pdf.png" class="icon--pdf" alt="">
                <div class="catalog__text">Sponsorship Prospectus in Chinese</div>
                <a class="download" target="_blank" href="../../sponsership-zh-0508.pdf">Download</a>
            </div>
        </div>
        <div class="sponsor__item">
            <div class="sponsor__title">1. Package Sponsorship</div>
            <div class="sponsor__grid sponsor__grid--package">
                <div class="span2 rowhead">Packages</div><div class="rowhead center">Diamond</div><div class="rowhead center">Gold</div><div class="rowhead center">Silver</div>
                <div class="span2">Item No.</div><div class="center">1-1</div><div class="center">1-2</div><div class="center">1-3</div>
                <div class="span2 rowhead">Unite Price</div><div class="rowhead center">NT$300,000<br>(≈US$11,000)</div><div class="rowhead center">NT$200,000<br>(≈US$6,800)</div><div class="rowhead center">NT$150,000<br>(≈US$5,000)</div>
                <div class="span2">Shell Scheme Booth (3m*2m=6 square meter)</div><div class="center">2</div><div class="center">1</div><div class="center">-</div>
                <div class="span2">Satellite Symposium<br>*Meals is not included in the package and should paid by the sponsor.</div><div class="center">2</div><div class="center">1</div><div class="center">-</div>
                <div class="span2">Complimentary Admission to INS 2023</div><div class="center">2</div><div class="center">2</div><div class="center">6</div>
                <div class="span2">Admission Badges to Exhibition Area and Satellite Symposium</div><div class="center">2</div><div class="center">1</div><div class="center">-</div>
                <div class="span2">Complimentary Admission to Gala Dinner</div><div class="center">2</div><div class="center">2</div><div class="center">2</div>
                <div class="span2">Certificate of Appreciation</div><div class="center">V</div><div class="center">V</div><div class="center">V</div>
                <div class="span2">A Free DM Insert in the INS 2023 Bag (A4 Double Slide)</div><div class="center">V</div><div class="center">V</div><div class="center">X</div>
                <div class="span2">Logo Positioning on Registration Counter</div><div class="center">V</div><div class="center">V</div><div class="center">-</div>
                <div class="span2">An Advertisement of Program Book (one page)</div><div class="center">Back cover, Inside front cover or inside back cover</div><div class="center">Inside front cover, Inside back cover or Inside page</div><div class="center">Inside Pages</div>
                <div class="span2">Logo Positioning on Lobby Giant Hanging Banners</div><div class="center">V</div><div class="center">V</div><div class="center">X</div>
                <div class="span2">Logo Positioning on the official website (with link)</div><div class="center">V</div><div class="center">V</div><div class="center">V</div>
                <div class="span2">Logo Positioning on Image Fort</div><div class="center">V</div><div class="center">V</div><div class="center">V</div>
                <div class="span2">Logo positioning on Program Book</div><div class="center">V</div><div class="center">V</div><div class="center">V</div>
                <div class="special center">Special Price for Package Sponsors!</div>
                <div class="span2-ver">1-4</div><div class="span2-ver">Additional Purchase:<br>Logo Positioning on the INS 2023 Taiwan Meeting Bag</div><div class="rowhead span3">NT$45,000 (≈US$1,500)</div><div class="span3">- Logo Positioning on Conference Bag.<br>-The logo is provided by the sponsor, the items will be manufactured and provided by the organizer.</div>
                <div class="span2-ver">1-5</div><div class="span2-ver">Additional Purchase:<br>Logo Positioning on Badge Lanyard (Exclusive)</div><div class="rowhead span3">NT$30,000 (≈US$1,000)</div><div class="span3">- Logo Positioning on Badge Lanyard.<br>-The logo is provided by the sponsor, the items will be manufactured and provided by the organizer.</div>
                <div>1-6</div><div>Admission to Gala Dinner</div><div class="rowhead span3">NT$1,800 (≈US$60)/person/ticket</div>
            </div>
        </div>
        <div class="sponsor__item">
            <div class="sponsor__title">2. Exhibition Sponsorship</div>
            <div class="sponsor__grid sponsor__grid--expo">
                <div class="head span3-ver">Standard Booth</div><div>Item No.</div><div>2-1</div><div>Standard Exhibition Booth Size</div><div>3M*2M</div><div>Unit Price</div><div>NT$65,000(≈US$2,200)</div>
                <div class="head">Location</div><div class="grey span2">Reception Area & Marble Area of 101, 1F, Taipei International Convention Center</div>
                <div class="head">Exhibition Date</div><div class="span2">July 6-8, 2023</div>
                <div class="head">Benefits</div><div class="grey span2">Each standard booth includes 2 exhibitor badges with accessing to exhibition area ONLY.</div>
                <div class="head">Booth Allocation</div>
                <div class="span2">
                    <ul>
                    <li>Booth location will be assigned according to sponsorship payment amount and the date of application received: <b>Diamond Sponsor → Gold Sponsor → Silver Sponsor → Exhibition Sponsor→ Raw Space Sponsor</b></li>
                    <li>The date of application received</li>
                    *Allocation is subject to change by the INS 2023 Organizing Committee.
                    </ul>
                </div>
                <div class="head">Provided Material</div>
                <div class="span2">
                    <ul>
                        <li>Standard booth including back and side wall structure.</li>
                        <li>1 Fascia/Company name board (full name). Logo is excluded.</li>
                        <li>1 Table (100 x 50 x 75CM) & 2 Folding chairs.</li>
                        <li>Spotlights (18W) and Booth area carpet (gray)</li>
                        <li>1 Power and Power sources (110V socket outlet with 5A)</li>
                        <li>2 exhibitor badges</li>
                        (No refund will be provided if standard shell scheme is not required)
                    </ul>
                </div>
                <div class="head">Regulations</div>
                <div class="span2">
                    <ul>
                        <li>The height of the display booth (including all exhibits) shall not exceed 2.5 meters; exhibitors may renovations below this height.</li>
                        <li>Each standard will receive 2 exhibitor badges. Additional work badge is priced at NT$1,500 (≈US$50) per pass, with a maximum of 2 additional passes per booth.</li>
                        <li>Exhibitor's badge allows entry to the exhibition area and the opening ceremony but only for the use of company personnel.</li>
                        <li>The INS 2023 Organizing Committee reserves the right to change, shorten or extend the show dates and change the venue.</li>
                    </ul>
                    <img src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-expo.png" alt="">
                </div>
                <div class="head">NOTE!</div><div class="span2">During the INS 2023 Taiwan Meeting, opening ceremony, welcome reception and gala dinner, Sponsors are not allowed to hold their own academic meetings or social events inside or outside the conference venue.</div>
            </div>
            <div class="sponsor__grid sponsor__grid--expo">
                <div class="head span3-ver">Standard Booth</div><div>Item No.</div><div>2-2</div><div>Standard Exhibition Booth Size</div><div>3M*2M</div><div>Unit Price</div><div>NT$55,000(≈US$1,850)</div>
                <div class="head">Location</div><div class="grey span2">Reception Area & Marble Area of 101, 1F, Taipei International Convention Center</div>
                <div class="head">Exhibition Date</div><div class="span2">July 6-8, 2023</div>
                <div class="head">Benefits</div><div class="grey span2">Each standard booth includes 2 exhibitor badges with accessing to exhibition area ONLY.</div>
                <div class="head">Booth Allocation</div>
                <div class="span2">
                    <ul>
                    <li>Booth location will be assigned according to sponsorship payment amount and the date of application received: <b>Diamond Sponsor → Gold Sponsor → Silver Sponsor → Exhibition Sponsor→ Raw Space Sponsor</b></li>
                    <li>The date of application received</li>
                    *Allocation is subject to change by the INS 2023 Organizing Committee.
                    </ul>
                </div>
                <div class="head">Provided Material</div>
                <div class="span2">
                    <ul>
                        RAW SPACE ONLY, EXHIBITORS SET UP STAND AT THEIR OWN EXPENSES.
                        <li>1 Power and Power sources (110V socket outlet with 5A)</li>
                        <li>Booth area carpet (gray)</li>
                        <li>2 exhibitor badges.</li>
                    </ul>
                    <img style="width:290px" src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-expo-02.png" alt="">
                </div>
                <div class="head">Regulations</div>
                <div class="span2">
                    <ul>
                        <li>The height of the display booth (including all exhibits) shall not exceed 2.5 meters; exhibitors may renovations below this height.</li>
                        <li>Each standard will receive 2 exhibitor badges. Additional work badge is priced at NT$1,500 (≈US$50) per pass, with a maximum of 2 additional passes per booth.</li>
                        <li>Exhibitor's badge allows entry to the exhibition area and the opening ceremony but only for the use of company personnel.</li>
                        <li>The INS 2023 Organizing Committee reserves the right to change, shorten or extend the show dates and change the venue.</li>
                    </ul>
                </div>
                <div class="head">NOTE!</div><div class="span2">During the INS 2023 Taiwan Meeting, opening ceremony, welcome reception and gala dinner, Sponsors are not allowed to hold their own academic meetings or social events inside or outside the conference venue.</div>
            </div>

        </div>
        <div class="sponsor__item">
            <div class="sponsor__title">3. Satellite Symposium Sponsorship</div>
            <div class="sponsor__grid sponsor__grid--satellite">
                <div class="grey">Item No.</div>
                <div class="grey span2">Item</div>
                <div class="grey">Duration</div>
                <div class="grey">Location</div>
                <div class="grey">Price(NT$)/<br>(approx. US$)</div>
                <div>3-1</div><div>Luncheon Symposium</div><div>July 6, 2023 (Thu) 12:30-13:30<br>July 8, 2023 (Sat) 13:15-14:15</div><div class="span4-ver" style="justify-content: center;">50 minutes</div><div class="span3-ver">1F,<br>101A/101B/<br>101C/101D</div><div class="span3-ver">NT$65,000 (≈US$2,200)</div>
                <div>3-2</div><div>Evening Symposium</div><div>July 7, 2023 (Fri)<br>17:00-18:00</div>
                <div>3-3</div><div class="span2-ver">Morning Symposium</div><div>July 8, 2023 (Fri)<br>08:30-09:30</div>
                <div>3-4</div><div>July 7, 2023 (Fri)<br>07:30-08:30</div><div>101, 1F (All)</div><div>$75,000 (≈US$2,500)</div>
            </div>
            <ol>
                <div class="sponsor__subtitle">General Information</div>
                <li>Satellite Symposia are organized and entirely supported by industry; Satellite Symposia details will be included in the Final Program.</li>
                <li>The INS 2023 Taiwan Meeting Organizing Committee reserves the right to reallocate meetings, and to ensure the balance and continuity with the main agenda.</li>
            </ol>
            <ol>
                <div class="sponsor__subtitle">Satellite Symposia Include</div>
                <li>Fee includes standard audio-visual equipment with a projector, 2 microphones and a laptop.</li>
                <li>5 badges per session with company name only for company staffs to access the Symposium. The badge allow access to its own Satellite Symposium and shall be used by company staffs only. Additional work badge is priced at NT$1,500 (≈US$50) per pass.</li>
                <li>All speakers and moderators’ expenses should be sponsors’ responsibilities.</li>
                <li>The sponsor shall additionally provide the catering costs for the satellite session; the number of lunch/breakfast boxes will be suggested by the Organizer and the content of the lunch boxes can be chosen by the sponsor. (*According to TICC regulations, catering is only provided by the Cosmos Hotel Taipei, and outside food is prohibited)</li>
            </ol>
            <ol>
                <div class="sponsor__subtitle">Application and Booking</div>
                <b>Timeslot will be arranged according to below conditions:</b>
                <li>Sponsorship category: <b>Diamond Sponsor → Gold Sponsor → Silver Sponsor → Satellite Symposium Sponsor</b></li>
                <li>The priority of each Sponsorship will be based on:
                    <ol><li>Total sponsorship amount</li><li>The date of application received</li></ol>
                </li>
            </ol>
            <ol>
                <div class="sponsor__subtitle">Rules and Regulations</div>
                <li>The sponsoring company can submit a full proposal or ask for supports by the INS 2023 Taiwan Meeting Program Committee; an early contact via Conference Secretariat is recommended to avoid overlaps with the Scientific Program.</li>
                <li>Agenda of Symposia is subject to the approval of the INS 2023 Taiwan Meeting Organizing Committee </li>
                <li>Portable decoration with the Symposia titles and company logos inside/in front of session room or its own exhibition booth is allowed; beyond that no posters, signages, or distributions of materials are allowed in and around the Conference venue. </li>
            </ol>
        </div>
        <div class="sponsor__item">
            <div class="sponsor__title">4. Advertisement & Product Sponsorship</div>
            <div class="sponsor__grid sponsor__grid--advertisement" >
                <div class="grey">Item No.</div><div class="grey">Item</div><div class="grey">Description</div><div class="grey">Unit Price</div>
                <template v-for="(i,index) in advertList">
                    <div :key="index">4-{{index+1}}</div><div :key="i.title" style="align-items:flex-start;">{{i.title}}</div>
                    <div :key="index+i.description[0]"><ul>
                        <li v-for="d in i.description" :key="d">{{d}}</li>
                        <img style="width:290px" v-if="index == 8" :key="index+i.usd" src="https://storage.googleapis.com/zuvio-public/public/system/images/ins/img-expo-03.png">
                    </ul></div>
                    <div :key="index+i.twd">NT${{i.twd}} (≈US${{i.usd}})</div>
                </template>
            </div>
            <ol>
                <div class="sponsor__subtitle">Notice</div>
                <li>4-1: Sponsor needs to provide the high-resolution file, up to 300 dpi with AI/ JPG/ PDF format. According to Pharmaceutical Affairs Act on advertising, for publishing medication advertisement, pharmaceutical firms shall, before publishing, submit all texts, drawings or pictures constituting an advertisement to the central or the municipal competent health authority for approval, and shall forward the approval to the Conference for verification.</li>
                <li>The design and printing of flyer/DM in 4-2 are responsible by the sponsor. The finished products need to be delivered to the meeting venue at the appointed time.</li>
                <li>The sponsors of 4-3/4-4/4-5/4-6/4-7/4-8/4-9 should provide the Sponsor’s logo to the organizer for further design.</li>
            </ol>
        </div>
        <div class="sponsor__item">
            <div class="sponsor__title">5. Social Sponsorship</div>
            <div class="sponsor__grid sponsor__grid--advertisement" >
                <div class="grey">Item No.</div><div class="grey">Item</div><div class="grey">Description</div><div class="grey">Unit Price</div>
                <template v-for="(i,index) in socialList">
                    <div :key="index">5-{{index+1}}</div><div :key="i.title" style="text-align:center;">{{i.title}}</div>
                    <div :key="index+i.description[0]"><ul>
                        <li v-for="(d,d_index) in i.description" :key="d_index+d">{{d}}</li>
                    </ul></div>
                    <div :key="index+i.twd">NT${{i.twd}} (≈US${{i.usd}})</div>
                </template>
            </div>
            <ol>
                <div class="sponsor__subtitle">Benefit</div>
                <li>Total sponsorship amount.</li>
                <li>The date of application received.</li>
            </ol>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SponsorComp',
  data () {
    return {
      advertList: [{
        title: 'Advertisement of Program Book',
        description: ['Print-ready file in English should be provided by the Sponsor.', 'Ad size specifications is provided by the Organizer.', 'Package sponsors have the priority to choose the page, and the rest will be subject to the date of application received.'],
        twd: '18,000',
        usd: '600'
      }, {
        title: 'Flyer/DM Insert in the Conference Bag',
        description: ['DM will be insert in the INS 2023 Taiwan Meeting bag.', 'DMs to be printed is one on single/ both sides (within A4 size)', 'DM is produced and provided by the sponsor, and the suggested printing quantity will be informed before the conference.'],
        twd: '15,000',
        usd: '500'
      }, {
        title: 'NotePads',
        description: ['The items will be manufactured and provided by the organizer.', 'Print-ready file in English should be provided by the Sponsor.', 'Ad size specifications is provided by the Organizer.', 'The front cover is designed by the Organizer, and the advertisement file can be placed on the inside front cover, inside back cover or back cover.'],
        twd: '36,000',
        usd: '1,200'
      }, {
        title: 'Pens',
        description: ['Logo Positioning on Conference Pen.', 'The logo is provided by the sponsor, the items will be manufactured and provided by the organizer.'],
        twd: '30,000',
        usd: '1,000'
      }, {
        title: 'Water Bottle',
        description: ['Logo Positioning on the water bottle.', 'The items will be manufactured and provided by the organizer.', 'About 800-1,000 bottles of bottled water will be provided onsite.'],
        twd: '39,000',
        usd: '1,300'
      }, {
        title: 'Badge Lanyard (Exclusive)',
        description: ['Logo Positioning on Badge Lanyard.', 'The logo is provided by the sponsor, the items will be manufactured and provided by the organizer.', 'Package Sponsors reserve the priority for purchase this item.'],
        twd: '39,000',
        usd: '1,300'
      }, {
        title: 'The INS 2023 Taiwan Meeting conference Bag (Exclusive)',
        description: ['Logo Positioning on Conference Bag.', 'The logo is provided by the sponsor, the items will be manufactured and provided by the organizer.', 'Package Sponsors reserve the priority for purchase this item.'],
        twd: '60,000',
        usd: '2,000'
      }, {
        title: 'Staff Shirts (Exclusive)',
        description: ['Logo Positioning on staff Shirt.', 'The logo is provided by the sponsor, the items will be manufactured and provided by the organizer.'],
        twd: '30,000',
        usd: '1,000'
      }, {
        title: 'Lobby Giant Hanging Banners (Limited)',
        description: ['Logo Positioning on the Lobby Giant Hanging Banners', 'The logo is provided by the sponsor, the items will be manufactured and provided by the organizer.', 'The number of hanging flags is limited.', 'Package sponsors have the priority to choose the location, and the rest will be subject to the date of application received.'],
        twd: '36,000',
        usd: '1,200'
      }],
      socialList: [{
        title: 'Coffee Break',
        description: ['The Sponsor’s logo will appear on signage at the coffee break area during the break time.', 'The Sponsor can distribute their own brochures during the sponsored break time.'],
        twd: '45,000 per break',
        usd: '1,500'
      }, {
        title: 'Welcome Reception (Exclusive)',
        description: ['The Welcome Reception will provide a networking opportunity for delegates prior to scientific sessions commencing the following day.', 'The Sponsor’s logo will appear alongside the Conference logo on the Welcome Reception banner as well as table top cards on all food & beverage stations.', 'Three-minute address during the Welcome Reception by one company representative.'],
        twd: '90,000',
        usd: '3,000'
      }, {
        title: 'Gala Dinner',
        description: ['The Sponsor’s logo will appear alongside the Conference logo on the onsite banner.', '60-second advertising (Video) will be played before the Gala Dinner.', '5 free admissions.'],
        twd: '120,000',
        usd: '4,000'
      }]
    }
  }
}
</script>
